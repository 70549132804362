import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_numeric = _resolveComponent("numeric")
  const _component_validation_message = _resolveComponent("validation-message")

  return (_openBlock(), _createBlock("td", null, [
    (!_ctx.readonly)
      ? (_openBlock(), _createBlock(_component_numeric, {
          key: 0,
          ref: "refValue",
          modelValue: _ctx.v,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.v = $event)),
          step: "0.001",
          class: "form-control",
          min: "1",
          disabled: _ctx.readonly,
          placeholder: "(default)",
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.validValue()))
        }, null, 8, ["modelValue", "disabled"]))
      : _createCommentVNode("", true),
    (!_ctx.readonly)
      ? (_openBlock(), _createBlock(_component_validation_message, {
          key: 1,
          modelValue: _ctx.valueErrorMessage,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.valueErrorMessage = $event))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.readonly)
      ? (_openBlock(), _createBlock("span", _hoisted_1, _toDisplayString(_ctx.v), 1))
      : _createCommentVNode("", true)
  ]))
}


import { DataRequest } from "@/shared/support/Data";
import { Global } from "@/support/GlobalData";
import { Vue } from "vue-class-component";

export default class SessionToolYpr extends Vue {

    currentYaw: string|null = "";
    currentRoll: string|null = "";
    currentPitch: string|null = "";

    get yaw(): string {
        if (!this.currentYaw && Global.CalibrationSession)
            this.currentYaw = Global.CalibrationSession.ToolYaw?.toString() || null;
        return this.currentYaw || "";
    }
    set yaw(value: string) {
        this.currentYaw = value;
    }
    get pitch(): string {
        if (!this.currentPitch && Global.CalibrationSession)
            this.currentPitch = Global.CalibrationSession.ToolPitch?.toString() || null;
        return this.currentPitch || "";
    }
    set pitch(value: string) {
        this.currentPitch = value;
    }
    get roll(): string {
        if (!this.currentRoll && Global.CalibrationSession)
            this.currentRoll = Global.CalibrationSession.ToolRoll?.toString() || null;
        return this.currentRoll || "";
    }
    set roll(value: string) {
        this.currentRoll = value;
    }
    public Update(): void {
        if (!Global.CalibrationSession) {
            this.currentYaw = null;
            this.currentPitch = null;
            this.currentRoll = null;
        } else {
            this.currentYaw = Global.CalibrationSession.ToolYaw?.toString() || null;
            this.currentPitch = Global.CalibrationSession.ToolPitch?.toString() || null;
            this.currentRoll = Global.CalibrationSession.ToolRoll?.toString() || null;
        }
    }

    yawBlur(): void {
        this.save();
    }
    pitchBlur(): void {
        this.save();
    }
    rollBlur(): void {
        this.save();
    }
    normalizeNumber(val: number|string|null): number|null {
        if (val && val === "") {
            val = null;
        } else {
            val = Number(val);
            if (isNaN(val))
                val = null;
        }
        return val;
    }
    save(): void {
        if (!Global.CalibrationSession) return;

        const yaw = this.normalizeNumber(this.currentYaw);
        const pitch = this.normalizeNumber(this.currentPitch);
        const roll = this.normalizeNumber(this.currentRoll);
        if (yaw === Global.CalibrationSession.ToolYaw && pitch === Global.CalibrationSession.ToolPitch && roll === Global.CalibrationSession.ToolRoll) return; // nothing changed
        Global.CalibrationSession.ToolYaw = yaw;
        Global.CalibrationSession.ToolPitch = pitch;
        Global.CalibrationSession.ToolRoll = roll;

        const request = new DataRequest();
        request.autoToastOnFailure = false;
        request.$post<any, any>(`/Service/CalibrationSessionRecord/${Global.CalibrationSession!.CalibrationSessionGuid}/ToolYpr`, { Yaw: yaw, Pitch: pitch, Roll: roll });
    }
}


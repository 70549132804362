import { CalibrationSessionDto } from "@/shared/models/CalibrationSessionDto";
import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";
import { VehicleDto } from "@/shared/models/VehicleDto";

export class GlobalData {

    public MainApp: any = null!;

    public CalibrationSession: CalibrationSessionDto|null = null;
    public Vehicle: VehicleDto|null = null;
    public VehicleCalibration: VehicleCalibrationDto|null = null;

    public VehicleDescription(): string {
        const v = Global.Vehicle;
        if (!v) return "";
        return `${v.VehicleYear} ${v.VehicleMake} ${v.VehicleModel}`;
    }

    public clearSession(): void {
        Global.CalibrationSession = null;
        Global.Vehicle = null;
        Global.VehicleCalibration = null;
    }
}
export const Global = new GlobalData();

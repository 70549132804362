import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import { identity, UserRoles } from "@/identity";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "bootstrap";

import Toast from "@/shared/support/Toast";
import Debug from "@/shared/support/Debug";

import AButton from "@/shared/components/common/AButton.vue";
import AuggieIcon from "@/shared/components/common/AuggieIcon.vue";
import ButtonButton from "@/shared/components/common/ButtonButton.vue";
import BoolDisplay from "@/shared/components/common/BoolDisplay.vue";
import FormLabel from "@/shared/components/common/FormLabel.vue";
import FormRow from "@/shared/components/common/FormRow.vue";
import GridPanel from "@/shared/components/common/GridPanel.vue";
import GenericStatus from "@/shared/components/common/GenericStatus.vue";
import Loading from "@/shared/components/common/Loading.vue";
import Numeric from "@/shared/components/common/Numeric.vue";
import Selection from "@/shared/components/common/Selection.vue";
import SearchSelection from "@/shared/components/common/SearchSelection.vue";
import SessionHeader from "@/components/SessionHeader.vue";
import SpinButton from "@/shared/components/common/SpinButton.vue";
import SpinX from "@/shared/components/common/SpinX.vue";
import TeamViewerFooter from "@/components/TeamViewerFooter.vue";
import UploadPanel from "@/shared/components/common/UploadPanel.vue";
import ValidationMessage from "@/shared/components/common/ValidationMessage.vue";

import "@/styles/app.scss";
import "@progress/kendo-theme-default/dist/all.css";


// Register all fontawesome icons
// https://github.com/FortAwesome/vue-fontawesome
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faAsterisk, faBolt, faBoxOpen, faBullseye, faCamera, faCar, faCheckCircle,
    faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faClone, faCogs,
    faEdit, faEye, faFileDownload, faHome, faImages, faMinus, faSearch, faSpinner, faSyncAlt, faPlus, faTabletAlt, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Utility from "@/shared/support/Utility";

library.add(
    faAsterisk, faBolt, faBoxOpen, faBullseye, faCamera, faCar, faCheckCircle,
    faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faClone, faCogs,
    faEdit, faEye, faFileDownload, faHome, faImages, faMinus, faSearch, faSpinner, faSyncAlt, faPlus, faTabletAlt, faTimes, faTimesCircle,
);

identity.startup().then((ok: boolean): void => {
    if (ok) {
        console.log("Oidc startup successful");
    } else {
        console.log("Oidc startup incomplete");
    }
});

const appObject = createApp(App);

Toast.initializeToaster(appObject);
Debug.initialize();

// register commonly used components
appObject.component("AButton", AButton);
appObject.component("AuggieIcon", AuggieIcon);
appObject.component("ButtonButton", ButtonButton);
appObject.component("BoolDisplay", BoolDisplay);
appObject.component("FaIcon", FontAwesomeIcon);
appObject.component("FormLabel", FormLabel);
appObject.component("FormRow", FormRow);
appObject.component("GenericStatus", GenericStatus);
appObject.component("GridPanel", GridPanel);
appObject.component("Loading", Loading);
appObject.component("Numeric", Numeric);
appObject.component("SearchSelection", SearchSelection);
appObject.component("Selection", Selection);
appObject.component("SessionHeader", SessionHeader);
appObject.component("SpinButton", SpinButton);
appObject.component("SpinX", SpinX);
appObject.component("TeamViewerFooter", TeamViewerFooter);
appObject.component("UploadPanel", UploadPanel);
appObject.component("ValidationMessage", ValidationMessage);

appObject.use(router);

appObject.config.globalProperties.identity = identity;
appObject.config.globalProperties.UserRoles = UserRoles;

appObject.mount("#app");

export default appObject;

dom.watch();
// This will kick of the initial replacement of i to svg tags and configure a MutationObserver

window.onbeforeunload = (ev: BeforeUnloadEvent): any => {
    if (Utility.pageChanged) {
        ev.returnValue = "Are you sure you want to leave this page?"; // Chrome requires returnValue to be set
        ev.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    }
};




import { h, VNode } from "@vue/runtime-core";
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import GridHeader from "@/shared/components/common/GridHeader.vue";
import GridTitle from "@/shared/components/common/GridTitle.vue";
import GridBody from "@/shared/components/common/GridBody.vue";

import { Grid, GridNoRecords, GridDataStateChangeEvent, GridSortSettings, GridPagerSettings, GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { DataResult, State, SortDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";

@Options<GridPanel>({
    components: {
        GridHeader, GridTitle, GridBody,
        Grid,
        "grid-norecords": GridNoRecords, 
    },
    watch: {
        modelValue(val): void { 
            this.dataResult = val;
            this.loading = false; // setting the model automatically clears the loading indicator 
            this.clearTbodyHeight();
        },
        skip(val): void { this.currSkip = val; },
        take(val): void { this.currTake = val; },
    }
})
export default class GridPanel extends Vue {

    @Prop({ required: true }) readonly modelValue: DataResult = null!;

    @Prop({ default: "" }) readonly cssBorder: string = "";
    @Prop({ default: "" }) readonly cssHeader: string = "";
    @Prop({ default: "No Records Found" }) readonly noRecordsText: string = "";
    @Prop({ default: true }) readonly headerShown = true;
    @Prop({ default: false }) readonly autoSearch = false;
    @Prop({ default: 0 }) readonly autoSearchTimer: number = 0;
    @Prop({ default: true }) readonly reloadButton: boolean = true;

    @Prop({ default: "none" }) readonly style: string = "none";
    @Prop({ default: true }) readonly sortable: GridSortSettings = true;
    @Prop() readonly sort?: SortDescriptor[] = [];
    @Prop({ default: false }) readonly filterable = false;
    @Prop() readonly filter?: CompositeFilterDescriptor = undefined;
    @Prop({ default: { buttonCount: 4, pageSizes: [ 10, 25, 50 ] }}) readonly pageable: GridPagerSettings = { buttonCount: 4, pageSizes: [ 10, 25, 50 ] };
    @Prop({ default: 0 }) readonly skip? = 0;
    @Prop({ default: 10 }) readonly take? = 10;
    @Prop({ required: true }) readonly columns: GridColumnProps[] = null!;
    @Prop({ default: "scrollable" }) readonly scrollable = null!;

    loading = false;
    currSkip = this.skip;
    currTake = this.take;
    currSort = this.sort;
    currFilter = this.filter;
    search: string = "";
    pagerInterval: number = 0;

    dataResult = GridPanel.EmptyDataResult;

    public static get EmptyDataResult(): DataResult {
        return { data: [], total: 0};
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static customCell(oldH: any, defaultRendering: VNode | null, props: GridCellProps, listeners: any, vueComp: any, parms?: any): VNode {
        let tdProps =  {
            colspan: props.colSpan,
            class: props.className,
            role: "gridcell", "aria-selected": props.isSelected,
            "data-grid-col-index": props.columnIndex,
        };
        if (parms)
            tdProps = Object.assign(tdProps, parms);
        return h(vueComp, tdProps);
    }

    mounted(): void {
        this.currSkip = this.skip;
        this.currTake = this.take;
        this.currSort = this.sort;
        this.currFilter = this.filter;
        this.loadGrid();
        this.pagerInterval = setInterval((): void => {
            this.checkVisibility();
        }, 200) as unknown as number;
    }
    unmounted(): void {
        if (this.pagerInterval) {
            clearInterval(this.pagerInterval);
            this.pagerInterval = 0;
        }
    }

    // Kendo Vue Grid has a problem when its pager is initialized while the grid is not shown, e.g. its parent div has display:none.
    // This obviously happens with bootstrap tabs, modals, etc.
    // There are some solutions like https://bestofvue.com/repo/Akryum-vue-observe-visibility-vuejs-event-handling
    // that use the IntersectionObserver API https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    // Mobile support may be a bit sketchy according to this: https://caniuse.com/intersectionobserver
    // So instead, because we only ever have just a few grids active (including hidden ones), it's simpler to do a simple timer polling 
    // and check their visibility in a fast running timer event. This timer event sets and resets the pager as the grid is shown/hidden.
    // We also have to deal with grids going from shown to hidden status which happens when modals are hidden and shown again with
    // new data. Modals are not recreated in this case, just hidden and shown again (that's a Vue thing).
    get realPageable(): GridPagerSettings | boolean {
        return this.cachedPageable;
    }
    cachedPageable: GridPagerSettings|boolean = false;

    checkVisibility(): void {
        if (!this.$refs.refGrid) return;
        const rect = this.$refs.refGrid.getBoundingClientRect();
        if (rect.width > 0 && rect.height > 0) {
            if (!this.cachedPageable)
                this.cachedPageable = this.pageable; 
        } else {
            if (this.cachedPageable)
                this.cachedPageable = false; 
        }
    }


    cardCss(): string  {
        return `card ${this.cssBorder}`;
    }

    loadGrid(search?: string): void {
        this.search = search || "";
        this.reload();
    }
    public reload(): void {
        this.loading = true;
        this.keepTbodyHeight();
        this.dataResult.data = []; // clear data to show grid's loading indicator

        const dataState: State = {
            skip: this.currSkip,
            take: this.currTake,
            sort: this.currSort,
            filter: this.currFilter,
        };
        this.$emit("loadgrid", dataState, this.search);
    }
    dataStateChange(event: GridDataStateChangeEvent): void {
        this.loading = true;
        this.keepTbodyHeight();
        this.dataResult.data = []; // clear data to show grid's loading indicator

        const dataState: State = event.data;
        this.currSkip = dataState.skip;
        this.currTake = dataState.take;
        this.currSort = dataState.sort;
        this.currFilter = dataState.filter;
        this.$emit("loadgrid", dataState, this.search);
    }

    getNoRecordsText():string {
        if (this.loading) 
            return "Loading...";
        else
            return this.noRecordsText;
    }

    // When loading data, we show the grid-norecords component
    // which resizes the grid in height (making me dizzy). We lock the height until 
    // loading has completed.

    keepTbodyHeight(): void {
        const tbody = this.$el.querySelector("tbody") as HTMLElement;
        const h = tbody.clientHeight;
        tbody.style.height = `${h}px`;
    }
    clearTbodyHeight(): void {
        const tbody = this.$el.querySelector("tbody") as HTMLElement;
        tbody.style.height = "";
    }

    $refs!: {
        refGrid: HTMLElement;
    }
}


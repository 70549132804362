

import { VNode } from "@vue/runtime-core";
import { Vue, Options } from "vue-class-component";

import GridPanel from "@/shared/components/common/GridPanel.vue";
import { GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { State } from "@progress/kendo-data-query";
import { DataRequest, DataResult } from "@/shared/support/Data";
import Utility from "@/shared/support/Utility";

import EditImageModal from "./EditImageModal.vue";
import ImageButtonCell from "./ImageButtonCell.vue";

import { TargetImageDto } from "@/shared/models/TargetImageDto";
import BoolDisplayGridCell from "@/shared/components/common/BoolDisplayGridCell.vue";
import { GenericActiveStatus } from "@/shared/enums/GenericActiveStatus";
import { UserRoles } from "@/identity";
import App from "@/App.vue";

@Options({
    components: {
        EditImageModal,
    },
})
export default class ConfigurationImages extends Vue {

    loading = false;
    status: GenericActiveStatus = GenericActiveStatus.Active;

    show(imageId?: number): void {
        this.$refs.imagemodal.show(imageId);
    }
    hide(forceReload?: boolean, setUrl?: boolean): void {
        this.$refs.imagemodal.hide(forceReload, setUrl);
    }
    addNew(): void {
        App.MainApp.routerPush("/Configuration", { imageid: ""});
    }
    canAddNew(): boolean {
        return UserRoles.has("AuggieVehicleEdit");
    }

    dataResult:DataResult<TargetImageDto> = GridPanel.EmptyDataResult;
    columns: GridColumnProps[] = [ // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridColumnProps/
        { field: "Actions", title: " ", sortable: false, width: "120px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActions },
        { field: "TargetImageId", hidden: true },
        { field: "UploadId", hidden: true },
        { field: "ImageName", title: "Name", width: "350px", },
        { field: "Version", title: "Version", width: "100px", headerClassName: "gRight", className: "gRight",  },
        { field: "VehicleCount", title: "Vehicles", width: "100px", headerClassName: "gRight", className: "gRight", sortable: false, },
        { field: "ActiveInd", title: "Active", width: "100px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActive },
        { field: "CreatedDt", title: "Created", width: "180px", },
        { field: "CreatedByUserName", title: "Created By", width: "300px", sortable: false, },
        { field: "UpdatedDt", title: "Updated", width: "180px", },
        { field: "UpdatedByUserName", title: "Updated By", width: "300px", sortable: false, },
    ];

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActions(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            ImageButtonCell, { // the component used to render the cell
                imageId: props.dataItem.TargetImageId,
                imageName: props.dataItem.ImageName,
                uploadId: props.dataItem.UploadId,
                vehicleCount: props.dataItem.VehicleCount,
                active: props.dataItem.ActiveInd,
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActive(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            BoolDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.ActiveInd,  // props to pass to the component
            });
    }

    loadGrid(dataState: State, search: string): void {

        this.loading = true; // turn on page's loading indicator

        // load grid data
        const request = new DataRequest();
        request.$getGridData<TargetImageDto[]>("Service/TargetImages", dataState, search, { StatusFilter: this.status })
            .then((result): void => {
                // format all data that is not readily renderable
                for (const row of result.Rows) {
                    row.CreatedDt = Utility.getFormattedDateTime(new Date(row.CreatedDt));
                    if (row.UpdatedDt) row.UpdatedDt = Utility.getFormattedDateTime(new Date(row.UpdatedDt));
                }
                // set grid data
                this.loading = false;  // turn off page's loading indicator
                this.dataResult = { // update grid's model
                    data: result.Rows,
                    total: result.Total,
                };
            })
            .catch((reason): void => {
                this.loading = false; // turn off page's loading indicator
                this.dataResult = GridPanel.EmptyDataResult;
            });
    }
    reloadGrid(): void {
        this.$refs.imageGrid.reload();
    }

    $refs!: {
        imagemodal: EditImageModal,
        imageGrid: GridPanel,
    }
}


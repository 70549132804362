

import ModalBase from "@/shared/components/common/ModalBase";
import Toast from "@/shared/support/Toast";

import ValidationMessage, { ValidationLevel } from "@/shared/components/common/ValidationMessage.vue";

import { PackageDto } from "@/shared/models/PackageDto";
import { DataRequest } from "@/shared/support/Data";
import { confirmOk } from "@/shared/components/common/AlertDialog.vue";
import Utility from "@/shared/support/Utility";
import App from "@/App.vue";
import { UserRoles } from "@/identity";
import { OpsysAllType, OpsysType, OpsysTypeDescription } from "@/shared/enums/OpsysType";
import SelectionSupport, { SelectionEntry } from "@/shared/support/Selection";

export default class PackageModal extends ModalBase {

    packageId: number|null = null;

    active: boolean = false;
    selectedOpsys: OpsysAllType = OpsysAllType.All;
    opsysSelections: SelectionEntry[] = [];
    packageName: string = "";
    version: string = "";
    versionMajor: number = 0;
    versionMinor: number = 0;
    versionBuild: number = 0;
    requiredInstall: boolean = false;
    installBy: string = "";
    notes: string = "";
    description: string = "";
    auggiePackage: PackageDto = {} as PackageDto;
    createdDt = "";
    createdBy = "";
    updatedDt = "";
    updatedBy = "";

    isEdit = false;
    isNew = false;
    isView = false;
    hasPackage(): boolean { return !!this.packageName; }

    versionErrorMessage = "";
    notesErrorMessage = "";
    descriptionErrorMessage = "";

    initializeForm():void {
        this.packageId = null;
        this.clearForm();
    }
    clearForm(): void {
        this.isNew = this.isEdit = this.isView = false;
        this.version = "";
        this.active = false;
        this.selectedOpsys = OpsysAllType.All;
        this.packageName = "";
        this.versionMajor = 0;
        this.versionMinor = 0;
        this.versionBuild = 0;
        this.requiredInstall = false;
        this.installBy = "";
        this.notes = "";
        this.description = "";
        this.versionErrorMessage = "";
        this.notesErrorMessage = "";
        this.descriptionErrorMessage = "";
        this.auggiePackage = {} as PackageDto;
        this.createdDt = "";
        this.createdBy = "";
        this.updatedDt = "";
        this.updatedBy = "";
    }

    validForm(show: ValidationLevel = ValidationLevel.Test): boolean {
        if (this.isView) return true;

        const errors: string[] = [];

        this.validVersion(errors, show);
        this.validNotes(errors, show);
        this.validDescription(errors, show);

        if (show === ValidationLevel.Show)
            ValidationMessage.display(errors);
        return errors.length === 0;
    }
    validVersion(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.isNew) {
                if (!this.version) return "The Version field is required";
                const parts = this.version.split(".");
                if (parts.length !== 3)
                    return "Invalid package version - The version format is x.y.z, for example 2022.11.5.";
                try {
                    this.versionMajor = Number(parts[0]);
                    this.versionMinor = Number(parts[1]);
                    this.versionBuild = Number(parts[2]);
                } catch (e) {
                    return "Invalid package version - The version format is x.y.z, for example 2022.10.3.";
                }
            }
            return null;
        }, (message: string):void =>{ this.versionErrorMessage = message; });
    }
    validNotes(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            return null;
        }, (message: string):void =>{ this.notesErrorMessage = message; });
    }
    validDescription(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            return null;
        }, (message: string):void =>{ this.descriptionErrorMessage = message; });
    }
    show(packageId?: number): void {
        this.initializeForm(); // in case this is a second invocation of this modal we need to clear all
        this.packageId = packageId || null;
        if (!this.packageId) {
            this.isNew = true;
        } else {
            this.isEdit = !!this.packageId && UserRoles.has("AuggiePackageEdit");
            if (!this.isEdit)
                this.isView = !!this.packageId && UserRoles.has("AuggiePackageView");
        }
        this.showModal();
        this.loadPackage();
    }
    hide(forceReload?: boolean, setUrl?: boolean):void {
        if (this.hideModal(forceReload) && (setUrl === undefined || setUrl))
            App.MainApp.routerPush(window.location.pathname);
    }
    saveClicked():void {
        if (!this.validForm(ValidationLevel.Show))
            return;
        this.savePackage();
    }
    cancelClicked():void {
        this.hide();
    }

    loadPackage(): void {

        if (this.isNew) {
            this.initialized();
            this.opsysSelections = SelectionSupport.EnumSelections(OpsysAllType, OpsysTypeDescription);
            this.selectedOpsys = OpsysAllType.All;
            return;
        }

        this.loading = true;

        const dr = new DataRequest();
        dr.$get<PackageDto>(`/Service/Packages/${this.packageId}`)
            .then((auggiePackage: PackageDto): void => {
                this.loading = false;
                this.initialized();
                if (auggiePackage) {
                    this.packageId = auggiePackage.PackageId;
                    const opsys = auggiePackage.Opsys as unknown as OpsysAllType;
                    this.opsysSelections = [{ Key: OpsysAllType.All, Value: OpsysTypeDescription[OpsysAllType.All] }, { Key: opsys, Value: OpsysTypeDescription[opsys]}];
                    this.selectedOpsys = opsys;
                    this.packageName = auggiePackage.PackageName;
                    this.version = `${auggiePackage.VersionMajor}.${auggiePackage.VersionMinor}.${auggiePackage.VersionBuild}`;
                    this.versionMajor = auggiePackage.VersionMajor;
                    this.versionMinor = auggiePackage.VersionMinor;
                    this.versionBuild = auggiePackage.VersionBuild;
                    this.requiredInstall = auggiePackage.RequiredInstall;
                    this.installBy = this.requiredInstall || !auggiePackage.InstallBy ? "" : Utility.getFormattedDate(new Date(auggiePackage.InstallBy));
                    this.notes = auggiePackage.Notes;
                    this.description = auggiePackage.ReleaseNotes;
                    this.active = auggiePackage.ActiveInd;
                    this.auggiePackage = auggiePackage;
                    this.createdDt = Utility.getFormattedDateTime(new Date(auggiePackage.CreatedDt));
                    this.createdBy = auggiePackage.CreatedByUserName;
                    if (auggiePackage.UpdatedDt) {
                        this.updatedDt = Utility.getFormattedDateTime(new Date(auggiePackage.UpdatedDt));
                        this.updatedBy = auggiePackage.UpdatedByUserName || "";
                    }
                } else {
                    Toast.error(`Unable to retrieve Package ID ${this.packageId}`);
                }
            })
            .catch((reason): void => {
                this.loading = false;
                this.initialized();
            });
    }
    savePackage(): void {
        this.loading = true;

        const dr = new DataRequest();

        const auggiePackage: Partial<PackageDto> = {
            PackageId: this.isEdit ? this.packageId || 0 : 0,
            Opsys: this.selectedOpsys ? this.selectedOpsys as unknown as OpsysType: undefined,
            VersionMajor: this.versionMajor,
            VersionMinor: this.versionMinor,
            VersionBuild: this.versionBuild,
            RequiredInstall: this.requiredInstall,
            Notes: this.notes,
            ReleaseNotes: this.description,
            ActiveInd: this.active,
        };

        dr.$post<Partial<PackageDto>, PackageDto>("/Service/Packages", null, auggiePackage)
            .then((auggiePackage: PackageDto): void => {
                this.loading = false;
                if (auggiePackage.UpdateResult.Success) {
                    if (auggiePackage.UpdateResult.Message)
                        Toast.success(auggiePackage.UpdateResult.Message);
                    if (this.isEdit)
                        this.hide(true);
                    else {
                        this.packageId = auggiePackage.PackageId;
                        const origOpsys = this.selectedOpsys;
                        const opsys = auggiePackage.Opsys as unknown as OpsysAllType;
                        this.opsysSelections = [{ Key: OpsysAllType.All, Value: OpsysTypeDescription[OpsysAllType.All] }, { Key: opsys, Value: OpsysTypeDescription[opsys]}];
                        this.selectedOpsys = origOpsys;
                        this.packageName = auggiePackage.PackageName;
                        this.version = `${auggiePackage.VersionMajor}.${auggiePackage.VersionMinor}.${auggiePackage.VersionBuild}`;
                        this.versionMajor = auggiePackage.VersionMajor;
                        this.versionMinor = auggiePackage.VersionMinor;
                        this.versionBuild = auggiePackage.VersionBuild;
                        this.requiredInstall = auggiePackage.RequiredInstall;
                        this.installBy = this.requiredInstall || !auggiePackage.InstallBy ? "" : Utility.getFormattedDate(new Date(auggiePackage.InstallBy));
                        this.notes = auggiePackage.Notes;
                        this.description = auggiePackage.ReleaseNotes;
                        this.active = auggiePackage.ActiveInd;
                        this.auggiePackage = auggiePackage;
                        this.createdDt = Utility.getFormattedDateTime(new Date(auggiePackage.CreatedDt));
                        this.createdBy = auggiePackage.CreatedByUserName;
                        if (auggiePackage.UpdatedDt) {
                            this.updatedDt = Utility.getFormattedDateTime(new Date(auggiePackage.UpdatedDt));
                            this.updatedBy = auggiePackage.UpdatedByUserName || "";
                        }
                        this.isNew = false;// switch to edit mode
                        this.isEdit = true;
                    }
                } else {
                    confirmOk(auggiePackage.UpdateResult.Message || "Package save failed!");
                }
            })
            .catch((reason): void =>{
                this.loading = false;
            });
    }
}



export enum CalibrationSessionProgress {
    Unknown = 0,
    VehicleSelection = 1,
    CameraAlignment = 2,
    CameraVerified = 3,
    InProgress = 4,
    Ended = 5,
}

export const CalibrationSessionProgressDescription: string[] = [
    "Unknown",
    "Vehicle Selection",
    "Camera Alignment",
    "Camera Verified",
    "In Progress",
    "Ended",
];
export class Globals {

    public static GetEnvironment(): string {
        const host = window.location.hostname;
        if (host.startsWith("manage-qat.")) {
            return "Quality Assurance Testing Environment";
        } else if (host.startsWith("manage-uat.")) {
            return "User Acceptance Testing Environment";
        } else if (host.startsWith("manage-stg.")) {
            return "";
        } else if (host.startsWith("manage.")) {
            return "";
        } else if (host === "localhost") {
            if (window.location.port === "44342")
                return "Development Environment";
        }
        throw new Error(`Unexpected host ${host} can't be mapped to environment`);
    }

    public static GetSignalRFunction(): string {
        const host = window.location.hostname;
        if (host.startsWith("manage-qat.")) {
            return "https://api-qat.auggie.tech/signalr";
        } else if (host.startsWith("manage-uat.")) {
            return "https://api-uat.auggie.tech/signalr";
        } else if (host.startsWith("manage-stg.")) {
            return "https://api-stg.auggie.tech/signalr";
        } else if (host.startsWith("manage.")) {
            return "https://api.auggie.tech/signalr";
        } else if (host === "localhost") {
            if (window.location.port === "44342")
                return "http://localhost:7172/api";
        }
        throw new Error(`Unexpected host ${host} can't be mapped to SignalR Function`);
    }

    public static GetWebApi(): string {
        const host = window.location.hostname;
        if (host.startsWith("manage-qat.")) {
            return "https://api-qat.auggie.tech/manage/";
        } else if (host.startsWith("manage-uat.")) {
            return "https://api-uat.auggie.tech/manage/";
        } else if (host.startsWith("manage-stg.")) {
            return "https://api-stg.auggie.tech/manage/";
        } else if (host.startsWith("manage.")) {
            return "https://api.auggie.tech/manage/";
        } else if (host === "localhost") {
            if (window.location.port === "44342")
                return "https://localhost:44358/";
        }
        throw new Error(`Unexpected host ${host} can't be mapped to API`);
    }

    public static GetCompanionUrl(): string {
        const host = window.location.hostname;
        if (host.startsWith("manage-qat.")) {
            return "https://companion-qat.auggie.tech/";
        } else if (host.startsWith("manage-uat.")) {
            return "https://companion-uat.auggie.tech/";
        } else if (host.startsWith("manage-stg.")) {
            return "https://companion-stg.auggie.tech/";
        } else if (host.startsWith("manage.")) {
            return "https://companion.auggie.tech/";
        } else if (host === "localhost") {
            if (window.location.port === "44342")
                return "https://localhost:44343/";
        }
        throw new Error(`Unexpected host ${host} can't be mapped to API`);
    }

    public static IdentityUrl(url: string): string {
        const host = window.location.hostname;
        if (host.startsWith("manage-qat.")) {
            return `https://auth-qat.airprodiag.com${url}`;
        } else if (host.startsWith("manage-uat.")) {
            return `https://auth-uat.airprodiag.com${url}`;
        } else if (host.startsWith("manage-stg.")) {
            return `https://auth-stg.airprodiag.com${url}`;
        } else if (host.startsWith("manage.")) {
            return `https://auth.airprodiag.com${url}`;
        } else if (host === "localhost") {
            if (window.location.port === "44342")
                return `https://localhost:5001${url}`;
        }
        throw new Error(`Unexpected host ${host} can't be mapped to identity domain`);
    }

    public static GetIdentityClientId(): string {
        const host = window.location.hostname;
        if (host.startsWith("manage-qat.")) {
            return "web@auggie.management.qat";
        } else if (host.startsWith("manage-uat.")) {
            return "web@auggie.management.uat";
        } else if (host.startsWith("manage-stg.")) {
            return "web@auggie.management.stg";
        } else if (host.startsWith("manage.")) {
            return "web@auggie.management.prd";
        } else if (host === "localhost") {
            if (window.location.port === "44342")
                return "web@auggie.management.dev";
        }
        throw new Error(`Unexpected host ${host} can't be mapped to identity client id`);
    }
}
export default Globals;

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "btn-group",
  role: "group",
  "aria-label": "Actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("td", null, [
    _createVNode("div", _hoisted_1, [
      (_ctx.canEdit())
        ? (_openBlock(), _createBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-sm btn-warning",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showVehicleModal && _ctx.showVehicleModal(...args))),
            "data-bs-toggle": "tooltip",
            title: "Edit Vehicle Configuration"
          }, [
            _createVNode(_component_fa_icon, {
              icon: "edit",
              class: "fa-w-20"
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.canView())
        ? (_openBlock(), _createBlock("button", {
            key: 1,
            type: "button",
            class: "btn btn-sm btn-warning",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showVehicleModal && _ctx.showVehicleModal(...args))),
            "data-bs-toggle": "tooltip",
            title: "Display Vehicle Configuration"
          }, [
            _createVNode(_component_fa_icon, {
              icon: "eye",
              class: "fa-w-20"
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
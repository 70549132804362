

import { VNode } from "@vue/runtime-core";
import { Vue, Options } from "vue-class-component";

import GridPanel from "@/shared/components/common/GridPanel.vue";
import { GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { State } from "@progress/kendo-data-query";
import { DataRequest, DataResult } from "@/shared/support/Data";
import Utility from "@/shared/support/Utility";

import { CalibrationSessionLogDto, CalibrationSessionLogGridDto } from "@/shared/models/CalibrationSessionLogDto";
import { GenericActiveStatus } from "@/shared/enums/GenericActiveStatus";
import { SessionTypeDescription } from "@/shared/enums/SessionType";
import CalibrationButtonCell from "./CalibrationButtonCell.vue";
import NotesDisplayGridCell from "./NotesDisplayGridCell.vue";

@Options({
    components: {
        CalibrationButtonCell
    },
    watch: {
    }
})
export default class CalibrationsGrid extends Vue {

    loading = false;
    status: GenericActiveStatus = GenericActiveStatus.Active;

    dataResult:DataResult<CalibrationSessionLogDto> = GridPanel.EmptyDataResult;
    columns: GridColumnProps[] = [ // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridColumnProps/
        { field: "CalibrationSessionGuid", hidden: true },
        { field: "Actions", title: " ", sortable: false, width: "90px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActions },
        { field: "DeviceName", title: "Device", width: "150px", },
        { field: "SessionStartDt__Display", title: "Started", width: "180px", },
        { field: "SessionEndDt__Display", title: "Ended", width: "180px", },
        { field: "CalibrationFinalStatus__Display", title: "Status", width: "180px", },
        { field: "SessionType__Display", title: "Type", width: "140px", },
        { field: "VehicleFullName", sortable: false, title: "Vehicle", width: "280px", },
        { field: "Notes", title: "Notes", width: "80px", headerClassName: "gCenter", className: "gCenter", cell: this.cellNotes },
        { field: "TechnicianName", title: "Technician", width: "300px", },
    ];

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActions(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            CalibrationButtonCell, { // the component used to render the cell
                calibrationSessionGuid: props.dataItem.CalibrationSessionGuid,
            });
    }

    loadGrid(dataState: State, search: string): void {

        this.loading = true; // turn on page's loading indicator

        // load grid data
        const request = new DataRequest();
        request.$getGridData<CalibrationSessionLogGridDto[]>("/Service/CalibrationSessionLog", dataState, search, { StatusFilter: this.status })
            .then((result): void => {
                // format all data that is not readily renderable
                for (const row of result.Rows) {
                    row.SessionStartDt__Display = row.SessionStartDt ? Utility.getFormattedDateTime(new Date(row.SessionStartDt)) : "";
                    row.SessionEndDt__Display = row.SessionEndDt ? Utility.getFormattedDateTime(new Date(row.SessionEndDt)) : "";
                    row.SessionType__Display = SessionTypeDescription[row.SessionType];
                    row.CalibrationFinalStatus__Display = row.CalibrationFinalStatus?.replace("Calibration", "") || "";
                }
                // set grid data
                this.loading = false;  // turn off page's loading indicator
                this.dataResult = { // update grid's model
                    data: result.Rows,
                    total: result.Total,
                };
            })
            .catch((reason: any): void => {
                this.loading = false; // turn off page's loading indicator
                this.dataResult = GridPanel.EmptyDataResult;
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellNotes(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            NotesDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.Notes,  // props to pass to the component
            });
    }

    reloadGrid(): void {
        this.$refs.sessionsGrid.reload();
    }

    $refs!: {
        sessionsGrid: GridPanel,
    }
}




import { DataRequest } from "@/shared/support/Data";
import { Global } from "@/support/GlobalData";
import { Vue } from "vue-class-component";

export default class SessionNote extends Vue {

    currentNote: string|null = null;

    get note(): string {
        if (!this.currentNote) {
            if (Global.CalibrationSession?.Notes)
                this.currentNote = Global.CalibrationSession?.Notes;
            if (!this.currentNote)
                this.currentNote = null;
        }
        return this.currentNote || "";
    }
    set note(value: string) {
        this.currentNote = value;
    }

    public Update(): void {
        this.currentNote = Global.CalibrationSession?.Notes || null;
    }

    noteBlur(): void {
        if (!Global.CalibrationSession) return;
        if (!this.currentNote && !Global.CalibrationSession.Notes) return;// nothing changed
        if (this.currentNote === Global.CalibrationSession.Notes) return; // still nothing changed

        Global.CalibrationSession.Notes = this.currentNote;

        const request = new DataRequest();
        request.autoToastOnFailure = false;
        request.$post<any, any>(`/Service/CalibrationSessionRecord/${Global.CalibrationSession!.CalibrationSessionGuid}/Notes`, { Notes: this.currentNote });
    }
}


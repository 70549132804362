

import { CalibrationEvent, CalibrationEventDescription } from "@/shared/enums/CalibrationEvent";
import CalibrationEventDto from "@/shared/models/CalibrationEventDto";
import Panel from "@/shared/components/common/Panel.vue";
import Utility from "@/shared/support/Utility";
import Globals from "@/support/Globals";
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
    components: {
        Panel,
    }
})
export default class CalibrationEventEntry extends Vue {

    @Prop({ default: "", required: true }) readonly eventData: CalibrationEventDto | null = null;

    calibrationEventDesc(): string {
        if (!this.eventData) return "";
        const text = CalibrationEventDescription[this.eventData.CalibrationEvent] || "";
        return `${text} (${this.eventData.CalibrationEvent})`;
    }
    calibrationEventDt(): string {
        if (!this.eventData) return "";
        return Utility.getFormattedDateTimeHHMMSS(new Date(this.eventData.CalibrationEventDt));
    }
    isDebugValues(): boolean {
        return this.eventData?.CalibrationEvent === CalibrationEvent.DebugValues;
    }
    calibrationEventDataJson(): string {
        if (!this.eventData) return "";
        if (!this.eventData.CalibrationEventDataJson) return "";
        const obj = JSON.parse(this.eventData.CalibrationEventDataJson);
        return JSON.stringify(obj, null, 4).trimEnd();
    }
    windshieldOverride(): string {
        if (!this.eventData) return "";
        return this.eventData.WindshieldOverride?.toFixed(2) || "";
    }
    lightIntensity(): string {
        if (!this.eventData) return "";
        const val = this.eventData.LightIntensity?.toFixed(0) || "";
        if (!val) return "";
        return `${val} %`;
    }
    deviceStatus(): string {
        if (!this.eventData) return "";
        if (!this.eventData.DeviceStatus) return "";
        const obj = JSON.parse(this.eventData.DeviceStatus);
        return JSON.stringify(obj, null, 4).trimEnd();
    }
    targetImage(): string {
        if (!this.eventData) return "";
        if (!this.eventData.TargetImage) return "";
        if (!this.eventData.TargetImage.UploadId) return "";
        return this.downloadableImage(this.eventData.TargetImage.UploadId);
    }
    targetImageUpload(): string {
        if (!this.eventData) return "";
        if (!this.eventData.TargetImageUploadId) return "";
        return this.downloadableImage(this.eventData.TargetImageUploadId);
    }
    cameraImageUpload(): string {
        if (!this.eventData) return "";
        if (!this.eventData.CameraImageUploadId) return "";
        return this.downloadableImage(this.eventData.CameraImageUploadId);
    }
    cameraAlignmentImageUpload(): string {
        if (!this.eventData) return "";
        if (!this.eventData.CameraAlignmentImageUploadId) return "";
        return this.downloadableImage(this.eventData.CameraAlignmentImageUploadId);
    }
    getUrl(id: number): string {
        return Utility.formatUrl(`/Service/Uploads/GetContentById/${id}`, null, Globals.GetWebApi(), true);
    }
    downloadableImage(id: number): string {
        const url = this.getUrl(id);
        return `<a href="${url}" target="_blank"><img class="img-fluid img-border img-background" src="${url}"></a>`;
    }
}


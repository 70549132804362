

import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Utility, { ImageSize } from "@/shared/support/Utility";
import Toast from "@/shared/support/Toast";
import ValidationMessage, { ValidationLevel } from "@/shared/components/common/ValidationMessage.vue";
import GridPanel from "@/shared/components/common/GridPanel.vue";
import { GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { State } from "@progress/kendo-data-query";
import { VNode } from "vue";
import { DataRequest } from "@/shared/support/Data";

import { confirmOk } from "@/shared/components/common/AlertDialog.vue";

import TargetImageButtonCell from "./TargetImageButtonCell.vue";
import TargetImageNameCell from "./TargetImageNameCell.vue";
import TargetToCameraCell from "./TargetToCameraCell.vue";
import AssumedFullTargetDistanceCell from "./AssumedFullTargetDistanceCell.vue";

import { VehicleCalibrationTargetImageDto } from "@/shared/models/VehicleCalibrationTargetImageDto";
import { TargetImageDto } from "@/shared/models/TargetImageDto";
import { UploadDto } from "@/shared/models/UploadDto";
import { UploadType } from "@/shared/enums/UploadType";
import { CalibrationType } from "@/shared/enums/CalibrationType";
import Globals from "@/support/Globals";

interface VehicleCalibrationTargetImageGrid extends VehicleCalibrationTargetImageDto {
    // eslint-disable-next-line
    Dimensions__Display: string;
    // eslint-disable-next-line
    ImageUrl__Display: string;
}

@Options<TargetImages>({
    components: {
        TargetToCameraCell,
    },
    watch: {
        images(val: VehicleCalibrationTargetImageDto[]): void {
            this.reloadGrid();
        }
    },
    emits: [ "update:images" ]
})
export default class TargetImages extends Vue {

    @Prop({ required: true }) readonly images: VehicleCalibrationTargetImageDto[] = [];
    @Prop({ required: true }) readonly vehicleId: number = 0;
    @Prop({ required: true }) readonly vehicleCalibrationId: number = 0;
    @Prop({ required: true }) readonly calibrationType: CalibrationType = null!;
    @Prop({ required: false }) readonly readonly: boolean = false;

    selectedImage = 0;
    file: File|null = null;

    targetImageId: number = 0;
    uploadId: number = 0;
    fileName: string = "";
    fileDescription: string = "";
    fileSize: number = 0;
    imageSize: ImageSize | null = null; // new upload only
    imageUrl: string = "";

    loading = false;
    uploading = false;
    gridErrors = 0;

    haveImage(): boolean { return this.haveExistingImage() || this.haveNewImage(); }
    haveExistingImage(): boolean { return !!this.selectedImage; }
    haveNewImage(): boolean { return this.file != null; }

    selectedImageErrorMessage = "";
    fileErrorMessage = "";
    fileNameErrorMessage = "";

    initializeForm():void {
        this.clearForm();
        this.images.splice(0);
        this.reloadGrid();
    }

    clearForm(): void {
        if (this.$refs.uploadfile)
            this.$refs.uploadfile.value = "";
        this.selectedImage = 0;
        this.file = null;
        this.fileName = "";
        this.fileDescription = "";
        this.fileSize = 0;
        this.imageSize = null;
        this.targetImageId = 0;
        this.uploadId = 0;
        this.imageUrl = "";
        this.loading = false;
        this.uploading = false;
        this.selectedImageErrorMessage = "";
        this.fileErrorMessage = "";
        this.fileNameErrorMessage = "";
    }

    validForm(show: ValidationLevel = ValidationLevel.Test): boolean {
        if (this.readonly) return true;
        const errors: string[] = [];

        this.validSelectedImage(errors, show);
        this.validFileName(errors, show);

        if (show === ValidationLevel.Show)
            ValidationMessage.display(errors);
        return errors.length === 0;
    }
    validSelectedImage(errors?: string[], show?: ValidationLevel): void {
        if (this.readonly) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            return "";
        }, (message: string):void =>{ this.selectedImageErrorMessage = message; });
    }
    validFileName(errors?: string[], show?: ValidationLevel): void {
        if (this.readonly) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (!this.fileName) return "The Image Name field is required";
            return null;
        }, (message: string):void =>{ this.fileNameErrorMessage = message; });
    }

    addClicked(): void {

        if (!this.validForm(ValidationLevel.Show))
            return;

        if (this.haveExistingImage())
            this.saveExistingTargetImage();
        else if (this.haveNewImage())
            this.uploadFile();
    }

    uploadFile(): void {

        const dr = new DataRequest();

        const file = this.getUploadFile();
        if (!file) return;

        this.loading = true;
        this.uploading = true;
        this.uploadPercent = 0;

        dr.$postFile<UploadDto>("/Service/Uploads/UploadFile", { type: UploadType.TargetImage }, file, this.fileName, (ev: ProgressEvent): any|null =>{
            const total = ev.total;
            const position = ev.loaded;
            if (ev.lengthComputable)
                this.uploadPercent = Math.ceil(position / total * 100);
        })
            .then((result: UploadDto): void => {
                this.loading = false;
                this.uploading = false;
                if (result.UpdateResult.Success) {
                    this.uploadId = result.UploadId!;
                    this.saveNewTargetImage();
                } else {
                    confirmOk(result.UpdateResult.Message || "Image upload failed!");
                }
            })
            .catch((reason): void =>{
                this.loading = false;
                this.uploading = false;
            });
    }
    getUploadFile(): File | null {
        return this.$refs.uploadfile && this.$refs.uploadfile.files && this.$refs.uploadfile.files[0];
    }
    get uploadPercent(): number {
        return this.uploadPercentStore;
    }
    set uploadPercent(val: number) {
        this.uploadPercentStore = val;
    }
    private uploadPercentStore: number = 0;
    get progressBarStyle(): any {
        return {
            width: `${this.uploadPercent}%`
        };
    }

    saveNewTargetImage(): void {
        this.loading = true;

        const targetImage: Partial<TargetImageDto> = {
            TargetImageId: 0,
            UploadId: this.uploadId,
            ImageName: this.fileName,
            Description: this.fileDescription,
        };

        const dr = new DataRequest();
        dr.$post<Partial<TargetImageDto>, TargetImageDto>("/Service/TargetImages", null, targetImage)
            .then((targetImage: TargetImageDto): void => {
                this.loading = false;
                if (targetImage.UpdateResult.Success) {
                    if (targetImage.UpdateResult.Message)
                        Toast.success(targetImage.UpdateResult.Message);
                    const target: Partial<VehicleCalibrationTargetImageDto> = {
                        VehicleCalibrationId: this.vehicleCalibrationId,
                        TargetImageId: targetImage.TargetImageId,
                        SequenceNumber: 0,
                        ImageName: targetImage.ImageName,
                        Description: targetImage.Description,
                        TargetImage: {
                            UploadId: this.uploadId,
                            ImageWidth: targetImage.ImageWidth,
                            ImageHeight: targetImage.ImageHeight,
                        }
                    };
                    this.images.push(target as VehicleCalibrationTargetImageDto);
                    this.reloadGrid();
                } else {
                    confirmOk(targetImage.UpdateResult.Message || "Target Image save failed!");
                }
            })
            .catch((reason): void =>{
                this.loading = false;
            });
    }

    saveExistingTargetImage(): void {
        const target: Partial<VehicleCalibrationTargetImageDto> = {
            VehicleCalibrationId: this.vehicleCalibrationId,
            TargetImageId: this.targetImageId,
            SequenceNumber: 0,
            ImageName: this.fileName,
            Description: this.fileDescription,
            TargetImage: {
                UploadId: this.uploadId,
                ImageWidth: this.imageSize!.Width,
                ImageHeight: this.imageSize!.Height,
            }
        };
        this.images.push(target as VehicleCalibrationTargetImageDto);
        this.reloadGrid();
    }

    setExistingImage(): void {

        this.loading = true;

        if (!this.selectedImage) {
            this.clearForm();
        } else {
            const dr = new DataRequest();
            dr.$get<TargetImageDto>(`/Service/TargetImages/${this.selectedImage}`)
                .then((target: TargetImageDto): void => {
                    this.loading = false;

                    this.fileName = target.ImageName;
                    this.fileDescription = target.Description || "";
                    this.fileSize = 0; // not used
                    this.targetImageId = target.TargetImageId;
                    this.uploadId = target.UploadId;
                    this.imageSize = { Width: target.ImageWidth, Height: target.ImageHeight };
                    this.imageUrl = Utility.formatUrl(`/Service/Uploads/GetContentById/${target.UploadId}`, null, Globals.GetWebApi(), true);

                    this.validForm(ValidationLevel.Show);// to show any errors immediately
                })
                .catch((reason: any): void => {
                    this.loading = false;
                    Toast.error(`The selected image cannot be loaded: ${reason}`);
                });
        }
    }

    setNewImage(e: Event): void {

        this.file = null;

        const file = this.getUploadFile();
        if (!file)
            return;

        this.loading = true;

        if (file.size > 104857600) {
            this.fileErrorMessage = `Image ${file.name} exceeds the limit of 100 MB.`;
            this.loading = false;
            Toast.error(this.fileErrorMessage);
            return;
        }
        const name = file.name;
        const lastDot = name.lastIndexOf(".");
        this.fileName = lastDot > 0 ? name.substring(0, lastDot) : name;
        this.fileSize = file.size;

        Utility.getImageDimensions(file)
            .then((size: ImageSize): void => {
                this.file = file;
                this.imageSize = size;
                this.loading = false;
            }).catch((): void => {
                this.imageSize = null;
                this.loading = false;
                this.fileErrorMessage = `Image ${file!.name} is not a valid image file.`;
                Toast.error(this.fileErrorMessage);
            });
    }

    getDimensionsFormatted(imageSize: ImageSize|null): string {
        if (!imageSize) return "";
        return `${imageSize.Width} x ${imageSize.Height} (width x height in pixels)`;
    }
    getFileSizeFormatted(): string {
        return Utility.formatSizeInKB(this.fileSize);
    }
    getImageUrl(): string {
        if (this.haveNewImage()) {
            const file =  this.getUploadFile();
            if (!file)
                return "";
            const blob = URL.createObjectURL(file);
            return blob;
        } else if (this.haveExistingImage()) {
            return this.imageUrl;
        }
        return "";
    }

    dataResult = GridPanel.EmptyDataResult;
    getColumns(): GridColumnProps[] {
        const gridProps = this.readonly ?
            [   // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridColumnProps/
                { field: "SequenceNumber", title: " ", width: "50px", },
                { field: "VehicleTargetImageId", hidden: true },
                // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
                { field: "ImageName", title: "Image Name", width: "580px", cell: (o: any, d: VNode | null, p: GridCellProps, l: any): VNode => { return this.cellName(o,d,p,l); }},
                { field: "Dimensions__Display", title: "Dimensions", width: "120px", },
                { field: "ImageUrl__Display", hidden: true },
            ] : [
                // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
                { field: "Actions", title: " ", width: "120px", cell: (o: any, d: VNode | null, p: GridCellProps, l: any): VNode => { return this.cellActions(o,d,p,l); }},
                { field: "SequenceNumber", title: " ", width: "50px", },
                { field: "VehicleTargetImageId", hidden: true },
                // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
                { field: "ImageName", title: "Image Name", width: "520px", cell: (o: any, d: VNode | null, p: GridCellProps, l: any): VNode => { return this.cellName(o,d,p,l); }},
                { field: "Dimensions__Display", title: "Dimensions", width: "120px", },
                { field: "ImageUrl__Display", hidden: true },
            ];

        if (Number(this.calibrationType) === CalibrationType.Sequential) {
            if (this.readonly) {
                gridProps.push(
                    { field: "TargetToCamera", title: "Target To Camera", width: "150px", },
                    { field: "AssumedFullTargetDistance", title: "Assumed Full Target Dist.", width: "200px", }
                );
            } else {
                gridProps.push(
                    { field: "TargetToCamera", title: "Target To Camera", width: "150px", cell: (o: any, d: VNode | null, p: GridCellProps, l: any): VNode => { return this.callTargetToCamera(o,d,p,l); }},
                    { field: "AssumedFullTargetDistance", title: "Assumed Full Target Dist.", width: "200px", cell: (o: any, d: VNode | null, p: GridCellProps, l: any): VNode => { return this.cellAssumedFullTargetDistance(o,d,p,l); }}
                );
            }
        }
        return gridProps;
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActions(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            TargetImageButtonCell, { // the component used to render the cell
                index: props.dataItem.SequenceNumber-1,
                total: this.dataResult.total,
                imageName: props.dataItem.ImageName,
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellName(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            TargetImageNameCell, { // the component used to render the cell
                imageId: props.dataItem.VehicleTargetImageId,
                imageUrl: props.dataItem.ImageUrl__Display,
                imageName: props.dataItem.ImageName,
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    callTargetToCamera(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            TargetToCameraCell, { // the component used to render the cell
                value: props.dataItem.TargetToCamera,
                index: props.dataItem.SequenceNumber-1,
                readonly: this.readonly,
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellAssumedFullTargetDistance(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            AssumedFullTargetDistanceCell, { // the component used to render the cell
                value: props.dataItem.AssumedFullTargetDistance,
                index: props.dataItem.SequenceNumber-1,
                readonly: this.readonly,
            });
    }
    loadGrid(dataState: State, search: string): void {
        this.loading = true; // turn on page's loading indicator
        for (const row of this.images as VehicleCalibrationTargetImageGrid[]) {
            if (row.TargetImage && row.TargetImage.ImageWidth && row.TargetImage.ImageHeight) {
                row.Dimensions__Display = `${row.TargetImage.ImageWidth} x ${row.TargetImage.ImageHeight}`;
                row.ImageUrl__Display = Utility.formatUrl(`/Service/Uploads/GetContentById/${row.TargetImage.UploadId}`, null, Globals.GetWebApi(), true);
            }
        }
        // set grid data
        this.loading = false;  // turn off page's loading indicator
        this.dataResult = { // update grid's model
            data: this.images,
            total: this.images.length,
        };
        this.gridErrors = 0;
    }
    reloadGrid(): void {
        this.resequence();
        this.clearForm();
        this.$refs.imageGrid.reload();
        this.$emit("update:images", this.images);
    }
    resequence(): void {
        let seq = 0;
        for (const img of this.images) {
            img.SequenceNumber = ++seq;
        }
    }
    get gridFull(): boolean {
        if (Number(this.calibrationType) === CalibrationType.OneTime) {
            if (this.images.length === 1)
                return true;
        }
        return false;
    }

    getSearchUrl(): string {
        return "/Service/TargetImages/DisplayList";
    }

    gridUpClicked(index: number): void {
        const img = this.images[index];
        this.images.splice(index, 1);
        this.images.splice(index-1, 0, img);
        this.reloadGrid();
    }
    gridDownClicked(index: number): void {
        const img = this.images[index];
        this.images.splice(index, 1);
        this.images.splice(index+1, 0, img);
        this.reloadGrid();
    }
    gridRemoveClicked(index: number): void {
        this.images.splice(index, 1);
        this.reloadGrid();
    }
    gridUpdateTargetToCamera(index: number, value: string): void {
        const v = Number(value);
        if (v)
            this.images[index].TargetToCamera = v;
        else
            this.images[index].TargetToCamera = null;
    }
    gridUpdateAssumedFullTargetDistance(index: number, value: string): void {
        const v = Number(value);
        if (v)
            this.images[index].AssumedFullTargetDistance = v;
        else
            this.images[index].AssumedFullTargetDistance = null;
    }
    gridValid(valid: boolean): void {
        this.gridErrors += valid ? -1 : +1;
        if (this.gridErrors <= 0)
            this.gridErrors = 0;
    }

    $refs!: {
        imageGrid: GridPanel;
        uploadfile: HTMLInputElement;
    }
}


import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { CalibrationType } from "@/shared/enums/CalibrationType";
import CalibrationEventDto from "@/shared/models/CalibrationEventDto";
import { DataRequest } from "@/shared/support/Data";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { SessionType } from "@/shared/enums/SessionType";

export class CalibrationEventSupport {

    public static readonly MaxRetries = 3;
    public static readonly MaxRetriesUser = 3;
    public static RetryInterval = 30*1000;

    public static sendEvent(sessionGuid: string, name: CalibrationEvent): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = { CalibrationSessionGuid: sessionGuid, CalibrationEvent: name } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventSessionType(sessionGuid: string, name: CalibrationEvent, sessionType: SessionType): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = { CalibrationSessionGuid: sessionGuid, CalibrationEvent: name, SessionType: sessionType } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventTargetImageUploadId(sessionGuid: string, name: CalibrationEvent, targetImageUploadId: number, imageNumber: number): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: sessionGuid,
            CalibrationEvent: name,
            TargetImageUploadId: targetImageUploadId,
            TargetNumber: imageNumber,
        } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventCalibrationRestart(sessionGuid: string, name: CalibrationEvent, windshieldAngle: number): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = { CalibrationSessionGuid: sessionGuid, CalibrationEvent: name, WindshieldOverride: windshieldAngle } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventVehicleSelection(sessionGuid: string, name: CalibrationEvent,
        year: number, make: string, model: string, calType: CalibrationType, vin: string): Promise<CalibrationEventDto> {

        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: sessionGuid,
            CalibrationEvent: name,
            VehicleYear: year,
            VehicleMake: make,
            VehicleModel: model,
            CalibrationType: calType,
            Vin: vin,
        } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventLightIntensity(sessionGuid: string, name: CalibrationEvent, intensity: number): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = { CalibrationSessionGuid: sessionGuid, CalibrationEvent: name, LightIntensity: intensity } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }
    public static sendEventStartTv(sessionGuid: string,  name: CalibrationEvent, techGuid: string): Promise<CalibrationEventDto> {
        const dr = new DataRequest(undefined, undefined, CalibrationEventSupport.MaxRetries);
        const calibrationEvent = {
            CalibrationSessionGuid: sessionGuid,
            CalibrationEvent: name,
            TechnicianGuid: techGuid,
        } as CalibrationEventDto;
        return dr.$post<CalibrationEventDto>("/Service/CalibrationEvent", null, calibrationEvent);
    }

    /**
     * Handle a retriable event.
     * By design, any SignalR message (related to a session) that arrives after sending an event will cancel the retry of this event.
     * @param cb The callback that sends an event.
     * @param msgCb The callback used to ask the user whether to retry/abort.
     */
    public static RetryableEvent(cb: () => void, msgCb:() => Promise<ButtonClicked>, cancelCb?:() => void,
        timeoutOverrideSeconds?: number): void {
        const timeout = timeoutOverrideSeconds ? timeoutOverrideSeconds*1000 : CalibrationEventSupport.RetryInterval;
        console.debug("RetryableEvent");
        CalibrationEventSupport.CancelRetryableEvents();
        CalibrationEventSupport.HandleRetryableEvent(cb, msgCb, cancelCb, timeout);
        cb(); // send event
    }
    private static HandleRetryableEvent(cb: () => void, msgCb:() => Promise<ButtonClicked>, cancelCb:(() => void)|undefined, timeout: number): void {
        console.debug("HandleRetryableEvent");
        CalibrationEventSupport.retriableEventTimeout = setTimeout((): void => {
            CalibrationEventSupport.CancelRetryableEvents();
            msgCb() // show message box
                .then((clicked: ButtonClicked): void => {
                    if (clicked === ButtonClicked.Ok && CalibrationEventSupport.currentRetries < CalibrationEventSupport.MaxRetriesUser) {
                        setTimeout((): void => {
                            CalibrationEventSupport.currentRetries++;
                            CalibrationEventSupport.RetryableEvent(cb, msgCb, cancelCb, timeout);// try again with sending event
                        }, 1);// no nested calls
                    } else if (clicked === ButtonClicked.Alt1) { // wait
                        // nothing to do
                        CalibrationEventSupport.HandleRetryableEvent(cb, msgCb, cancelCb, timeout);// wait
                    } else {
                        if (cancelCb)
                            cancelCb();
                    }
                });
        }, timeout) as unknown as number;
    }

    public static retriableEventTimeout = 0;
    public static currentRetries = 0;
    public static CancelRetryableEvents(): void {
        console.debug("CancelRetryableEvents");
        if (CalibrationEventSupport.retriableEventTimeout) {
            clearTimeout(CalibrationEventSupport.retriableEventTimeout);
        }
        CalibrationEventSupport.retriableEventTimeout = 0;
        CalibrationEventSupport.currentRetries = 0;
    }
}

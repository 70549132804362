

import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { DataRequest } from "@/shared/support/Data";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import Toast from "@/shared/support/Toast";
import Events from "@/shared/support/Events";
import { UserRoles } from "@/identity";
import Utility from "@/shared/support/Utility";
import TooltipSupport from "@/shared/support/Tooltip";
import App from "@/App.vue";
import Globals from "@/support/Globals";

export default class DeviceButtonCell extends Vue {

    @Prop({ required: true }) readonly deviceGuid: number = 0;
    @Prop({ required: true }) readonly deviceName: string = "";
    @Prop({ required: true }) readonly active: boolean = false;
    @Prop({ required: true }) readonly deviceCalibrationUploadId: number|null = null;
    @Prop({ required: true }) readonly hasCompleteSetup: boolean = false;
    @Prop({ required: true }) readonly hasToolKey: boolean = false;

    canEdit(): boolean {
        return UserRoles.has("AuggieDeviceEdit");
    }
    canView(): boolean {
        return !this.canEdit() && UserRoles.has("AuggieDeviceView");
    }
    canRemove(): boolean {
        return UserRoles.has("AuggieDeviceDelete");
    }

    activatePossible(): boolean {
        return !this.active && this.hasCompleteSetup;
    }

    showDeviceModal(): void {
        App.MainApp.routerPush("/devices", { deviceguid: this.deviceGuid });
    }
    removeDevice(): void {
        confirmYesNo(`Are you sure you want to deactivate Device "${this.deviceName}"?`)
            .then((clicked: ButtonClicked) => {
                if (clicked === ButtonClicked.Ok)
                    this.postRemoveDevice();
            });
    }
    restoreDevice(): void {
        if (this.hasToolKey) {
            confirmOk(`Device ${this.deviceName} cannot be activated because it has an Inventory Tool Key which may conflict with an existing device. Edit the device instead to change the Active status.`);
            return;
        }
        confirmYesNo(`Are you sure you want to activate Device "${this.deviceName}"?`)
            .then((clicked: ButtonClicked) => {
                if (clicked === ButtonClicked.Ok)
                    this.postRestoreDevice();
            });
    }
    private postRemoveDevice(): void {
        const dr = new DataRequest();
        dr.$delete<boolean>(`/Service/Device/${this.deviceGuid}`)
            .then((result: boolean): void => {
                if (result) {
                    Toast.success(`Device "${this.deviceName}" has been successfully deactivated.`);
                    Events.bubbleEvent(this, "device-grid-reload", this.deviceGuid);
                } else {
                    confirmOk(`An error occurred deactivating Device "${this.deviceName}".`);
                }
            }).catch((reason): void => {
                confirmOk(`An error occurred deactivating Device "${this.deviceName}": ${reason}`);
            });
    }
    private postRestoreDevice(): void {
        const dr = new DataRequest();
        dr.$delete<boolean>(`/Service/Device/${this.deviceGuid}`, { Restore: true })
            .then((result: boolean): void => {
                if (result) {
                    Toast.success(`Device "${this.deviceName}" has been successfully activated.`);
                    Events.bubbleEvent(this, "device-grid-reload", this.deviceGuid);
                } else {
                    confirmOk(`An error occurred activating Device "${this.deviceName}".`);
                }
            }).catch((reason): void => {
                confirmOk(`An error occurred activating Device "${this.deviceName}": ${reason}`);
            });
    }
    get downloadUrl(): string {
        return Utility.formatUrl(`/Service/Uploads/GetContentById/${this.deviceCalibrationUploadId}`, null, Globals.GetWebApi(), true);
    }
    clearTooltips(): void {
        TooltipSupport.clearTooltips();
    }
}

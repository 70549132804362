

import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options<GridHeader>({
    watch: {
        search(val: string): void {
            this.search = val;
            if (this.autoSearch && this.autoSearchTimer) {
                this.clearSearchTimeout();
                this.searchTimeout = setTimeout((): void => {
                    this.loadgrid();
                }, this.autoSearchTimer);
            }
        }
    }
})
export default class GridHeader extends Vue {

    searchTimeout: number = 0;

    @Prop({ default: "" }) readonly modelValue: string = "";
    @Prop({ default: true }) readonly reloadButton: boolean = true;
    @Prop({ default: "" }) readonly cssHeader: string = "";
    @Prop({ default: false }) readonly autoSearch = false;
    @Prop({ default: 0 }) readonly autoSearchTimer: number = 0;
    @Prop({ default: false }) readonly loading = false;

    search: string = "";

    mounted(): void {
        this.search = this.modelValue;
    }

    headerCss(): string  {
        return `card-header ${this.cssHeader}`;
    }

    loadgrid(): void {
        this.clearSearchTimeout();
        this.$emit("loadgrid", this.search);
    }

    private clearSearchTimeout():void {
        if (this.searchTimeout) 
            clearTimeout(this.searchTimeout);
        this.searchTimeout = 0;
    }
}




import { UserRoles } from "@/identity";
import { SessionType } from "@/shared/enums/SessionType";
import Events from "@/shared/support/Events";
import Globals from "@/support/Globals";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
    emits: [ "device-connect", "teamviewer-enable" ]
})
export default class DeviceButtonCell extends Vue {

    @Prop({ required: true }) readonly available: boolean = false;
    @Prop({ required: true }) readonly calibrationSessionGuid: string = "";
    @Prop({ required: true }) readonly companionGuid: string = "";
    @Prop({ required: true }) readonly verified: boolean = false;
    @Prop({ required: true }) readonly allowAssistedCalibrationInd: boolean = false;
    @Prop({ required: true }) readonly allowAssistedTargetDisplayInd: boolean = false;

    getCssClass(): string {
        if (!this.verified)
            return "btn btn-sm btn-info btn-warning";
        return "btn btn-sm btn-info btn-success";
    }
    getViewUrl(): string {
        return `${Globals.GetCompanionUrl()}?s=${this.calibrationSessionGuid}&c=${this.companionGuid}`;
    }

    canEdit(): boolean {
        return UserRoles.has("AuggieSessionEdit");
    }

    connectAssistedCalibrationClicked(): void {
        Events.bubbleEvent(this, "device-connect", this.calibrationSessionGuid, SessionType.AssistedCalibration);
    }
    connectAssistedTargetDisplayClicked(): void {
        Events.bubbleEvent(this, "device-connect", this.calibrationSessionGuid, SessionType.AssistedTargetDisplay);
    }
    tvClicked(): void {
        Events.bubbleEvent(this, "teamviewer-enable", this.calibrationSessionGuid);
    }
}


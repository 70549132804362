// https://github.com/soukoku/vue-oidc-client (Vue 3)
// https://www.richard-banks.org/2018/11/securing-vue-with-identityserver-part7.html (really for Vue 2 only)

/* eslint-disable @typescript-eslint/naming-convention */

import { Profile, User } from "oidc-client";
import { createOidcAuth, SignInType } from "vue-oidc-client/vue3";
import { DataRequest } from "@/shared/support/Data";
import { SignalR } from "./support/SignalR";
import Globals from "./support/Globals";

const loc = window.location;
const appUrl = `${loc.protocol}//${loc.host}${process.env.BASE_URL}`;
const silentRedirect = `${appUrl}${(appUrl.endsWith("/") ? "" : "/")}auth/signinsilent/main.html`;

export const identity = createOidcAuth("main", SignInType.Window, appUrl, {
    authority: Globals.IdentityUrl(""),
    client_id: Globals.GetIdentityClientId(),
    response_type: "id_token token",
    scope: "openid profile offline_access roles phone email auggiemanagementapi",
    silent_redirect_uri: silentRedirect,
    automaticSilentRenew: true,
});

identity.events.addUserLoaded((user: User): void => {
    console.debug("identity: user loaded", user);

    SignalR.startTechSession();

    // Post to API that a user logged on
    const dr: DataRequest = new DataRequest();
    dr.autoToastOnFailure = false;
    dr.$post("/Service/Users/Load");
});

identity.events.addUserUnloaded((): void => {
    console.debug("identity: user unloaded");
});

identity.events.addUserSignedOut((): void => {
    console.debug("identity: user signed out");
});

identity.events.addAccessTokenExpiring(function() {
    console.debug("identity: access token expiring");
});

identity.events.addAccessTokenExpired(function() {
    console.debug("identity: access token expired");
});

identity.events.addSilentRenewError(function(err: Error) {
    console.error("identity: silent renew error", err);
});

identity.events.addUserSessionChanged(function() {
    console.debug("identity: user session changed");
});

export class CurrentUser {

    private static authenticated = true;// must default to true in case of page reloads without User loading

    /**
     * Returns whether the user is authenticated.
     */
    public static get isAuthenticated():boolean {
        return CurrentUser.authenticated && identity.isAuthenticated;
    }

    public static get userProfile(): Profile | null {
        if (CurrentUser.isAuthenticated)
            return identity.userProfile;
        return null;
    }

    public static get userGuid(): string | null {
        if (CurrentUser.isAuthenticated) {
            return identity.userProfile.sub;
        }
        return null;
    }

    /**
     * Defines whether the User was loaded and has been established as logged on with the WebAPI.
     * FOR INTERNAL USE ONLY.
     */
    public static setAuthentication(auth: boolean): void {
        CurrentUser.authenticated = auth && identity.isAuthenticated;
    }

    /**
     * Returns whether the User was loaded and has been established as logged on with the WebAPI.
     * FOR INTERNAL USE ONLY.
     */
    public static verifyAuthentication(): boolean {
        return CurrentUser.authenticated;
    }
}

/**
 * Class to manage/test user roles.
 */
export class UserRoles {
    /**
     * Tests whether the currently authenticated user (if any) has the required user role.
     * @param role The user role to test.
     * @returns true if the user has the required role, false otherwise.
     */
    public static has(role: string): boolean {
        const profile = CurrentUser.userProfile;
        if (!profile) return false;
        // eslint-disable-next-line dot-notation
        const roles = profile["role"] as string[] | null;
        if (!roles) return false;
        return roles.find((v) => v === role) != null;
    }
    /**
     * Tests whether the currently authenticated user (if any) has at least one of the required user roles.
     * @param roles The list of user roles to test.
     * @returns true if the user has at least one of the required role, false otherwise.
     */
    public static hasAny(roles: string[]): boolean {
        for (const role of roles) {
            if (UserRoles.has(role))
                return true;
        }
        return false;
    }
}

export class Access {
    public static getToken(): string | null {
        if (!CurrentUser.isAuthenticated) return null;
        return identity.accessToken;
    }
}

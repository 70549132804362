import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "btn-group",
  role: "group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("td", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("a", {
        href: _ctx.downloadUrl,
        target: "_blank",
        class: "btn btn-sm btn-primary",
        "data-bs-toggle": "tooltip",
        title: "Download",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearTooltips && _ctx.clearTooltips(...args)))
      }, [
        _createVNode(_component_fa_icon, {
          icon: "file-download",
          class: "fa-w-20"
        })
      ], 8, ["href"]),
      (!_ctx.readonly)
        ? (_openBlock(), _createBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-sm btn-danger",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeClicked && _ctx.removeClicked(...args))),
            "data-bs-toggle": "tooltip",
            title: "Remove"
          }, [
            _createVNode(_component_fa_icon, {
              icon: "times",
              class: "fa-w-20"
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
export enum UploadType {
    TargetImage = 1,
    CameraCalibrationImage = 2,
    VehicleDocument = 3,
    DeviceCalibration = 4,
    RenderedTargetImage = 5,
    CameraImagesZip = 6,
    DeviceDocument = 7,
}

export const UploadTypeDescription: string[] = [
    "Target Image",
    "Calibration Image",
    "Vehicle Document",
    "Device Calibration",
    "Target Image",
    "Camera Images ZIP",
    "Device Document",
];
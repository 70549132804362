

import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Events from "@/shared/support/Events";
import Numeric from "@/shared/components/common/Numeric.vue";

@Options({
    watch: {
        value(val: string) {
            this.v = val || "";
            this.valueErrorMessage = "";
        }
    }
})
export default class TargetToCameraCell extends Vue {

    @Prop({ required: true }) readonly value: number|string = "";
    @Prop({ required: true }) readonly index: number = 0;
    @Prop({ required: false }) readonly readonly: boolean = false;

    v = this.value;
    valueErrorMessage = "";

    validValue(): void {
        if (!this.$refs.refValue.isValid()) {
            if (!this.valueErrorMessage)
                Events.bubbleEvent(this, "target-image-grid-valid", false);
            this.valueErrorMessage = "Invalid";
            return;
        }
        if (this.valueErrorMessage)
            Events.bubbleEvent(this, "target-image-grid-valid", true);
        Events.bubbleEvent(this, "target-image-target-height", this.index, this.v);
        this.valueErrorMessage = "";
    }
    mounted(): void {
        this.v = this.value;
    }

    $refs!: {
        refValue: Numeric;
    }
}


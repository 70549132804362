

import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class BoolDisplay extends Vue {

    @Prop({ default: false }) readonly value: boolean = false;

}




import { VNode } from "@vue/runtime-core";
import { Vue, Options } from "vue-class-component";

import GridPanel from "@/shared/components/common/GridPanel.vue";
import { GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { State } from "@progress/kendo-data-query";
import { DataRequest, DataResult } from "@/shared/support/Data";
import MetricsButtonCell from "./MetricsButtonCell.vue";
import { CameraImageMetricsDto, DayMetricsGridDto } from "@/shared/models/CameraImageMetricsDto";
import Utility from "@/shared/support/Utility";

@Options({

})
export default class MetricsGrid extends Vue {

    loading = false;

    dataResult:DataResult<CameraImageMetricsDto> = GridPanel.EmptyDataResult;
    columns: GridColumnProps[] = [ // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridColumnProps/
        { field: "Actions", title: " ", sortable: false, width: "90px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActions },
        { field: "Date__Display", title: "Date", width: "200px", },
        { field: "TotalCalibrations", title: "Total Calibrations", headerClassName: "gRight", className: "gRight", width: "200px", },
        { field: "CameraDetectionAttempts", title: "Attempts", headerClassName: "gRight", className: "gRight", width: "200px", },
        { field: "CameraDetectionFailures", title: "Failures", headerClassName: "gRight", className: "gRight", width: "200px", },
        { field: "ZipFileId", hidden: true },
    ];

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActions(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            MetricsButtonCell, { // the component used to render the cell
                zipFileId: props.dataItem.ZipFileId,
            });
    }

    loadGrid(dataState: State, search: string): void {

        this.loading = true; // turn on page's loading indicator

        // load grid data
        const request = new DataRequest();
        request.$getGridData<DayMetricsGridDto[]>("/Service/CameraImageMetrics", dataState)
            .then((result): void => {
                // format all data that is not readily renderable
                for (const row of result.Rows) {
                    row.Date__Display = Utility.getFormattedDateOnly(row.Date);
                }
                // set grid data
                this.dataResult = { // update grid's model
                    data: result.Rows,
                    total: result.Total,
                };
            })
            .catch((reason: any): void => {
                this.dataResult = GridPanel.EmptyDataResult;
            })
            .finally((): void => {
                this.loading = false; // turn off page's loading indicator
            });
    }
    reloadGrid(): void {
        this.$refs.cameraImagesGrid.reload();
    }

    $refs!: {
        cameraImagesGrid: GridPanel,
    }
}



import { SignalR } from "@/support/SignalR";
import { Options, Vue } from "vue-class-component";

@Options<OnlineStatus>({
})
export default class OnlineStatus extends Vue {

    statusTitle = "Site Disconnected";
    css = "label-danger"

    get statusCss(): string {
        return `${this.css} position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle`;
    }

    mounted(): void {
        SignalR.registerUpdateStatusCallback((css: string, text: string): void => {
            this.statusTitle = text;
            this.css = css;
        });
    }
    unmounted(): void {
        SignalR.registerUpdateStatusCallback(null);
    }
}

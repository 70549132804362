import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "btn-group",
  role: "group",
  "aria-label": "Actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("td", null, [
    _createVNode("div", _hoisted_1, [
      (_ctx.canEdit())
        ? (_openBlock(), _createBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-sm btn-warning",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showPackageModal && _ctx.showPackageModal(...args))),
            "data-bs-toggle": "tooltip",
            title: "Edit Package"
          }, [
            _createVNode(_component_fa_icon, {
              icon: "edit",
              class: "fa-w-20"
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.canView())
        ? (_openBlock(), _createBlock("button", {
            key: 1,
            type: "button",
            class: "btn btn-sm btn-warning",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showPackageModal && _ctx.showPackageModal(...args))),
            "data-bs-toggle": "tooltip",
            title: "Display Package"
          }, [
            _createVNode(_component_fa_icon, {
              icon: "eye",
              class: "fa-w-20"
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode("a", {
        href: _ctx.downloadUrl,
        class: "btn btn-sm btn-primary",
        "data-bs-toggle": "tooltip",
        title: "Download",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.downloadClicked && _ctx.downloadClicked(...args)))
      }, [
        _createVNode(_component_fa_icon, {
          icon: "file-download",
          class: "fa-w-20"
        })
      ], 8, ["href"]),
      (_ctx.canRemove() && _ctx.active)
        ? (_openBlock(), _createBlock("button", {
            key: 2,
            type: "button",
            class: "btn btn-sm btn-danger",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.removePackage && _ctx.removePackage(...args))),
            "data-bs-toggle": "tooltip",
            title: "Deactivate Package"
          }, [
            _createVNode(_component_fa_icon, {
              icon: "times",
              class: "fa-w-20"
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.canRemove() && !_ctx.active)
        ? (_openBlock(), _createBlock("button", {
            key: 3,
            type: "button",
            class: "btn btn-sm btn-success",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.restorePackage && _ctx.restorePackage(...args))),
            "data-bs-toggle": "tooltip",
            title: "Activate Package"
          }, [
            _createVNode(_component_fa_icon, {
              icon: "check-circle",
              class: "fa-w-20"
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}

import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";

@Options({
})
export default class PageNotFound extends PageBase {
    currentPage = window.location.toString();
}




import { UploadDto } from "@/shared/models/UploadDto";
import { DataRequest } from "@/shared/support/Data";
import Toast from "@/shared/support/Toast";
import Events from "@/shared/support/Events";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { confirmOk } from "./AlertDialog.vue";

export default class UploadFile extends Vue {

    @Prop({ default: 0, required: true }) readonly uploadType: number = 0;
    @Prop({ default: "", required: true }) readonly uploadKey: string = "";
    @Prop({ default: [], required: false }) readonly allowedExtensions: string[] = [];
    @Prop({ default: true, required: false }) readonly showSuccessUpload: boolean = true;
    @Prop({ default: true, required: false }) readonly clearAfterUpload: boolean = true;
    @Prop({ default: 100000000, required: false }) readonly maxSize: number = 100000000;
        
    uploading = false;

    clear(): void {
        this.$refs.uploadFile.value = "";
    }

    extensions(): string {
        if (this.allowedExtensions && this.allowedExtensions.length > 0) {
            let s = "";
            for (const ext of this.allowedExtensions)
                s += `.${ext},`;
            return s.substring(0, s.length - 1);
        }
        return "";
    }

    uploadFile(): void {

        const file = this.getUploadFile();
        if (!file) return;
        
        const ix = file.name.lastIndexOf(".");
        if (ix >= 0) {
            const extension = file.name.substring(ix + 1, file.name.length).toLowerCase();
            if (this.allowedExtensions.length && this.allowedExtensions.indexOf(extension) < 0) {
                this.$refs.uploadFile.value = "";
                confirmOk(`The file extension "${extension}" (type ${file.type}) is not allowed.`);
                return;
            }
        }
        if (file.size > this.maxSize) {
            this.$refs.uploadFile.value = "";
            confirmOk(`The file "${file.name}" exceeds the maximum file size limit.`);
            return;
        }

        this.uploading = true;
        Events.bubbleEvent(this, "uploading-start");
        this.$refs.progressBar.style.zIndex = "0"; // hide file input to expose progress bar
        this.uploadPercent = 0;

        const dr = new DataRequest();
        dr.$postFile<UploadDto>("/Service/Uploads/UploadFile", { type: this.uploadType, key: this.uploadKey }, file, file.name, (ev: ProgressEvent): any|null => {
            const total = ev.total;
            const position = ev.loaded;
            if (ev.lengthComputable)
                this.uploadPercent = Math.ceil(position / total * 100);
        })
            .then((result: UploadDto): void => {
                this.$refs.progressBar.style.zIndex = "-1";
                if (this.clearAfterUpload)
                    this.$refs.uploadFile.value = "";
                this.uploading = false;
                Events.bubbleEvent(this, "uploading-end");
                if (result.UpdateResult.Success) {
                    if (this.showSuccessUpload)
                        Toast.success(result.UpdateResult.Message || `File "${file.name}" successfully uploaded.`);
                    this.$emit("uploadfile-file-uploaded", file.name, result);
                } else {
                    confirmOk(result.UpdateResult.Message || "File upload failed!");
                }
            })
            .catch((reason: any): void => {
                this.$refs.progressBar.style.zIndex = "-1";
                this.$refs.uploadFile.value = "";
                this.uploading = false;
                Events.bubbleEvent(this, "uploading-end");
            });
    }
    getUploadFile(): File | null {
        return this.$refs.uploadFile && this.$refs.uploadFile.files && this.$refs.uploadFile.files[0];
    }
    get uploadPercent(): number {
        return this.uploadPercentStore;
    }
    set uploadPercent(val: number) {
        this.uploadPercentStore = val;
    }
    private uploadPercentStore: number = 0;

    get progressBarStyle(): any {
        return {
            width: `${this.uploadPercent}%`
        };
    }

    $refs!: {
        progressBar: HTMLDivElement,
        uploadFile: HTMLInputElement,
    }
}




import { Options, Vue } from "vue-class-component";
import VehiclePanel from "./VehiclePanel.vue";
import WheelArchPanel from "./WheelArchPanel.vue";
import SessionNote from "./SessionNote.vue";
import SessionToolYpr from "./SessionToolYpr.vue";
import { UploadType } from "@/shared/enums/UploadType";
import { Global } from "@/support/GlobalData";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";
import { CalibrationSessionDto } from "@/shared/models/CalibrationSessionDto";

@Options({
    components: {
        VehiclePanel,
        WheelArchPanel,
        SessionNote,
        SessionToolYpr,
    },
})
export default class SessionSidePanel extends Vue {

    getUploadType(): number {
        return UploadType.VehicleDocument;
    }
    getVehicleId(): string {
        return Global.Vehicle?.VehicleId.toString() || "";
    }
    getVehicle(): VehicleDto|null {
        return Global.Vehicle || null;
    }
    getCalibration(): VehicleCalibrationDto|null {
        return Global.VehicleCalibration || null;
    }
    getSession(): CalibrationSessionDto|null {
        return Global.CalibrationSession || null;
    }

    public Update(): void {
        this.$refs.refVehiclePanel?.Update();
        this.$refs.refWheelArchPanel?.Update();
        this.$refs.refSessionNote?.Update();
        this.$refs.refSessionToolYpr?.Update();
    }

    $refs!: {
        refVehiclePanel: VehiclePanel,
        refWheelArchPanel: WheelArchPanel,
        refSessionNote: SessionNote,
        refSessionToolYpr: SessionToolYpr,
    }
}


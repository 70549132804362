

import { UploadDto } from "@/shared/models/UploadDto";
import { DataRequest, GridResult } from "@/shared/support/Data";
import Utility from "@/shared/support/Utility";
import Globals from "@/support/Globals";
import { State } from "@progress/kendo-data-query";
import { GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { VNode } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import GridPanel from "./GridPanel.vue";
import UploadButtonCell from "./UploadButtonCell.vue";
import UploadFile from "./UploadFile.vue";

interface UploadGridDto extends UploadDto {
    // Grid rendering only
    // eslint-disable-next-line
    UploadedDateTime__Display: string;
    // eslint-disable-next-line
    UploadFileName__Display: string;
    // eslint-disable-next-line
    UploadMimeType__Display: string;
    // eslint-disable-next-line
    UploadFileSizeBytes__Display: string;
}

@Options<UploadPanel>({
    components: {
        UploadFile,
    },
    watch: {
        uploadKey(val: number): void {
            this.reloadGrid();
        },
    },
})
export default class UploadPanel extends Vue {

    @Prop({ default: 0, required: true }) readonly uploadType: number = 0;
    @Prop({ default: "", required: true }) readonly uploadKey: string = "";
    @Prop({ default: "", required: true }) readonly title: string = "";
    @Prop({ default: false, required: false }) readonly readonly: boolean = false;
    @Prop({ default: [], required: false }) readonly allowedExtensions: string[] = [];

    loading = false;

    dataResult = GridPanel.EmptyDataResult;
    get columns(): GridColumnProps[] {
        return [ // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridColumnProps/
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            { field: "Actions", title: " ", width: "80px", className: "gCenter", cell: (o: any, d: VNode | null, p: GridCellProps, l: any): VNode => { return this.cellActions(o,d,p,l); }},
            { field: "UploadFileName__Display", title: "File Name", width: "400px", },
            { field: "UploadMimeType__Display", title: "File Type", width: "200px", },
            { field: "UploadFileSizeBytes__Display", title: "File Size", width: "200px", className: "gRight", headerClassName: "gRight" },
            { field: "UploadedDateTime__Display", title: "Uploaded", width: "200px", },
            { field: "ImageUrl", hidden: true },
        ];
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActions(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            UploadButtonCell, { // the component used to render the cell
                uploadId: props.dataItem.UploadId,
                uploadFileName: props.dataItem.UploadFileName__Display,
                readonly: this.readonly,
                apiUrl: Globals.GetWebApi(),
            });
    }
    loadGrid(dataState: State, search: string): void {

        if (DataRequest.haveToken && !DataRequest.haveToken()) return;

        this.loading = true; // turn on page's loading indicator

        // load grid data
        const request = new DataRequest();
        request.$getGridData<UploadGridDto[]>("/Service/Uploads", dataState, search, {
            UploadTypeId: this.uploadType,
            UploadKey: this.uploadKey,
        })
            .then((result: GridResult<UploadGridDto[]>): void => {
                // format all data that is not readily renderable
                for (const row of result.Rows) {
                    row.UploadFileName__Display = `${row.UploadFileName}${row.UploadFileExtension ? `.${row.UploadFileExtension}` : ""}`;
                    row.UploadMimeType__Display = this.displayMimeType(row.UploadMimeType, row.UploadFileExtension);
                    row.UploadFileSizeBytes__Display = Utility.formatSizeInKB(row.UploadFileSizeBytes);
                    row.UploadedDateTime__Display = Utility.getFormattedDateTime(new Date(row.UploadedDateTime));
                }
                // set grid data
                this.loading = false;  // turn off page's loading indicator
                this.dataResult = { // update grid's model
                    data: result.Rows,
                    total: result.Total,
                };
            })
            .catch((reason: any): void => {
                this.loading = false; // turn off page's loading indicator
                this.dataResult = GridPanel.EmptyDataResult;
            });
    }
    reloadGrid(): void {
        this.$refs.uploadGrid.reload();
    }

    displayMimeType(type: string, extension: string): string {
        const t = type.toLowerCase();
        if (extension) {
            const x = extension.toLowerCase();
            if (t.indexOf("application/octet-stream") > -1 && x.indexOf("log") > -1) return "Log";
            if (t.indexOf("application/octet-stream") > -1 && x.indexOf("csv") > -1) return "CSV";
        }
        if (t.indexOf("application/octet-stream") > -1) return "Binary";
        if (t.indexOf("application/pdf") > -1) return "PDF";
        if (t.indexOf("application/rtf") > -1) return "RTF";
        if (t.indexOf("application/vnd.ms-xpsdocument") > -1) return "MS XPS";
        if (t.indexOf("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") > -1) return "MS Excel";
        if (t.indexOf("application/vnd.openxmlformats-officedocument.wordprocessingml.document") > -1) return "MS Word";
        if (t.indexOf("application/vnd.visio") > -1) return "MS Visio";
        if (t.indexOf("image/bmp") > -1) return "Image (BMP)";
        if (t.indexOf("image/gif") > -1) return "Image (GIF)";
        if (t.indexOf("jpeg") > -1) return "Image (JPG)";
        if (t.indexOf("image/png") > -1) return "Image (PNG)";
        if (t.indexOf("image/svg+xml") > -1) return "Image (SVG)";
        if (t.indexOf("image") > -1) return "Image";
        if (t.indexOf("text/html") > -1) return "HTML";
        if (t.indexOf("text/plain") > -1) return "Text";
        if (t.indexOf("text/xml") > -1) return "XML";
        if (t.indexOf("video") > -1) return "Video";
        return type;
    }

    $refs!: {
        uploadGrid: GridPanel,
    }

}


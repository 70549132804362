

import ModalBase from "@/shared/components/common/ModalBase";
import Toast from "@/shared/support/Toast";
import Utility, { ImageSize } from "@/shared/support/Utility";

import { Tab } from "bootstrap";

import ValidationMessage, { ValidationLevel } from "@/shared/components/common/ValidationMessage.vue";
import GridPanel from "@/shared/components/common/GridPanel.vue";
import { GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { State } from "@progress/kendo-data-query";
import { DataRequest, DataResult } from "@/shared/support/Data";
import { VNode } from "vue";

import { UploadDto } from "@/shared/models/UploadDto";
import { UploadType } from "@/shared/enums/UploadType";
import { TargetImageDto } from "@/shared/models/TargetImageDto";
import { VehicleDto } from "@/shared/models/VehicleDto";

import ImageVehicleButtonCell from "./ImageVehicleButtonCell.vue";
import { confirmOk } from "@/shared/components/common/AlertDialog.vue";
import { GenericActiveStatus } from "@/shared/enums/GenericActiveStatus";
import BoolDisplayGridCell from "@/shared/components/common/BoolDisplayGridCell.vue";
import Globals from "@/support/Globals";
import { UserRoles } from "@/identity";

export default class EditImageModal extends ModalBase {

    imageId: number = 0;

    fileName: string = "";
    fileVersion: number = 0;
    fileDescription: string = "";
    fileSize: number = 0;
    imageSize: ImageSize | null = null;
    imageUrl: string = "";
    uploadId: number = 0;
    haveImage = false;
    createdDt = "";
    createdBy = "";
    updatedDt = "";
    updatedBy = "";

    isEdit = false;
    isNew = false;
    isView = false;
    uploading = false;

    fileErrorMessage = "";
    fileNameErrorMessage = "";

    modalTitle(): string {
        if (this.isNew)
            return "Add Image";
        else if (this.isView)
            return "Display Image";
        else
            return "Edit Image";
    }

    initializeForm():void {
        if (this.$refs.uploadfile)
            this.$refs.uploadfile.value = "";
        this.imageId = 0;
        this.clearForm();
    }
    clearForm(): void {
        this.isNew = this.isEdit = this.isView = false;
        this.fileName = "";
        this.fileVersion = 0;
        this.fileDescription = "";
        this.fileSize = 0;
        this.imageSize = null;
        this.imageUrl = "";
        this.uploadId = 0;
        this.haveImage = false;
        this.uploading = false;
        this.fileErrorMessage = "";
        this.fileNameErrorMessage = "";
        this.createdDt = "";
        this.createdBy = "";
        this.updatedDt = "";
        this.updatedBy = "";
    }
    clearUploadFile(): void {
        if (this.isNew) {
            this.fileName = "";
            this.fileVersion = 0;
            this.fileDescription = "";
        }
        this.fileSize = 0;
        this.imageSize = null;
        this.imageUrl = "";
        this.uploadId = 0;
        this.haveImage = false;
        this.uploading = false;
        this.fileErrorMessage = "";
        this.fileNameErrorMessage = "";
    }
    validForm(show: ValidationLevel = ValidationLevel.Test): boolean {
        if (this.isView) return true;

        const errors: string[] = [];

        this.validFileName(errors, show);

        if (show === ValidationLevel.Show)
            ValidationMessage.display(errors);
        return errors.length === 0;
    }
    validFileName(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (!this.fileName) return "The Image Name field is required";
            return null;
        }, (message: string):void =>{ this.fileNameErrorMessage = message; });
    }

    show(imageId?: number): void {
        if (this.$refs.editImageImageTab) {
            const tab = new Tab(this.$refs.editImageImageTab);
            tab.show();// always activate the first tab in case this is a second invokation of this modal
        }

        this.initializeForm(); // in case this is a second invokation of this modal we need to clear all
        this.imageId = imageId || 0;
        if (!this.imageId) {
            this.isNew = true;
        } else {
            this.isEdit = !!this.imageId && UserRoles.has("AuggieVehicleEdit");
            if (!this.isEdit)
                this.isView = !!this.imageId && UserRoles.has("AuggieVehicleView");
        }
        this.showModal();
        this.loadTargetImage();
    }
    hide(forceReload?: boolean, setUrl?: boolean):void {
        if (this.hideModal(forceReload) && (setUrl === undefined || setUrl))
            this.$router.push(window.location.pathname);
    }
    saveClicked():void {
        if (!this.validForm(ValidationLevel.Show))
            return;

        this.saveImageInfo();
    }
    cancelClicked():void {
        this.hide();
    }

    loadTargetImage(): void {
        if (this.isNew) {
            this.initialized();
            return;
        }

        this.loading = true;

        const dr = new DataRequest();
        dr.$get<TargetImageDto>(`/Service/TargetImages/${this.imageId}`)
            .then((result: TargetImageDto): void => {
                this.loading = false;
                this.initialized();
                if (result) {
                    this.fileName = result.ImageName;
                    this.fileVersion = Number(result.Version);
                    this.fileDescription = result.Description || "";
                    this.uploadId = result.UploadId;
                    this.imageSize = { Width: result.ImageWidth, Height: result.ImageHeight};
                    this.imageUrl = Utility.formatUrl(`/Service/Uploads/GetContentById/${result.UploadId}`, null, Globals.GetWebApi(), true);
                    this.haveImage = true;
                    this.createdDt = Utility.getFormattedDateTime(new Date(result.CreatedDt));
                    this.createdBy = result.CreatedByUserName;
                    if (result.UpdatedDt) {
                        this.updatedDt = Utility.getFormattedDateTime(new Date(result.UpdatedDt));
                        this.updatedBy = result.UpdatedByUserName || "";
                    }
                } else {
                    Toast.error(`Unable to retrieve Target Image ID ${this.imageId}`);
                }
            })
            .catch((reason): void => {
                this.loading = false;
                this.initialized();
            });

        this.$refs.vehicleGrid.reload();
    }
    saveTargetImage(): void {
        this.loading = true;

        const targetImage: Partial<TargetImageDto> = {
            TargetImageId: this.imageId,
            UploadId: this.uploadId,
            ImageName: this.fileName,
            Description: this.fileDescription,
        };

        const dr = new DataRequest();
        dr.$post<Partial<TargetImageDto>, TargetImageDto>("/Service/TargetImages", null, targetImage)
            .then((result: TargetImageDto): void => {
                this.loading = false;
                if (result.UpdateResult.Success) {
                    this.hide(true);
                    if (result.UpdateResult.Message)
                        Toast.success(result.UpdateResult.Message);
                } else {
                    confirmOk(result.UpdateResult.Message || "Target Image save failed!");
                }
            })
            .catch((reason): void =>{
                this.loading = false;
            });
    }

    saveImageInfo(): void {
        if (this.isNew) {
            this.uploadFile();
        } else if (this.isEdit) {
            const file = this.getUploadFile();
            if (file) // we have a replacement file
                this.uploadFile();
            else
                this.saveTargetImage();
        }
    }

    uploadFile(): void {

        const dr = new DataRequest();

        const file = this.getUploadFile();
        if (!file) return;

        this.loading = true;
        this.uploading = true;
        this.uploadPercent = 0;

        dr.$postFile<UploadDto>("/Service/Uploads/UploadFile", { type: UploadType.TargetImage }, file, this.fileName, (ev: ProgressEvent): any|null =>{
            const total = ev.total;
            const position = ev.loaded;
            if (ev.lengthComputable)
                this.uploadPercent = Math.ceil(position / total * 100);
        })
            .then((result: UploadDto): void => {
                this.loading = false;
                this.uploading = false;
                if (result.UpdateResult.Success) {
                    this.uploadId = result.UploadId!;
                    this.saveTargetImage();
                } else {
                    confirmOk(result.UpdateResult.Message || "Image upload failed!");
                }
            })
            .catch((reason): void =>{
                this.loading = false;
                this.uploading = false;
            });
    }
    getUploadFile(): File | null {
        return this.$refs.uploadfile && this.$refs.uploadfile.files && this.$refs.uploadfile.files[0];
    }
    get uploadPercent(): number {
        return this.uploadPercentStore;
    }
    set uploadPercent(val: number) {
        this.uploadPercentStore = val;
    }
    private uploadPercentStore: number = 0;
    get progressBarStyle(): any {
        return {
            width: `${this.uploadPercent}%`
        };
    }

    getFileSizeFormatted(): string {
        return Utility.formatSizeInKB(this.fileSize);
    }
    getDimensionsFormatted(): string {
        if (!this.imageSize) return "";
        return `${this.imageSize.Width} x ${this.imageSize.Height} (width x height in pixels)`;
    }
    getImageUrl(): string {
        if (this.isNew) {
            const file =  this.getUploadFile();
            if (!file)
                return "";
            const blob = URL.createObjectURL(file);
            return blob;
        } else {
            return this.imageUrl;
        }
    }

    setFile(e: Event): boolean {

        this.clearUploadFile();

        const file = this.getUploadFile();
        if (!file)
            return false;

        if (file.size > 104857600) {
            this.fileErrorMessage = `Image ${file.name} exceeds the limit of 100 MB.`;
            Toast.error(this.fileErrorMessage);
            return false;
        }
        this.loading = true;

        const name = file.name;
        const lastDot = name.lastIndexOf(".");
        if (this.isNew)
            this.fileName = lastDot > 0 ? name.substring(0, lastDot) : name;
        this.fileSize = file.size;

        Utility.getImageDimensions(file)
            .then((size: ImageSize): void => {
                this.imageSize = size;
                this.haveImage = true;
                this.loading = false;
            }).catch((): void => {
                this.imageSize = null;
                this.loading = false;
                this.fileErrorMessage = `Image ${file!.name} is not a valid image file.`;
                Toast.error(this.fileErrorMessage);
            });

        return false;
    }

    dataResult: DataResult<VehicleDto> = GridPanel.EmptyDataResult;
    columns: GridColumnProps[] = [ // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridColumnProps/
        { field: "Actions", title: " ", sortable: false, width: "90px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActions }, // https://v3.vuejs.org/guide/migration/render-function-api.html
        { field: "VehicleId", hidden: true },
        { field: "VehicleMake", title: "Make", width: "140px", },
        { field: "VehicleModel", title: "Model", width: "200px", },
        { field: "VehicleYear", title: "Year", width: "70px", },
        { field: "CalibrationsCount", title: "Calibration Count", width: "150px", headerClassName: "gRight", className: "gRight", sortable: false, },
        { field: "ActiveInd", title: "Active", width: "100px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActive },
    ];
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActions(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            ImageVehicleButtonCell, { // the component used to render the cell
                vehicleId: props.dataItem.VehicleId,
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActive(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            BoolDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.ActiveInd,  // props to pass to the component
            });
    }
    loadGrid(dataState: State, search: string): void {

        if (!this.imageId) {
            this.dataResult = GridPanel.EmptyDataResult;
            return;
        }

        this.loading = true; // turn on page's loading indicator

        // load grid data
        const request = new DataRequest();
        request.$getGridData<VehicleDto[]>("/Service/Vehicle", dataState, search, {
            TargetImageId: this.imageId,
            StatusFilter: GenericActiveStatus.All,
        })
            .then((result): void => {
                this.loading = false;  // turn off page's loading indicator
                this.dataResult = { // update grid's model
                    data: result.Rows,
                    total: result.Total,
                };
            })
            .catch((reason): void => {
                this.loading = false; // turn off page's loading indicator
                this.dataResult = GridPanel.EmptyDataResult;
            });
    }

    $refs!: {
        vehicleGrid: GridPanel;
        uploadfile: HTMLInputElement;
        editImageImageTab: HTMLButtonElement;
        editImageVehicleTab: HTMLButtonElement;
    }
}


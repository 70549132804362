

import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";
import { CalibrationType } from "@/shared/enums/CalibrationType";
import ValidationMessage, { ValidationLevel } from "@/shared/components/common/ValidationMessage.vue";
import Numeric from "@/shared/components/common/Numeric.vue";
import TargetImages from "./TargetImages.vue";

@Options<VehicleCalibration>({
    components: {
        TargetImages,
    },
    watch: {
        modelValue(val): void {
            this.calibrationInit.CalibrationType = this.modelType;
            this.vehicleCalibration = val || Object.assign({}, this.calibrationInit);
            this.clearForm(this.vehicleCalibration);
        },
        modelType(val): void {
            this.calibrationInit.CalibrationType = val;
            this.vehicleCalibration.CalibrationType = val;
        },
    }
})
export default class VehicleCalibration extends Vue {

    calibrationInit: VehicleCalibrationDto = {
        VehicleCalibrationId: 0,
        VehicleId: 0,
        CalibrationType: CalibrationType.Sequential,
        TargetHeight: 0,
        TargetToCamera: 0,
        TargetDistanceFull: 0,
        TargetDistanceScaled: 0,
        ActiveInd: false,
        CreatedByUserName: null!,
        CreatedDt: null!,
        UpdatedByUserName: null,
        UpdatedDt: null,

        VehicleCalibrationTargetImages: [],

        UpdateResult: null!,
    };

    @Prop({ required: true }) readonly modelValue: VehicleCalibrationDto = Object.assign({}, this.calibrationInit);
    @Prop({ required: true }) readonly modelType: CalibrationType = CalibrationType.All;
    @Prop({ required: false }) readonly readonly: boolean = false;

    vehicleCalibration: VehicleCalibrationDto = Object.assign({}, this.calibrationInit);

    active: boolean = false;
    targetHeight: string | number = "";
    targetToCamera: string | number = "";
    fullTargetDistance: string | number = "";
    scaledTargetDistance: string | number = "";

    targetHeightErrorMessage = "";
    targetToCameraErrorMessage = "";
    fullTargetDistanceErrorMessage = "";
    scaledTargetDistanceErrorMessage = "";
    imagesErrorMessage = "";

    loading = false;

    haveImages(): boolean {
        return this.$refs.refTargetImages?.images.length > 0;
    }

    initializeForm():void {
        this.clearForm(this.calibrationInit);
        this.$refs.refTargetImages.initializeForm();
    }

    clearForm(cal: VehicleCalibrationDto): void {
        this.active = cal.ActiveInd;
        this.targetHeight = cal.TargetHeight;
        this.targetHeightErrorMessage = "";
        this.targetToCamera = cal.TargetToCamera;
        this.targetToCameraErrorMessage = "";
        this.fullTargetDistance = cal.TargetDistanceFull;
        this.fullTargetDistanceErrorMessage = "";
        this.scaledTargetDistance = cal.TargetDistanceScaled;
        this.scaledTargetDistanceErrorMessage = "";
        this.imagesErrorMessage = "";
    }

    validForm(show: ValidationLevel = ValidationLevel.Test): boolean {
        if (this.readonly) return true;
        const errors: string[] = [];

        this.validTargetHeight(errors, show);
        this.validTargetToCamera(errors, show);
        this.validFullTargetDistance(errors, show);
        this.validScaledTargetDistance(errors, show);
        this.validImages(errors, show);

        if (show === ValidationLevel.Show)
            ValidationMessage.display(errors);

        if (errors.length === 0) {
            const cal = Object.assign({}, this.modelValue);
            cal.TargetHeight = this.targetHeight !== "" ? Number(this.targetHeight) : 0;
            cal.TargetToCamera = this.targetToCamera !== "" ? Number(this.targetToCamera) : 0;
            cal.TargetDistanceFull = this.fullTargetDistance !== "" ? Number(this.fullTargetDistance) : 0;
            cal.TargetDistanceScaled = this.scaledTargetDistance !== "" ? Number(this.scaledTargetDistance) : 0;
            cal.ActiveInd = this.active;
            // cal.VehicleCalibrationTargetImages already set via TargetImages model
            this.$emit("update:modelValue", cal);
        }
        return errors.length === 0;
    }

    validTargetHeight(errors?: string[], show?: ValidationLevel): void {
        if (this.readonly) return;
        this.clearCalibrationError();
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active || this.targetHeight !== "") {
                if (!this.$refs.refTargetHeight.isValid()) return "The Target Height field is not valid";
                if (this.targetHeight === "") return "The Target Height field is required";
            }
            return null;
        }, (message: string):void =>{ this.targetHeightErrorMessage = message; });
    }
    validTargetToCamera(errors?: string[], show?: ValidationLevel): void {
        if (this.readonly) return;
        this.clearCalibrationError();
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active || this.targetToCamera !== "") {
                if (!this.$refs.refTargetToCamera.isValid()) return "The Target to Camera field is not valid";
                if (this.targetToCamera === "") return "The Target to Camera field is required";
            }
            return null;
        }, (message: string):void =>{ this.targetToCameraErrorMessage = message; });
    }
    validFullTargetDistance(errors?: string[], show?: ValidationLevel): void {
        if (this.readonly) return;
        this.clearCalibrationError();
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active || this.fullTargetDistance !== "") {
                if (!this.$refs.refFullTargetDistance.isValid()) return "The Assumed Full Target Distance field is not valid";
                if (this.fullTargetDistance === "") return "The Assumed Full Target Distance field is required";
            }
            return null;
        }, (message: string):void =>{ this.fullTargetDistanceErrorMessage = message; });
    }
    validScaledTargetDistance(errors?: string[], show?: ValidationLevel): void {
        if (this.readonly) return;
        this.clearCalibrationError();
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active || this.scaledTargetDistance !== "") {
                if (!this.$refs.refScaledTargetDistance.isValid()) return "The Assumed Scaled Target Distance field is not valid";
                if (this.scaledTargetDistance === "") return "The Assumed Scaled Target Distance field is required";
            }
            return null;
        }, (message: string):void =>{ this.scaledTargetDistanceErrorMessage = message; });
    }
    validImages(errors?: string[], show?: ValidationLevel): void {
        if (this.readonly) return;
        this.clearCalibrationError();
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active) {
                if (this.$refs.refTargetImages.gridErrors > 0) return "Value(s) in Images grid are invalid.";
                if (!this.haveImages()) return "An image is required";
            }
            return null;
        }, (message: string):void =>{ this.imagesErrorMessage = message; });
    }
    clearCalibrationError(): void {
        this.$emit("reset-form-errors", this.modelType);
    }

    $refs!: {
        refTargetImages: TargetImages;
        refTargetHeight: Numeric;
        refTargetToCamera: Numeric;
        refFullTargetDistance: Numeric;
        refScaledTargetDistance: Numeric;
    }
}




import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";

import PackageInventory from "./PackageInventory.vue";
import { SignalR } from "@/support/SignalR";

@Options({
    components: {
        PackageInventory,
    },
})
export default class Packages extends PageBase {

    mounted(): void {
        SignalR.whenReady((): void => {
            this.pageLoading = false;
        });
    }

}




import { Vue, Options } from "vue-class-component";
import CalibrationPanel from "./CalibrationPanel.vue";
import CalibrationEventEntry from "./CalibrationEventEntry.vue";
import { CalibrationSessionLogDto } from "@/shared/models/CalibrationSessionLogDto";
import { DataRequest } from "@/shared/support/Data";
import { confirmOk } from "@/shared/components/common/AlertDialog.vue";
import CalibrationEventDto from "@/shared/models/CalibrationEventDto";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";

@Options({
    components: {
        CalibrationPanel,
        CalibrationEventEntry,
    },
    watch: {
    }
})
export default class CalibrationDetails extends Vue {

    calibrationSessionGuid: string|null = null;
    calibrationSession: CalibrationSessionLogDto|null = null;
    showListenerStatus = false;

    calibrationEvents(): CalibrationEventDto[] {
        if (!this.calibrationSession) return [];
        return this.calibrationSession.CalibrationEvents;
    }
    isListenerStatus(evnt: CalibrationEventDto): boolean {
        return evnt.CalibrationEvent === CalibrationEvent.StatusForListeners;
    }
    loadSession(): void {
        const dr = new DataRequest();
        dr.$get<CalibrationSessionLogDto>(`/Service/CalibrationSessionLog/${this.calibrationSessionGuid}`)
            .then((calibrationSession: CalibrationSessionLogDto): void => {
                this.calibrationSession = calibrationSession;
                this.$forceUpdate();
            })
            .catch((reason): void => {
                confirmOk(`Calibration Session ${this.calibrationSessionGuid} not found.`);
            });
    }

    mounted(): void {
        const query = this.$router.currentRoute.value.query;
        this.calibrationSessionGuid = query.calibrationsessionguid as string|null;
        if (this.calibrationSessionGuid)
            this.loadSession();
        else
            confirmOk("No Calibration Session ID provided.");
    }
}


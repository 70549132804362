/* eslint-disable @typescript-eslint/explicit-function-return-type */

// https://router.vuejs.org/

import { createRouter, createWebHistory, RouteRecordRaw, Router, RouteLocationNormalized, NavigationFailure } from "vue-router";
import { CurrentUser, identity, UserRoles } from "@/identity";
import Home from "@/views/Home.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import AccountLogoff from "@/views/AccountLogoff.vue";
import { SignalR } from "./support/SignalR";

// for lazy loading see https://vuedose.tips/dynamic-imports-in-vue-js-for-better-performance

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/AccountLogoff",
        name: "AccountLogoff",
        component: AccountLogoff,
    },
    {
        path: "/Configuration",
        name: "Configuration",
        component: () => import("@/views/configuration/Configuration.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieVehicleView", "AuggieVehicleEdit"]
        }
    },
    {
        path: "/Devices",
        name: "Devices",
        component: () => import("@/views/devices/Devices.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieDeviceView", "AuggieDeviceEdit"]
        }
    },
    {
        path: "/Connect",
        name: "Connect",
        component: () => import("@/views/connection/Session.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieSessionView", "AuggieSessionEdit"]
        }
    },
    {
        path: "/session/Vehicle",
        name: "SessionVehicle",
        component: () => import("@/views/session/Vehicle.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieSessionView", "AuggieSessionEdit"]
        }
    },
    {
        path: "/session/RideHeight",
        name: "RideHeight",
        component: () => import("@/views/session/RideHeight.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieSessionView", "AuggieSessionEdit"]
        }
    },
    {
        path: "/session/CameraSetup",
        name: "CameraSetup",
        component: () => import("@/views/session/CameraSetup.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieSessionView", "AuggieSessionEdit"]
        }
    },
    {
        path: "/session/CameraReady",
        name: "SessionCameraReady",
        component: () => import("@/views/session/CameraReady.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieSessionView", "AuggieSessionEdit"]
        }
    },
    {
        path: "/session/CameraVerifying",
        name: "SessionCameraVerifying",
        component: () => import("@/views/session/CameraVerifying.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieSessionView", "AuggieSessionEdit"]
        }
    },
    {
        path: "/session/CameraVerification",
        name: "SessionCameraVerification",
        component: () => import("@/views/session/CameraVerification.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieSessionView", "AuggieSessionEdit"]
        }
    },
    {
        path: "/session/SendTargetImages",
        name: "SessionSendTargetImages",
        component: () => import("@/views/session/SendTargetImages.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieSessionView", "AuggieSessionEdit"]
        }
    },
    {
        path: "/session/WindshieldAngle",
        name: "SessionWindshieldAngle",
        component: () => import("@/views/session/WindshieldAngle.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieSessionView", "AuggieSessionEdit"]
        }
    },
    {
        path: "/session/Completed",
        name: "SessionCompleted",
        component: () => import("@/views/session/Completed.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieSessionView", "AuggieSessionEdit"]
        }
    },
    {
        path: "/Packages",
        name: "Packages",
        component: () => import("@/views/packages/Packages.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggiePackageView", "AuggiePackageEdit"]
        }
    },
    {
        path: "/Calibrations",
        name: "Calibrations",
        component: () => import("@/views/calibrations/Calibrations.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieCalibrationView"]
        }
    },
    {
        path: "/CalibrationDetails",
        name: "CalibrationDetails",
        component: () => import("@/views/calibrations/CalibrationDetails.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieCalibrationView"]
        }
    },
    {
        path: "/CameraImages",
        name: "CameraImages",
        component: () => import("@/views/cameraimages/Metrics.vue"),
        meta: {
            authName: "main",
            allowedRoles: ["AuggieCameraImageMetricsView"]
        }
    },
    {
        // path: "*",
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: PageNotFound,
    }
];

const router: Router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next): void => {

    const roles = to.meta.allowedRoles as string[] | null;
    if (roles && roles.length > 0) {

        // limited to certain roles
        if (UserRoles.hasAny(roles)) {
            next();
        } else {
            if (CurrentUser.isAuthenticated) {
                next("/PageNotFound");
            } else {
                next();
            }
        }
    } else {
        // No auth required. We can navigate
        next();
    }
});

router.afterEach( (to: RouteLocationNormalized, from: RouteLocationNormalized, failure?: NavigationFailure | void): any => {
    if (failure) return;
    if (!SignalR.isConnected) return;
    SignalR.connection.send("PageNavigation", to.path);
});

identity.useRouter(router);

export default router;




import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
    watch: {
        modelValue(val: string) {
            this.numericValue = val;
        }
    }
})
export default class Numeric extends Vue {

    @Prop({ default: "", required: true }) readonly modelValue: string|number = "";
    @Prop({ default: 100 }) readonly maxlength: number = 100;

    numericValue: string|null = null;

    isValid(): boolean {
        return this.$refs.inputNumber.validity.valid;
    }

    processInput(): void {
        this.$emit("update:modelValue", this.numericValue);
    }

    mounted(): void {
        this.numericValue = this.modelValue?.toString();
    }

    $refs! :{
        inputNumber: HTMLInputElement
    }
}



import { identity } from "@/identity";

import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";

@Options({
})
export default class AccountLogoff extends PageBase {

    mounted(): void {
        identity.signOut();
    }
}


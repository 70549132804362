

import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Utility from "@/shared/support/Utility";
import TooltipSupport from "@/shared/support/Tooltip";
import Globals from "@/support/Globals";

export default class MetricsButtonCell extends Vue {

    @Prop({ required: true }) readonly zipFileId: number = 0;

    get downloadUrl(): string {
        return Utility.formatUrl(`/Service/Uploads/GetContentById/${this.zipFileId}`, null, Globals.GetWebApi(), true);
    }
    clearTooltips(): void {
        TooltipSupport.clearTooltips();
    }
}


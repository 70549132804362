import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "btn-group",
  role: "group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("td", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("button", {
        type: "button",
        class: "btn btn-sm btn-danger",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeClicked && _ctx.removeClicked(...args)))
      }, [
        _createVNode(_component_fa_icon, {
          icon: "times-circle",
          class: "fa-w-20"
        })
      ]),
      (_ctx.index > 0)
        ? (_openBlock(), _createBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-sm btn-info",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.upClicked && _ctx.upClicked(...args)))
          }, [
            _createVNode(_component_fa_icon, {
              icon: "chevron-up",
              class: "fa-w-20"
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.index < _ctx.total - 1)
        ? (_openBlock(), _createBlock("button", {
            key: 1,
            type: "button",
            class: "btn btn-sm btn-info",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.downClicked && _ctx.downClicked(...args)))
          }, [
            _createVNode(_component_fa_icon, {
              icon: "chevron-down",
              class: "fa-w-20"
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
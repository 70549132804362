

import { CalibrationType, CalibrationTypeDescription } from "@/shared/enums/CalibrationType";
import { SessionTypeDescription } from "@/shared/enums/SessionType";
import { CalibrationSessionLogDto } from "@/shared/models/CalibrationSessionLogDto";
import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { DataRequest } from "@/shared/support/Data";
import Utility from "@/shared/support/Utility";
import Globals from "@/support/Globals";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
    watch: {
        modelValue(val: CalibrationSessionLogDto | null): void {
            this.session = val;
            this.loadVehicleInfo();
        }
    }
})
export default class VehiclePanel extends Vue {

    @Prop({ default: "", required: true }) readonly modelValue: CalibrationSessionLogDto | null = null;

    session: CalibrationSessionLogDto | null = null;
    loading = false;
    vehicle: VehicleDto|null = null;
    vehicleCalibration: VehicleCalibrationDto|null = null;

    deviceName(): string { return this.session?.DeviceName || ""; }
    deviceUrl(): string {
        if (!this.session) return "";
        return `/devices?deviceguid=${this.session.DeviceGuid}`;
    }
    get downloadUrl(): string {
        if (!this.session) return "#";
        return Utility.formatUrl(`/Service/CalibrationSessionLog/GetSessionLogData/${this.session.CalibrationSessionGuid}`, { Environment: Globals.GetEnvironment() }, Globals.GetWebApi(), true);
    }
    sessionStartEndDt(): string {
        let date = "";
        if (!this.session) return date;
        if (this.session.SessionStartDt)
            date += Utility.getFormattedDateTime(new Date(this.session.SessionStartDt));
        else
            date += "?";
        date += " - ";
        if (this.session.SessionEndDt)
            date += Utility.getFormattedDateTime(new Date(this.session.SessionEndDt));
        else
            date += "?";
        return date;
    }
    calibrationStatus(): string {
        if (!this.session) return "";
        return this.session.CalibrationFinalStatus?.replace("Calibration", "") || "";
    }
    sessionType(): string {
        if (!this.session) return "";
        return SessionTypeDescription[this.session.SessionType];
    }
    calibrationType(): string {
        if (!this.session) return "";
        if (this.session.SessionType === CalibrationType.All) return "";
        return CalibrationTypeDescription[this.session.CalibrationType];
    }
    calibrationSessionGuid(): string { return this.session?.CalibrationSessionGuid || ""; }
    notes(): string { return this.session?.Notes || ""; }
    toolYpr(): string {
        if (!this.session) return "";
        if (!this.session.ToolYaw && !this.session.ToolPitch && !this.session.ToolYaw) return "(n/a)";
        return `Yaw=${this.session.ToolYaw ? this.session.ToolYaw.toFixed(2) : "n/a"}, Pitch=${this.session.ToolPitch ? this.session.ToolPitch.toFixed(2) : "n/a"}, Roll=${this.session.ToolRoll ? this.session.ToolRoll.toFixed(2) : "n/a"}`;
    }
    vehicleFullName(): string {
        if (!this.session) return "";
        const val = this.session.VehicleFullName || "";
        return `${val} (${this.session.VehicleId})`;
    }
    vehicleUrl(): string {
        if (!this.session) return "";
        return `/Configuration?vehicleid=${this.session.VehicleId}`;
    }
    vin(): string { return this.session?.Vin || "(n/a)"; }
    technicianName(): string { return this.session?.TechnicianName || "(none)"; }
    windshieldOverride(): string { return this.session?.WindshieldOverride?.toFixed(2) || "(none)"; }
    packageVersion(): string { return this.session?.PackageVersion || "(n/a)"; }
    siteVersion(): string { return this.session?.SiteVersion || "(n/a)"; }

    cameraImageUpload(): string {
        if (!this.session) return "";
        if (!this.session.CameraImageUploadId) return "";
        return this.downloadableImage(this.session.CameraImageUploadId);
    }
    cameraAlignmentImageUpload(): string {
        if (!this.session) return "";
        if (!this.session.CameraAlignmentImageUploadId) return "";
        return this.downloadableImage(this.session.CameraAlignmentImageUploadId);
    }
    getUrl(id: number): string {
        return Utility.formatUrl(`/Service/Uploads/GetContentById/${id}`, null, Globals.GetWebApi(), true);
    }
    downloadableImage(id: number): string {
        const url = this.getUrl(id);
        return `<a href="${url}" target="_blank"><img class="img-fluid img-border" src="${url}"></a>`;
    }
    loadVehicleInfo(): void {
        if (!this.session) return;
        if (!this.session.CalibrationType) return;

        this.loading = true;

        const dr = new DataRequest();
        dr.$get<VehicleDto>(`/Service/Vehicle/${this.session.VehicleId}`)
            .then((vehicle: VehicleDto): void => {
                this.vehicle = vehicle;
                const cals = vehicle.VehicleCalibrations.filter((v: VehicleCalibrationDto, i:number): boolean => { return v.CalibrationType === this.session?.CalibrationType; });
                if (cals?.length === 1)
                    this.vehicleCalibration = cals[0];
            })
            .finally((): void =>{
                this.loading = false;
            });
    }
    vehicleCalibrationDataJson(): string {
        if (!this.vehicle) return "";
        let obj: any = Object.assign({}, this.vehicle);
        delete obj.VehicleCalibrations;// clear excess
        obj = this.removeExcess(obj);
        return JSON.stringify(obj, null, 4).trimEnd();
    }
    vehicleCalibrationForTypeDataJson(): string {
        if (!this.vehicleCalibration) return "";
        let obj: any = Object.assign({}, this.vehicleCalibration);
        delete obj.VehicleCalibrationTargetImages;// clear excess
        obj = this.removeExcess(obj);
        return JSON.stringify(obj, null, 4).trimEnd();
    }
    // eslint-disable-next-line
    removeExcess(obj: any): any {
        delete obj.UpdatedDt;
        delete obj.UpdatedByUserName;
        delete obj.UpdatedByUserGuid;
        delete obj.CreatedDt;
        delete obj.CreatedByUserName;
        delete obj.CreatedByUserGuid;
        delete obj.ActiveInd;
        return obj;
    }

    mounted(): void {
        this.loadVehicleInfo();
    }
}


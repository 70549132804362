

import { Options } from "vue-class-component";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { SignalR } from "@/support/SignalR";
import SessionBase from "./SessionBase";
import DeviceStatusModal from "./DeviceStatusModal.vue";
import App from "@/App.vue";
import StatusMessage from "./StatusMessage.vue";
import SessionSidePanel from "./SessionSidePanel.vue";

@Options({
    components: {
        DeviceStatusModal,
        SessionSidePanel,
        StatusMessage,
    },
    watch: {
        $route(to, from): void { // going from this page to some page. If it's the same, check query parameters
            if (from.path.toLowerCase() === to.path.toLowerCase()) { // going to the same page, re-initialize
                this.initialize();
            }
        }
    }
})
export default class CameraVerification extends SessionBase {


    resetData(): void {
        // retained for consistency
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    initialize(): void {
        CalibrationEventSupport.CancelRetryableEvents();
        this.loading = true;
        SignalR.whenReady((): void => {
            this.pageLoading = false;
            this.$refs.refDeviceStatusModal.verifySessionFromContext(false, ():void => {
                this.loading = false;
                this.resetData();
                this.$nextTick((): void => {
                    this.$refs.refSessionSidePanel?.Update();
                });
            });
        });
    }
    mounted(): void {
        this.initialize();
    }
    unmounted(): void {
        CalibrationEventSupport.CancelRetryableEvents();
    }

    $refs!: {
        refSessionSidePanel: SessionSidePanel,
        refDeviceStatusModal: DeviceStatusModal,
    }
}


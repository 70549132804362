

import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import Events from "@/shared/support/Events";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
    emits: [ "target-image-grid-up", "target-image-grid-down", "target-image-grid-remove" ]
})
export default class TargetImageButtonCell extends Vue {

    @Prop({ required: true }) readonly index: number = 0;
    @Prop({ required: true }) readonly total: number = 0;
    @Prop({ required: true }) readonly imageName: string = "";

    upClicked(): void {
        Events.bubbleEvent(this, "target-image-grid-up", this.index);
    }
    downClicked(): void {
        Events.bubbleEvent(this, "target-image-grid-down", this.index);
    }
    removeClicked(): void {
        confirmYesNo(`Are you sure you want to remove image "${this.imageName}" from this vehicle?`)
            .then((clicked: ButtonClicked) => {
                if (clicked === ButtonClicked.Ok)
                    Events.bubbleEvent(this, "target-image-grid-remove", this.index);
            });
    }
}


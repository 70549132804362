

import { Options } from "vue-class-component";
import ModalBase from "@/shared/components/common/ModalBase";
import ValidationMessage, { ValidationLevel } from "@/shared/components/common/ValidationMessage.vue";
import { DataRequest } from "@/shared/support/Data";

import Numeric from "@/shared/components/common/Numeric.vue";

import { CalibrationType } from "@/shared/enums/CalibrationType";
import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";

import VehicleCalibration from "./VehicleCalibration.vue";
import UploadPanel from "@/shared/components/common/UploadPanel.vue";
import { confirmOk } from "@/shared/components/common/AlertDialog.vue";
import Toast from "@/shared/support/Toast";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { UploadType } from "@/shared/enums/UploadType";
import { VehicleCalibrationTargetImageDto } from "@/shared/models/VehicleCalibrationTargetImageDto";
import { VehicleFilterType } from "@/shared/enums/VehicleFilterType";
import { Tab } from "bootstrap";
import Utility from "@/shared/support/Utility";
import SelectionSupport, { SelectionEntry } from "@/shared/support/Selection";
import { RegionType, RegionTypeDescription } from "@/shared/enums/RegionType";
import { UserRoles } from "@/identity";
import { MarkerType, MarkerTypeDescription } from "@/shared/enums/MarkerType";

@Options({
    components: {
        UploadPanel,
        VehicleCalibration,
    }
})
export default class EditVehicleModal extends ModalBase {

    vehicleId: number = 0;
    year: number|null = null;
    make: string = "";
    model: string = "";
    selectedRegion: RegionType = RegionType.Domestic;
    regionSelections: SelectionEntry[] = SelectionSupport.EnumSelections(RegionType, RegionTypeDescription);
    legLengthA: number|string = "";
    legLengthB: number|string = "";
    legLengthC: number|string = "";
    cameraHeight: number|string = "";
    cameraMountingOffset: string | number = "";
    windshieldAngle: number|string = "";
    selectedMarker: MarkerType = MarkerType.Standard;
    markerSelections: SelectionEntry[] = SelectionSupport.EnumSelections(MarkerType, MarkerTypeDescription);
    targetBrightness: number|string = "100";
    wheelArchHeightMin: number|string = "";
    wheelArchHeightMax: number|string = "";
    measuredWheelArchHeightFL: number|string = "";
    measuredWheelArchHeightFR: number|string = "";
    measuredWheelArchHeightRL: number|string = "";
    measuredWheelArchHeightRR: number|string = "";
    notes: string = "";
    active: boolean = false;
    createdDt = "";
    createdBy = "";
    updatedDt = "";
    updatedBy = "";

    onetimeCalibration: VehicleCalibrationDto = null!;
    sequentialCalibration: VehicleCalibrationDto = null!;

    getCalibrationInit(type: CalibrationType): VehicleCalibrationDto {
        return {
            VehicleCalibrationId: 0,
            VehicleId: 0,
            CalibrationType: type,
            TargetHeight: 0,
            TargetToCamera: 0,
            TargetDistanceFull: 0,
            TargetDistanceScaled: 0,
            ActiveInd: false,
            CreatedByUserName: null!,
            CreatedDt: null!,
            UpdatedByUserName: null,
            UpdatedDt: null,

            VehicleCalibrationTargetImages: [],

            UpdateResult: null!,
        };
    }

    modalTitle(): string {
        if (this.isClone)
            return "Add Cloned Vehicle";
        else if (this.isView)
            return "Display Vehicle";
        else if (this.isNew)
            return "Add Vehicle";
        else
            return "Edit Vehicle";
    }

    isEdit = false;
    isNew = false;
    isView = false;
    isClone = false;

    getUploadType(): number {
        return UploadType.VehicleDocument;
    }
    getOnetimeType(): number {
        return CalibrationType.OneTime;
    }
    getSequentialType(): number {
        return CalibrationType.Sequential;
    }

    isOnetimeCalibrationActive(): boolean {
        return this.$refs.refOnetimeCalibration &&
            this.$refs.refOnetimeCalibration.active &&
            this.$refs.refOnetimeCalibration.haveImages();
    }
    haveSequentialCalibration(): boolean {
        return this.$refs.refSequentialCalibration &&
            this.$refs.refSequentialCalibration.active &&
            this.$refs.refSequentialCalibration.haveImages();
    }

    getMakeSearchUrl(): string {
        return `/Service/Vehicle/DisplayList?VehicleFilterType=${VehicleFilterType.VehicleMake}`;
    }

    yearErrorMessage = "";
    makeErrorMessage = "";
    modelErrorMessage = "";
    legLengthAErrorMessage = "";
    legLengthBErrorMessage = "";
    legLengthCErrorMessage = "";
    cameraHeightErrorMessage = "";
    windshieldAngleErrorMessage = "";
    cameraMountingOffsetErrorMessage = "";
    targetBrightnessErrorMessage = "";
    wheelArchHeightMinErrorMessage = "";
    wheelArchHeightMaxErrorMessage = "";
    wheelArchHeightMinMaxErrorMessage = "";
    measuredWheelArchHeightFLErrorMessage = "";
    measuredWheelArchHeightFRErrorMessage = "";
    measuredWheelArchHeightRLErrorMessage = "";
    measuredWheelArchHeightRRErrorMessage = "";
    measuredWheelArchHeightAllErrorMessage = "";
    activeErrorMessage = "";
    onetimeCalibrationErrorMessage = "";
    sequentialCalibrationErrorMessage = "";

    initializeForm():void {
        this.vehicleId = 0;
        this.clearForm();
        this.$refs.refOnetimeCalibration.initializeForm();
        this.$refs.refSequentialCalibration.initializeForm();
    }
    clearForm(): void {
        this.isNew = this.isEdit = this.isView = this.isClone = false;
        this.year = null;
        this.year = new Date().getFullYear()+1;
        this.yearErrorMessage = "";
        this.make = "";
        this.makeErrorMessage = "";
        this.model = "";
        this.modelErrorMessage = "";
        this.selectedRegion = RegionType.Domestic;
        this.legLengthA = "";
        this.legLengthAErrorMessage = "";
        this.legLengthB = "";
        this.legLengthBErrorMessage = "";
        this.legLengthC = "";
        this.legLengthCErrorMessage = "";
        this.cameraHeight = "";
        this.cameraHeightErrorMessage = "";
        this.cameraMountingOffset = "";
        this.cameraMountingOffsetErrorMessage = "";
        this.windshieldAngle = "";
        this.windshieldAngleErrorMessage = "";
        this.targetBrightness = "100";
        this.targetBrightnessErrorMessage = "";
        this.wheelArchHeightMin = "";
        this.wheelArchHeightMinErrorMessage = "";
        this.wheelArchHeightMax = "";
        this.wheelArchHeightMaxErrorMessage = "";
        this.wheelArchHeightMinMaxErrorMessage = "";
        this.measuredWheelArchHeightFL = "";
        this.measuredWheelArchHeightFLErrorMessage = "";
        this.measuredWheelArchHeightFR = "";
        this.measuredWheelArchHeightFRErrorMessage = "";
        this.measuredWheelArchHeightRL = "";
        this.measuredWheelArchHeightRLErrorMessage = "";
        this.measuredWheelArchHeightRR = "";
        this.measuredWheelArchHeightRRErrorMessage = "";
        this.measuredWheelArchHeightFL = "";
        this.measuredWheelArchHeightAllErrorMessage = "";
        this.active = false;
        this.activeErrorMessage = "";
        this.onetimeCalibrationErrorMessage = "";
        this.sequentialCalibrationErrorMessage = "";
        this.notes = "";
        this.createdDt = "";
        this.createdBy = "";
        this.updatedDt = "";
        this.updatedBy = "";
    }

    validForm(show: ValidationLevel = ValidationLevel.Test): boolean {

        if (this.isView) return true;

        const errors: string[] = [];

        this.validYear(errors, show);
        this.validMake(errors, show);
        this.validModel(errors, show);
        this.validLegLengthA(errors, show);
        this.validLegLengthB(errors, show);
        this.validLegLengthC(errors, show);
        this.validCameraHeight(errors, show);
        this.validCameraMountingOffset(errors, show);
        this.validWindshieldAngle(errors, show);
        this.validTargetBrightness(errors, show);
        this.validWheelArchHeightMin(errors, show);
        this.validWheelArchHeightMax(errors, show);
        this.validWheelArchHeightMinMax(errors, show);
        this.validMeasuredWheelArchHeightFL(errors, show);
        this.validMeasuredWheelArchHeightFR(errors, show);
        this.validMeasuredWheelArchHeightRL(errors, show);
        this.validMeasuredWheelArchHeightRR(errors, show);
        this.validMeasuredWheelArchHeightAll(errors, show);
        this.validActive(errors, show);
        this.validOnetimeCalibration(errors, show);
        this.validSequentialCalibration(errors, show);

        if (show === ValidationLevel.Show)
            ValidationMessage.display(errors);
        return errors.length === 0;
    }
    validYear(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.isNew || this.isEdit || this.isClone) {
                if (!this.year) return "Required";
                if (!this.$refs.refYear.isValid()) return "The Year field is not valid";
            }
            return null;
        }, (message: string):void => { this.yearErrorMessage = message; });
    }
    validMake(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.isNew || this.isEdit || this.isClone) {
                if (!this.make) return "The Make field is required";
            }
            return null;
        }, (message: string):void => { this.makeErrorMessage = message; });
    }
    validModel(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.isNew || this.isEdit || this.isClone) {
                if (!this.model) return "The Model field is required";
            }
            return null;
        }, (message: string):void => { this.modelErrorMessage = message; });
    }
    validLegLengthA(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active || this.legLengthA !== "") {
                if (!this.$refs.refLegLengthA.isValid()) return "The Leg Position A field is not valid";
                if (this.legLengthA === "") return "The Leg Position A field is required";
            }
            return null;
        }, (message: string):void => { this.legLengthAErrorMessage = message; });
    }
    validLegLengthB(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active || this.legLengthB !== "") {
                if (!this.$refs.refLegLengthB.isValid()) return "The Leg Position B field is not valid";
                if (this.legLengthB === "") return "The Leg Position B field is required";
            }
            return null;
        }, (message: string):void => { this.legLengthBErrorMessage = message; });
    }
    validLegLengthC(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active || this.legLengthC !== "") {
                if (!this.$refs.refLegLengthC.isValid()) return "The Leg Position C field is not valid";
                if (this.legLengthC === "") return "The Leg Position C field is required";
            }
            return null;
        }, (message: string):void => { this.legLengthCErrorMessage = message; });
    }
    validCameraHeight(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active || this.cameraHeight !== "") {
                if (!this.$refs.refCameraHeight.isValid()) return "The Camera Height field is not valid";
                if (this.cameraHeight === "") return "The Camera Height field is required";
            }
            return null;
        }, (message: string):void => { this.cameraHeightErrorMessage = message; });
    }
    validWindshieldAngle(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active || this.windshieldAngle !== "") {
                if (!this.$refs.refWindshieldAngle.isValid()) return "The Windshield Angle field is not valid";
                if (this.windshieldAngle === "") return "The Windshield Angle field is required";
                try {
                    const angle = Number(this.windshieldAngle);
                    if (angle < 16 || angle > 55) return "The Windshield Angle field is invalid";
                } catch (e) {
                    return "The Windshield Angle field is invalid";
                }
            }
            return null;
        }, (message: string):void => { this.windshieldAngleErrorMessage = message; });
    }
    validCameraMountingOffset(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active || this.cameraMountingOffset !== "") {
                if (!this.$refs.refCameraMountingOffset.isValid()) return "The Camera Mounting Offset field is not valid";
                if (this.cameraMountingOffset === "") return "The Camera Mounting Offset field is required";
            }
            return null;
        }, (message: string):void => { this.cameraMountingOffsetErrorMessage = message; });
    }
    validTargetBrightness(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active || this.targetBrightness !== "") {
                if (!this.$refs.refTargetBrightness.isValid()) return "The Target Brightness field is not valid";
                if (this.targetBrightness === "") return "The Target Brightness field is required";
            }
            return null;
        }, (message: string):void => { this.targetBrightnessErrorMessage = message; });
    }
    validWheelArchHeightMin(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.wheelArchHeightMin !== "" && !this.$refs.refWheelArchHeightMin.isValid())
                return "The Wheel Arch Height (Min) field is not valid";
            return null;
        }, (message: string):void => { this.wheelArchHeightMinErrorMessage = message; this.wheelArchHeightMinMaxErrorMessage = ""; });
    }
    validWheelArchHeightMax(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.wheelArchHeightMax !== "" && !this.$refs.refWheelArchHeightMax.isValid())
                return "The Wheel Arch Height (Max) field is not valid";
            if (this.wheelArchHeightMin !== "" && this.wheelArchHeightMax !== "" && Number(this.wheelArchHeightMax) <= Number(this.wheelArchHeightMin))
                return "The Wheel Arch Height (Max) must be greater than Min";
            return null;
        }, (message: string):void => { this.wheelArchHeightMaxErrorMessage = message; this.wheelArchHeightMinMaxErrorMessage = ""; });
    }
    validWheelArchHeightMinMax(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (!this.wheelArchHeightMinErrorMessage && !this.wheelArchHeightMaxErrorMessage) {
                if ((this.wheelArchHeightMin !== "" && this.wheelArchHeightMax === "") ||
                    (this.wheelArchHeightMin === "" && this.wheelArchHeightMax !== "")) {
                    return "The Wheel Arch Height (Min and Max) must both be provided";
                }
            }
            return null;
        }, (message: string):void => { this.wheelArchHeightMinMaxErrorMessage = message; });
    }
    validMeasuredWheelArchHeightFL(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.measuredWheelArchHeightFL !== "" && !this.$refs.refMeasuredWheelArchHeightFL.isValid())
                return "The Measured Wheel Arch Height Front Left field is not valid";
            return null;
        }, (message: string):void => { this.measuredWheelArchHeightFLErrorMessage = message; this.measuredWheelArchHeightAllErrorMessage = ""; });
    }
    validMeasuredWheelArchHeightFR(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.measuredWheelArchHeightFR !== "" && !this.$refs.refMeasuredWheelArchHeightFR.isValid())
                return "The Measured Wheel Arch Height Front Right field is not valid";
            return null;
        }, (message: string):void => { this.measuredWheelArchHeightFRErrorMessage = message; this.measuredWheelArchHeightAllErrorMessage = ""; });
    }
    validMeasuredWheelArchHeightRL(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.measuredWheelArchHeightRL !== "" && !this.$refs.refMeasuredWheelArchHeightRL.isValid())
                return "The Measured Wheel Arch Height Rear Left field is not valid";
            return null;
        }, (message: string):void => { this.measuredWheelArchHeightRLErrorMessage = message; this.measuredWheelArchHeightAllErrorMessage = ""; });
    }
    validMeasuredWheelArchHeightRR(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.measuredWheelArchHeightRR !== "" && !this.$refs.refMeasuredWheelArchHeightRR.isValid())
                return "The Measured Wheel Arch Height Rear Right field is not valid";
            return null;
        }, (message: string):void => { this.measuredWheelArchHeightRRErrorMessage = message; this.measuredWheelArchHeightAllErrorMessage = ""; });
    }
    validMeasuredWheelArchHeightAll(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (!this.measuredWheelArchHeightFLErrorMessage && !this.measuredWheelArchHeightFRErrorMessage &&
                !this.measuredWheelArchHeightRLErrorMessage && !this.measuredWheelArchHeightRRErrorMessage) {
                if ((this.measuredWheelArchHeightFL !== "" || this.measuredWheelArchHeightFR !== "" ||
                    this.measuredWheelArchHeightRL !== "" || this.measuredWheelArchHeightRR !== "") &&
                    (this.measuredWheelArchHeightFL === "" || this.measuredWheelArchHeightFR === "" ||
                    this.measuredWheelArchHeightRL === "" || this.measuredWheelArchHeightRR === "")) {
                    return "All Measured Wheel Arch Height values must be provided";
                }
            }
            return null;
        }, (message: string):void => { this.measuredWheelArchHeightAllErrorMessage = message; });
    }
    validActive(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (this.active && !this.$refs.refOnetimeCalibration.active && !this.$refs.refSequentialCalibration.active)
                return "The vehicle configuration can't be set Active unless One Time Calibration or Sequential Calibration information is provided";
            return null;
        }, (message: string):void => { this.activeErrorMessage = message; });
    }
    validOnetimeCalibration(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (!this.$refs.refOnetimeCalibration.validForm(ValidationLevel.Validate))
                return "The One Time Calibration information is invalid";
            return null;
        }, (message: string):void => { this.onetimeCalibrationErrorMessage = message; });
    }
    validSequentialCalibration(errors?: string[], show?: ValidationLevel): void {
        if (this.isView) return;
        ValidationMessage.validate(errors, show, ():string|null => {
            if (!this.$refs.refSequentialCalibration.validForm(ValidationLevel.Validate))
                return "The Sequential Calibration information is invalid";
            return null;
        }, (message: string):void => { this.sequentialCalibrationErrorMessage = message; });
    }
    clearOnetimeCalibrationError(): void {
        this.onetimeCalibrationErrorMessage = "";
    }
    clearSequentialCalibrationError(): void {
        this.sequentialCalibrationErrorMessage = "";
    }

    show(vehicleId?: number, clone?: string): void {
        this.initializeForm(); // in case this is a second invokation of this modal we need to clear all
        this.isClone = (!!vehicleId && !!clone) || false;
        this.vehicleId = vehicleId || 0;
        if (this.isClone) {
            // nothing to set
        } else if (!this.vehicleId) {
            this.isNew = true;
        } else {
            this.isEdit = !!this.vehicleId && UserRoles.has("AuggieVehicleEdit");
            if (!this.isEdit)
                this.isView = !!this.vehicleId && UserRoles.has("AuggieVehicleView");
        }
        this.showModal();
        this.loadVehicleInfo(vehicleId || 0);
    }
    hide(forceReload?: boolean, setUrl?: boolean):void {
        if (this.hideModal(forceReload) && (setUrl === undefined || setUrl))
            this.$router.push(window.location.pathname);
    }
    saveClicked():void {
        if (!this.validForm(ValidationLevel.Show))
            return;

        this.saveVehicleInfo();
    }
    cancelClicked():void {
        this.hide();
    }
    onActiveClicked(): void {
        this.validForm(ValidationLevel.Reset);
    }

    loadVehicleInfo(vehicleId: number): void {

        if (!vehicleId) {
            this.onetimeCalibration = Object.assign({}, this.getCalibrationInit(CalibrationType.OneTime)); // clone
            this.sequentialCalibration = Object.assign({}, this.getCalibrationInit(CalibrationType.Sequential)); // clone
            const tab = new Tab(this.$refs.refOnetimeTab);
            tab.show();
            this.initialized();
            return;
        }

        this.loading = true;

        const dr = new DataRequest();
        dr.$get<VehicleDto>(`/Service/Vehicle/${vehicleId}`)
            .then((vehicle): void => {
                this.loading = false;
                this.initialized();

                this.vehicleId = vehicle.VehicleId;
                this.make = vehicle.VehicleMake;
                this.model = vehicle.VehicleModel;
                this.year = vehicle.VehicleYear;
                this.selectedRegion = vehicle.Region;
                this.legLengthA = vehicle.LegLengthA;
                this.legLengthB = vehicle.LegLengthB;
                this.legLengthC = vehicle.LegLengthC;
                this.cameraHeight = vehicle.CameraHeight;
                this.windshieldAngle = vehicle.WindshieldAngle;
                this.cameraMountingOffset = vehicle.CameraMountingOffset;
                this.selectedMarker = vehicle.Marker;
                this.targetBrightness = vehicle.TargetBrightness;
                this.wheelArchHeightMin = vehicle.WheelArchHeightMin || "";
                this.wheelArchHeightMax = vehicle.WheelArchHeightMax || "";
                this.measuredWheelArchHeightFL = vehicle.MeasuredWheelArchHeightFrontLeft || "";
                this.measuredWheelArchHeightFR = vehicle.MeasuredWheelArchHeightFrontRight || "";
                this.measuredWheelArchHeightRL = vehicle.MeasuredWheelArchHeightRearLeft || "";
                this.measuredWheelArchHeightRR = vehicle.MeasuredWheelArchHeightRearRight || "";
                this.notes = vehicle.Notes;
                this.active = vehicle.ActiveInd;

                this.onetimeCalibration = Object.assign({}, this.getCalibration(vehicle, CalibrationType.OneTime)); // clone
                this.onetimeCalibration.CalibrationType = CalibrationType.OneTime;
                this.sequentialCalibration = Object.assign({}, this.getCalibration(vehicle, CalibrationType.Sequential)); // clone
                this.sequentialCalibration.CalibrationType = CalibrationType.Sequential;

                this.createdDt = Utility.getFormattedDateTime(new Date(vehicle.CreatedDt));
                this.createdBy = vehicle.CreatedByUserName;
                if (vehicle.UpdatedDt) {
                    this.updatedDt = Utility.getFormattedDateTime(new Date(vehicle.UpdatedDt));
                    this.updatedBy = vehicle.UpdatedByUserName || "";
                }

                let activeTab = this.$refs.refOnetimeTab;
                if (this.onetimeCalibration.ActiveInd) {
                    // default to first tab
                } else if (this.sequentialCalibration.ActiveInd) {
                    activeTab = this.$refs.refSequentialTab;
                }
                const tab = new Tab(activeTab);
                tab.show();
            })
            .catch((reason): void =>{
                this.loading = false;
                this.initialized();
            });
    }

    saveVehicleInfo(): void {

        this.loading = true;

        const vehicle: Partial<VehicleDto> = {
            VehicleId: this.vehicleId,
            VehicleYear: this.year!,
            VehicleMake: this.make,
            VehicleModel: this.model,
            Region: this.selectedRegion,
            LegLengthA: Number(this.legLengthA),
            LegLengthB: Number(this.legLengthB),
            LegLengthC: Number(this.legLengthC),
            CameraHeight: Number(this.cameraHeight),
            CameraMountingOffset: Number(this.cameraMountingOffset),
            WindshieldAngle: Number(this.windshieldAngle),
            Marker: this.selectedMarker,
            TargetBrightness: Number(this.targetBrightness),
            WheelArchHeightMin: this.wheelArchHeightMin !== "" ? Number(this.wheelArchHeightMin) : null,
            WheelArchHeightMax: this.wheelArchHeightMax !== "" ? Number(this.wheelArchHeightMax) : null,
            MeasuredWheelArchHeightFrontLeft: this.measuredWheelArchHeightFL !== "" ? Number(this.measuredWheelArchHeightFL) : null,
            MeasuredWheelArchHeightFrontRight: this.measuredWheelArchHeightFR !== "" ? Number(this.measuredWheelArchHeightFR) : null,
            MeasuredWheelArchHeightRearLeft: this.measuredWheelArchHeightRL !== "" ? Number(this.measuredWheelArchHeightRL) : null,
            MeasuredWheelArchHeightRearRight: this.measuredWheelArchHeightRR !== "" ? Number(this.measuredWheelArchHeightRR) : null,
            Notes: this.notes,
            ActiveInd: this.active,
            VehicleCalibrations: [],
            Clone: this.isClone,
        };

        let cal = this.getChangedCalibration(this.onetimeCalibration);
        if (cal)
            vehicle.VehicleCalibrations!.push(cal);
        cal = this.getChangedCalibration(this.sequentialCalibration);
        if (cal)
            vehicle.VehicleCalibrations!.push(cal);

        const dr = new DataRequest();
        dr.$post<Partial<VehicleDto>, VehicleDto>("/Service/Vehicle", null, vehicle)
            .then((result): void => {
                this.loading = false;
                if (result.UpdateResult.Success) {
                    Toast.success(result.UpdateResult.Message ||
                        (this.isNew || this.isClone ? "Vehicle information successfully saved" : "Vehicle information successfully updated"));
                    this.vehicleId = result.VehicleId;
                    this.$emit("modal-update");
                    this.hide();
                } else {
                    confirmOk(result.UpdateResult.Message!);
                }
            })
            .catch((reason): void =>{
                this.loading = false;
            });
    }

    getChangedCalibration(cal: VehicleCalibrationDto): VehicleCalibrationDto|null {
        const newCal = Object.assign({}, cal);
        const init = this.getCalibrationInit(cal.CalibrationType);
        if (newCal.VehicleCalibrationId !== init.VehicleCalibrationId ||
            newCal.VehicleId !== init.VehicleId ||
            newCal.TargetHeight !== init.TargetHeight ||
            newCal.TargetToCamera !== init.TargetToCamera ||
            newCal.TargetDistanceFull !== init.TargetDistanceFull ||
            newCal.TargetDistanceScaled !== init.TargetDistanceScaled ||
            newCal.ActiveInd !== init.ActiveInd ||
            newCal.VehicleCalibrationTargetImages.length !== 0) {

            const newImages: VehicleCalibrationTargetImageDto[] = [];
            for (const img of cal.VehicleCalibrationTargetImages) {
                const newImg = Object.assign({}, img);
                newImg.TargetImage = null!;// clear so we don't send (otherwise server can't match model)
                newImages.push(newImg);
            }
            newCal.VehicleCalibrationTargetImages = newImages;
            return newCal;
        }
        return null;
    }

    getCalibration(vehicle: VehicleDto, type: CalibrationType): VehicleCalibrationDto {
        if (vehicle.VehicleCalibrations) {
            if (vehicle.VehicleCalibrations.length > 1) {
                if (vehicle.VehicleCalibrations[1].CalibrationType === type)
                    return vehicle.VehicleCalibrations[1];

            }
            if (vehicle.VehicleCalibrations.length > 0) {
                if (vehicle.VehicleCalibrations[0].CalibrationType === type)
                    return vehicle.VehicleCalibrations[0];
            }
        }
        return this.getCalibrationInit(type);
    }

    $refs!: {
        refOnetimeCalibration: VehicleCalibration;
        refSequentialCalibration: VehicleCalibration;
        refYear: Numeric;
        refLegLengthA: Numeric;
        refLegLengthB: Numeric;
        refLegLengthC: Numeric;
        refCameraHeight: Numeric;
        refCameraMountingOffset: Numeric;
        refWindshieldAngle: Numeric;
        refTargetBrightness: Numeric;
        refWheelArchHeightMin: Numeric;
        refWheelArchHeightMax: Numeric;
        refMeasuredWheelArchHeightFL: Numeric;
        refMeasuredWheelArchHeightFR: Numeric;
        refMeasuredWheelArchHeightRL: Numeric;
        refMeasuredWheelArchHeightRR: Numeric;
        refOnetimeTab: HTMLElement;
        refSequentialTab: HTMLElement;
    }
}


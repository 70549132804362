

import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import Toast from "@/shared/support/Toast";
import { DataRequest } from "@/shared/support/Data";
import Events from "@/shared/support/Events";
import { UserRoles } from "@/identity";
import App from "@/App.vue";

export default class VehicleButtonCell extends Vue {

    @Prop({ required: true }) readonly vehicleId: number = 0;
    @Prop({ required: true }) readonly vehicleName: string = "";
    @Prop({ required: true }) readonly active: boolean = false;

    canEdit(): boolean {
        return UserRoles.has("AuggieVehicleEdit");
    }
    canView(): boolean {
        return !this.canEdit() && UserRoles.has("AuggieVehicleView");
    }
    canRemove(): boolean {
        return UserRoles.has("AuggieVehicleDelete");
    }

    showVehicleModal(): void {
        App.MainApp.routerPush("/Configuration", { vehicleid: this.vehicleId });
    }
    cloneVehicleModal(): void {
        App.MainApp.routerPush("/Configuration", { vehicleid: this.vehicleId, clone: true });
    }
    removeVehicle(): void {
        confirmYesNo(`Are you sure you want to deactivate Vehicle Configuration ${this.vehicleName}?`)
            .then((clicked: ButtonClicked) => {
                if (clicked === ButtonClicked.Ok) {
                    this.postRemoveVehicle();
                }
            });
    }
    restoreVehicle(): void {
        confirmYesNo(`Are you sure you want to restore Vehicle Configuration ${this.vehicleName}?`)
            .then((clicked: ButtonClicked) => {
                if (clicked === ButtonClicked.Ok) {
                    this.postRestoreVehicle();
                }
            });
    }
    private postRemoveVehicle(): void {
        const dr = new DataRequest();
        dr.$delete<boolean>(`/Service/Vehicle/${this.vehicleId}`)
            .then((result: boolean): void => {
                if (result) {
                    Toast.success(`Vehicle "${this.vehicleName}" has been successfully deactivated.`);
                    Events.bubbleEvent(this, "vehicle-grid-reload", this.vehicleId);
                } else {
                    confirmOk(`An error occurred deactivating Vehicle "${this.vehicleName}".`);
                }
            }).catch((reason): void => {
                confirmOk(`An error occurred deactivating Vehicle "${this.vehicleName}": ${reason}`);
            });
    }
    private postRestoreVehicle(): void {
        const dr = new DataRequest();
        dr.$delete<boolean>(`/Service/Vehicle/${this.vehicleId}`, { Restore: true })
            .then((result: boolean): void => {
                if (result) {
                    Toast.success(`Vehicle "${this.vehicleName}" has been successfully activated.`);
                    Events.bubbleEvent(this, "vehicle-grid-reload", this.vehicleId);
                } else {
                    confirmOk(`An error occurred activating Vehicle "${this.vehicleName}".`);
                }
            }).catch((reason): void => {
                confirmOk(`An error occurred activating Vehicle "${this.vehicleName}": ${reason}`);
            });
    }
}



import { Vue } from "vue-class-component";
import { CurrentUser } from "@/identity";
import { Global } from "@/support/GlobalData";
import { confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";

export default class Header extends Vue {

    tvClicked(): void {
        if (Global.CalibrationSession && Global.CalibrationSession.CalibrationSessionGuid)
            this.teamviewerEnableClicked(Global.CalibrationSession.CalibrationSessionGuid);
    }
    teamviewerEnableClicked(calibrationSessionGuid: string): void {
        confirmYesNo("Are you sure you want to enable Remote Assistance? This may negatively affect device performance, depending on available bandwidth.")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    CalibrationEventSupport.sendEventStartTv(calibrationSessionGuid, CalibrationEvent.StartTeamViewer, CurrentUser.userGuid!);
                }
            });
    }


}

import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createBlock("input", {
    type: "number",
    ref: "inputNumber",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.numericValue = $event)),
    onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.processInput && _ctx.processInput(...args)))
  }, null, 544)), [
    [_vModelText, _ctx.numericValue]
  ])
}


import Utility from "@/shared/support/Utility";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class TargetImageNameCell extends Vue {

    @Prop({ required: true }) readonly imageName: string = "";
    @Prop({ required: true }) readonly imageUrl: string = "";

    getTitle(): string {
        return `<div style='width:200px;height:200px'><img src='${Utility.encodeHTML(this.imageUrl)}' class='img-fluid'></div>`;
    }

}




import PageBase from "@/shared/components/common/PageBase";

import { VNode } from "vue";
import { GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { DataRequest, DataResult, GridResult } from "@/shared/support/Data";

import GridPanel from "@/shared/components/common/GridPanel.vue";
import DeviceButtonCell from "./DeviceButtonCell.vue";
import { CalibrationSessionDto, CalibrationSessionGridDto } from "@/shared/models/CalibrationSessionDto";
import Utility from "@/shared/support/Utility";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { CameraStatus } from "@/shared/enums/CameraStatus";
import { State } from "@progress/kendo-data-query";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import CalibrationEventDto from "@/shared/models/CalibrationEventDto";
import { CurrentUser } from "@/identity";
import { SignalR } from "@/support/SignalR";
import { CalibrationSessionProgress, CalibrationSessionProgressDescription } from "@/shared/enums/CalibrationSessionProgress";
import { SessionType, SessionTypeDescription } from "@/shared/enums/SessionType";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { Options } from "vue-class-component";
import DeviceStatusModal from "../session/DeviceStatusModal.vue";
import { Global } from "@/support/GlobalData";

@Options({
    components: {
        DeviceStatusModal
    }
})
export default class Session extends PageBase {

    connecting = false;

    dataResult:DataResult<CalibrationSessionDto> = GridPanel.EmptyDataResult;
    columns: GridColumnProps[] = [ // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridColumnProps/
        { field: "Actions", title: " ", sortable: false, width: "120px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActions },
        { field: "DeviceGuid", hidden: true },
        { field: "CalibrationSessionGuid", hidden: true },
        { field: "CompanionGuid", hidden: true },
        { field: "Progress", hidden: true },
        { field: "DeviceName", title: "Name", width: "200px", },
        { field: "Progress__Display", title: "Status", width: "200px", headerClassName: "gCenter", className: "gCenter", sortable: false },
        { field: "SessionType_Display", title: "Type", width: "140px", headerClassName: "gCenter", className: "gCenter", sortable: false },
        { field: "VehicleMake", title: "Make", width: "200px", },
        { field: "VehicleModel", title: "Model", width: "200px", },
        { field: "VehicleYear", title: "Year", width: "100px", },
        { field: "TechnicianName", title: "Technician", width: "300px", },
        { field: "SessionStartDt__Display", title: "Started", width: "200px", },
    ];

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActions(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {

        let available = false;
        let verified = false;
        let allowAssistedCalibrationInd = props.dataItem.AllowAssistedCalibrationInd;
        let allowAssistedTargetDisplayInd = props.dataItem.AllowAssistedTargetDisplayInd;

        const progress: CalibrationSessionProgress = props.dataItem.Progress;
        if (props.dataItem.SessionType === SessionType.SelfCalibration) {
            available = false;
        } else {
            switch (progress) {
                case CalibrationSessionProgress.VehicleSelection:
                    available = !props.dataItem.TechnicianUserGuid || CurrentUser.userGuid === props.dataItem.TechnicianUserGuid;
                    allowAssistedCalibrationInd = allowAssistedCalibrationInd && (props.dataItem.SessionType === SessionType.AssistedCalibration || props.dataItem.SessionType === SessionType.Unknown);
                    allowAssistedTargetDisplayInd = allowAssistedTargetDisplayInd && (props.dataItem.SessionType === SessionType.AssistedTargetDisplay || props.dataItem.SessionType === SessionType.Unknown);
                    break;
                case CalibrationSessionProgress.CameraAlignment:
                    available = !props.dataItem.TechnicianUserGuid || CurrentUser.userGuid === props.dataItem.TechnicianUserGuid;
                    allowAssistedCalibrationInd = allowAssistedCalibrationInd && props.dataItem.SessionType === SessionType.AssistedCalibration;
                    allowAssistedTargetDisplayInd = allowAssistedTargetDisplayInd && props.dataItem.SessionType === SessionType.AssistedTargetDisplay;
                    break;
                case CalibrationSessionProgress.CameraVerified:
                    available = props.dataItem.VehicleId && props.dataItem.CameraStatus === CameraStatus.Found && (!props.dataItem.TechnicianUserGuid || CurrentUser.userGuid === props.dataItem.TechnicianUserGuid);
                    verified = true;
                    allowAssistedCalibrationInd = allowAssistedCalibrationInd && props.dataItem.SessionType === SessionType.AssistedCalibration;
                    allowAssistedTargetDisplayInd = allowAssistedTargetDisplayInd && props.dataItem.SessionType === SessionType.AssistedTargetDisplay;
                    break;
                case CalibrationSessionProgress.InProgress:
                    // tech owns this session
                    if (props.dataItem.VehicleId) {
                        if (props.dataItem.CameraImageUploadId == null) {
                            // no camera image
                            available = !props.dataItem.TechnicianUserGuid || CurrentUser.userGuid === props.dataItem.TechnicianUserGuid;
                        } else if (props.dataItem.CameraImageUploadId != null && props.dataItem.CameraStatus !== CameraStatus.Found) {
                            // unverified camera image
                            available = !props.dataItem.TechnicianUserGuid || CurrentUser.userGuid === props.dataItem.TechnicianUserGuid;
                        } else {
                            available = props.dataItem.VehicleId && props.dataItem.CameraStatus === CameraStatus.Found && (!props.dataItem.TechnicianUserGuid || CurrentUser.userGuid === props.dataItem.TechnicianUserGuid);
                        }
                    } else {
                        available = !props.dataItem.TechnicianUserGuid || CurrentUser.userGuid === props.dataItem.TechnicianUserGuid;
                    }
                    allowAssistedCalibrationInd = allowAssistedCalibrationInd && props.dataItem.SessionType === SessionType.AssistedCalibration;
                    allowAssistedTargetDisplayInd = allowAssistedTargetDisplayInd && props.dataItem.SessionType === SessionType.AssistedTargetDisplay;
                    break;
                case CalibrationSessionProgress.Ended:
                    break;
            }
        }
        return GridPanel.customCell(oldH, dr, props, listeners,
            DeviceButtonCell, { // the component used to render the cell
                calibrationSessionGuid: props.dataItem.CalibrationSessionGuid,
                companionGuid: props.dataItem.CompanionGuid,
                available: available,
                verified: verified,
                allowAssistedCalibrationInd: available && allowAssistedCalibrationInd,
                allowAssistedTargetDisplayInd: available && allowAssistedTargetDisplayInd,
            }
        );
    }

    loadGrid(dataState: State, search: string): void {

        this.loading = true; // turn on page's loading indicator

        const request = new DataRequest();
        request.$getGridData<CalibrationSessionGridDto[]>("/Service/CalibrationSession", dataState, search)
            .then((result: GridResult<CalibrationSessionGridDto[]>): void => {
                // format all data that is not readily renderable
                for (const row of result.Rows) {
                    row.SessionStartDt__Display = row.SessionStartDt ? Utility.getFormattedDateTime(new Date(row.SessionStartDt)) : "";
                    row.Progress__Display = CalibrationSessionProgressDescription[row.Progress];
                    row.SessionType_Display = SessionTypeDescription[row.SessionType || 0];
                }
                // set grid data
                this.dataResult = { // update grid's model
                    data: result.Rows,
                    total: result.Total,
                };
            })
            .catch((reason): void => {
                this.dataResult = GridPanel.EmptyDataResult;
            })
            .finally((): void => {
                this.loading = false; // turn off page's loading indicator
            });
    }

    gridDeviceClicked(calibrationSessionGuid: string, sessionType: SessionType): void {
        this.grabSession(calibrationSessionGuid, sessionType);
    }

    grabSession(sessionGuid: string, sessionType: SessionType): void {

        this.connecting = true;

        // load session & verify it hasn't started yet
        CalibrationEventSupport.sendEventSessionType(sessionGuid, CalibrationEvent.TechnicianDeviceSelected, sessionType)
            .then((result: CalibrationEventDto): void => {
                this.connecting = false;
                if (result.UpdateResult.Success) {
                    this.$refs.refDeviceStatusModal.activateSession(sessionGuid);
                } else {
                    confirmOk(result.UpdateResult.Message || `Unable to connect to device - session ID ${sessionGuid}.`);
                }
            })
            .catch((reason): void => {
                confirmOk(`Unable to connect to device - session ID ${sessionGuid} - ${reason}.`);
            })
            .finally((): void => {
                this.connecting = false;
            });
    }

    teamviewerEnableClicked(calibrationSessionGuid: string): void {
        confirmYesNo("Are you sure you want to enable Remote Assistance? This may negatively affect device performance, depending on available bandwidth.")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    CalibrationEventSupport.sendEventStartTv(calibrationSessionGuid, CalibrationEvent.StartTeamViewer, CurrentUser.userGuid!);
                }
            });
    }
    mounted(): void {
        Global.clearSession();
        CalibrationEventSupport.CancelRetryableEvents();
        SignalR.whenReady((): void => {
            this.loading = false;
            this.pageLoading = false;
        });

        SignalR.onNewDeviceCalibrationReady = (deviceName: string): void => {
            this.reloadSessions();
        };
        SignalR.onNewDeviceConnection = (): void => {
            this.reloadSessions();
        };
        SignalR.onNewDeviceCalibrationSession = (): void => {
            this.reloadSessions();
        };
        SignalR.onRemovedDeviceCalibrationSession = (): void => {
            this.reloadSessions();
        };
        SignalR.onSessionStarted = (): void => {
            this.reloadSessions();
        };
    }
    unmounted(): void {
        CalibrationEventSupport.CancelRetryableEvents();

        SignalR.onNewDeviceCalibrationReady = null;
        SignalR.onNewDeviceConnection = null;
        SignalR.onNewDeviceCalibrationSession = null;
        SignalR.onRemovedDeviceCalibrationSession = null;
        SignalR.onSessionStarted = null;
    }

    reloadSessions(): void {
        this.$refs.refDeviceGrid.reload();
    }

    $refs!: {
        refDeviceGrid: GridPanel,
        refDeviceStatusModal: DeviceStatusModal,
    }
}


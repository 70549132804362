/**
 * A global class that supports dropdown menus throughout an application.
 */
export default class DropDownMenuSupport {

    // Bootstrap dropdown menu doesn't work in divs that have overflow:hidden. While they
    // claim it "should" work and there are many workarounds (that don't really work), there
    // is a documented issue: https://github.com/twbs/bootstrap/issues/35774
    // This code will magically break one day when they fix the above issue.
    // Or maybe not. Even this code is somewhat magical...
    // If the dropdown menu's UL is not moved to the <body>, it's clipped by table cells (Telerik) and
    // vertical scrolling messes up the dropdown position.
    // This workaround has the advantage that no special CSS is needed for the dropdown. We handle dropdown
    // events and reposition the UL and contents to the <body>.

    /**
     * Used to initialize dropdown menu support for the entire application.
     */
    public static initialize(): void {
        let lastMenuDiv: HTMLElement|null = null;
        let lastMoved: HTMLElement|null = null;
        document.body.addEventListener("show.bs.dropdown", (ev: Event): void => {
            // The toggle element is the original target of this event
            // validate that it is really a dropdown button
            const toggleElem = ev.target as HTMLElement|null;
            if (!toggleElem) return;
            if (toggleElem.tagName !== "BUTTON") return;
            if (!toggleElem.className.includes("dropdown-toggle")) return;
            const menuDiv = toggleElem.parentElement;
            if (!menuDiv) return;
            // Relocate the UL and its contents
            const ulElem = menuDiv.querySelector("ul");
            if (ulElem) {
                console.debug("Dropdown showing");
                // return a pending dropdown to its original location
                if (lastMoved && lastMenuDiv) {
                    console.debug("Dropdown - Returning to original position");
                    lastMenuDiv.append(lastMoved);
                }
                // move new dropdown to body
                console.debug("Appending dropdown to body");
                document.body.append(ulElem);
                lastMoved = ulElem;
                lastMenuDiv = menuDiv;
            }
        });
        document.body.addEventListener("hide.bs.dropdown", (ev: Event): void => {
            // The toggle element is the original target of this event
            // validate that it is really a dropdown button
            const toggleElem = ev.target as HTMLElement|null;
            if (!toggleElem) return;
            if (toggleElem.tagName !== "BUTTON") return;
            if (!toggleElem.className.includes("dropdown-toggle")) return;
            const menuDiv = toggleElem.parentElement;
            if (!menuDiv) return;
            // make sure this event is for a pending dropdown
            if (menuDiv !== lastMenuDiv) return;
            // move the UL back into the grid for cleanliness
            if (lastMoved && lastMenuDiv) {
                console.debug("Dropdown - Returning to original position");
                lastMenuDiv.append(lastMoved);
            }
            lastMoved = null;
            lastMenuDiv = null;
        });
    }
}

import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("td", null, [
    _createTextVNode(_toDisplayString(_ctx.imageName) + " ", 1),
    _createVNode("span", {
      "data-bs-toggle": "tooltip",
      "data-bs-html": "true",
      title: _ctx.getTitle()
    }, [
      _createVNode(_component_fa_icon, {
        class: "fa-w-20 ms-1",
        icon: "images"
      })
    ], 8, ["title"])
  ]))
}
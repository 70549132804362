
import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";
import { CurrentUser } from "@/identity";
import App from "@/App.vue";

@Options({
})
export default class Home extends PageBase {

    configurationClicked(): void {
        App.MainApp.routerPush("/Configuration");
    }
    connectClicked(): void {
        App.MainApp.routerPush("/Connect");
    }

    get isAuthenticated(): boolean {
        return CurrentUser.isAuthenticated;
    }
}




import { Options } from "vue-class-component";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { SignalR } from "@/support/SignalR";
import { Global } from "@/support/GlobalData";
import SessionBase from "./SessionBase";
import DeviceStatusModal from "./DeviceStatusModal.vue";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import App from "@/App.vue";
import StatusMessage from "./StatusMessage.vue";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { confirmYesWaitNo } from "@/shared/components/common/AlertDialog.vue";
import SessionSidePanel from "./SessionSidePanel.vue";

@Options({
    components: {
        DeviceStatusModal,
        SessionSidePanel,
        StatusMessage,
    },
    watch: {
        $route(to, from): void { // going from this page to some page. If it's the same, check query parameters
            if (from.path.toLowerCase() === to.path.toLowerCase()) { // going to the same page, re-initialize
                this.initialize();
            }
        }
    }
})
export default class CameraSetup extends SessionBase {

    resetData(): void {
        // empty, but present for consistency
    }

    nextClicked(): void {
        CalibrationEventSupport.RetryableEvent((): void => {
            this.loading = true;
            CalibrationEventSupport.sendEvent(Global.CalibrationSession!.CalibrationSessionGuid, CalibrationEvent.GetCameraAlignmentImage);
        }, (): Promise<ButtonClicked> => {
            return confirmYesWaitNo("The device did not respond. Do you want to retry receiving the camera image? Responding No will cancel the session.");
        }, (): void => {
            App.MainApp.routerReplace("/Connect");
        }, 60); // wait 60 seconds (slow internet)
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    initialize(): void {
        CalibrationEventSupport.CancelRetryableEvents();
        this.loading = true;
        SignalR.whenReady((): void => {
            this.pageLoading = false;
            this.$refs.refDeviceStatusModal.verifySessionFromContext(false, ():void => {
                this.resetData();
                this.$nextTick((): void => {
                    this.$refs.refSessionSidePanel?.Update();
                });
                this.loading = false;
            });
        });
    }
    mounted(): void {
        this.initialize();
    }
    unmounted(): void {
        CalibrationEventSupport.CancelRetryableEvents();
    }

    $refs!: {
        refSessionSidePanel: SessionSidePanel,
        refDeviceStatusModal: DeviceStatusModal,
    }
}




import { Options } from "vue-class-component";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { SignalR } from "@/support/SignalR";
import { Global } from "@/support/GlobalData";
import SessionBase from "./SessionBase";
import DeviceStatusModal from "./DeviceStatusModal.vue";
import StatusMessage from "./StatusMessage.vue";
import { DataRequest } from "@/shared/support/Data";
import { confirmOk, confirmYesWaitNo } from "@/shared/components/common/AlertDialog.vue";
import Utility from "@/shared/support/Utility";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import ImageFooter from "./ImageFooter.vue";
import TargetRendererDto from "@/shared/models/TargetRendererDto";
import App from "@/App.vue";
import { VehicleCalibrationTargetImageDto } from "@/shared/models/VehicleCalibrationTargetImageDto";
import Globals from "@/support/Globals";
import SessionSidePanel from "./SessionSidePanel.vue";

@Options({
    components: {
        DeviceStatusModal,
        SessionSidePanel,
        StatusMessage,
        ImageFooter,
    },
    watch: {
        $route(to, from): void { // going from this page to some page. If it's the same, check query parameters
            if (from.path.toLowerCase() === to.path.toLowerCase()) { // going to the same page, re-initialize
                this.initialize();
            }
        }
    }
})
export default class SendTargetImages extends SessionBase {

    imageNumber = 0;
    imageUrl: string = "";
    targetImageUploadId: number = 0;
    sendingImage = false;
    receivingImageConfirmation = false;

    resetData(): void {
        this.imageNumber = 0;
        this.imageUrl = "";
        this.targetImageUploadId = 0;
        this.sendingImage = false;
        this.receivingImageConfirmation = false;
    }

    getTotalImages(): number {
        if (!Global.VehicleCalibration) return 0;
        return Global.VehicleCalibration!.VehicleCalibrationTargetImages.length;
    }

    previousClicked(): void {
        if (this.imageNumber > 0) {
            this.getDisplayImage(this.imageNumber-1, true);
        }
    }
    getDisplayImage(imageNumber: number, send: boolean): void {

        this.sendingImage = true;

        const dr = new DataRequest();
        const targetRenderer: TargetRendererDto = {
            DeviceGuid: Global.CalibrationSession!.DeviceGuid,
            CalibrationSessionGuid: Global.CalibrationSession!.CalibrationSessionGuid,
            TargetImageIndex: imageNumber,
        } as TargetRendererDto;
        dr.$post<TargetRendererDto, TargetRendererDto>("/Service/TargetRenderer", null, targetRenderer)
            .then((rendererResult: TargetRendererDto): void => {
                this.sendingImage = false;
                if (rendererResult.UpdateResult.Success) {
                    const url = Utility.formatUrl(rendererResult.FetchUrl, {}, Globals.GetWebApi(), true);
                    this.imageUrl = url;
                    this.targetImageUploadId = rendererResult.TargetImageUpload.UploadId!;
                    this.imageNumber = imageNumber;
                    if (send)
                        this.receiveImageConfirmation();
                } else {
                    confirmOk(rendererResult.UpdateResult.Message || "An error occurred rendering the target image");
                }
            })
            .catch((reason): void => {
                confirmOk(`An unexpected error occurred rendering the target image - ${reason}`);
            })
            .finally((): void => {
                this.sendingImage = false;
            });
    }

    receiveImageConfirmation(): void {
        CalibrationEventSupport.RetryableEvent((): void => {
            CalibrationEventSupport.sendEventTargetImageUploadId(Global.CalibrationSession!.CalibrationSessionGuid, CalibrationEvent.VehicleTargetImageSelected, this.targetImageUploadId, this.imageNumber);
            this.receivingImageConfirmation = true;
        }, (): Promise<ButtonClicked> => {
            return confirmYesWaitNo("The device did not respond. Do you want to try sending the image again? Responding No will cancel the session.");
        }, (): void => {
            App.MainApp.routerReplace("/connect");
        }, 60); // wait 60 seconds (slow internet)
    }

    successClicked(): void {
        CalibrationEventSupport.sendEvent(Global.CalibrationSession!.CalibrationSessionGuid!, CalibrationEvent.ImageCalibrationSucceeded);
        if (this.imageNumber < this.getTotalImages()-1) {
            this.getDisplayImage(this.imageNumber+1, true);
        } else {
            App.MainApp.routerReplace("/session/Completed", { session: Global.CalibrationSession!.CalibrationSessionGuid });
        }
    }
    failedClicked(): void {
        App.MainApp.routerReplace("/session/WindshieldAngle", { session: Global.CalibrationSession!.CalibrationSessionGuid });
    }
    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    initialize(): void {
        CalibrationEventSupport.CancelRetryableEvents();
        this.loading = true;
        SignalR.whenReady((): void => {
            this.pageLoading = false;
            this.$refs.refDeviceStatusModal.verifySessionFromContext(false, ():void => {
                this.loading = false;
                this.resetData();
                // Find out which image is currently displayed on the auggie device. There may not be one.
                const query = this.$router.currentRoute.value.query;
                const targetNumberString = (query.targetnumber as string|undefined) || null;
                let targetNumber = 0;
                let num = -1;
                if (targetNumberString ) {
                    num = Number(targetNumberString);
                    if (num >= 0)
                        targetNumber = num;
                }
                this.getDisplayImage(targetNumber, targetNumber === 0 && num < 0);
                this.$nextTick((): void => {
                    this.$refs.refSessionSidePanel?.Update();
                });
            });
        });
    }
    getNumberFromUploadId(uploadId: number) : number {
        const index = Global.VehicleCalibration!.VehicleCalibrationTargetImages.findIndex((v: VehicleCalibrationTargetImageDto, index: number): boolean => {
            return v.TargetImage.UploadId === uploadId;
        });
        return index < 0 ? 0 : index;
    }

    mounted(): void {
        this.initialize();
    }
    unmounted(): void {
        CalibrationEventSupport.CancelRetryableEvents();
    }

    $refs!: {
        refSessionSidePanel: SessionSidePanel,
        refDeviceStatusModal: DeviceStatusModal,
    }
}


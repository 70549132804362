

import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { Dropdown } from "bootstrap";

import { DataRequest } from "@/shared/support/Data";

interface Entry {
    Key: string;
    Value: string;
}

@Options({
    watch: {
        searchText(val: string) {
            this.updateSelection(val);
        },
        modelValue(val: string|number): void {
            if (!val) {
                this.selectedEntry = null;
                this.searchText = "";
                this.searchResults = [];
            }
        }
    }
})
export default class SearchSelection extends Vue {

    selectedEntry: Entry | null = null;
    searchText: string = "";
    searchResults: Entry[] = [];
    isFocused: boolean = false;
    loading: boolean = false;

    dropDownButton: Dropdown = null!;
    dropDownOpen = false;
    inDropDown = false;

    @Prop({  }) readonly options: any = null!;
    @Prop({ default: "" }) readonly modelValue: string|number = "";
    @Prop({ default: 200 }) readonly maxlength: number = 200;
    @Prop({ default: "", required: true }) readonly searchUrl: string = "";
    @Prop({ default: false }) readonly allowNew: boolean = false;
    @Prop({ default: 25 }) readonly maxRecords = 25;
    @Prop({ default: false }) readonly readonly: boolean = false;
    @Prop({ default: false }) readonly disabled: boolean = false;
    @Prop({ default: "Search Text" }) readonly placeholder: string = "";

    get isSearchable(): boolean {
        return !this.selectedEntry;
    }

    isMatch(entry: Entry): boolean {
        return (this.isFocused || this.inDropDown) && entry.Value.toString().toLowerCase() === this.searchText.toLowerCase();
    }

    showDropDown(): void {
        if (this.searchResults && this.searchResults.length > 0) {
            this.dropDownOpen = true;
            this.inDropDown = false;
            this.dropDownButton.show();
            this.downArrow();
        } else {
            this.hideDropDown();
        }
    }
    hideDropDown(): void {
        this.dropDownButton.hide();
        this.dropDownOpen = false;
    }

    downArrow(): void {
        if (!this.dropDownOpen && this.searchResults && this.searchResults.length > 0) {
            this.showDropDown();
        }
    }

    updateSelection(val: string): void {
        if (!this.isFocused) return;        
        
        this.loading = true;
        this.searchResults = [];

        if (this.searchUrl && this.searchText) {
            const dr = new DataRequest();
            dr.$get<Entry[]>(this.searchUrl, { SearchPhrase: this.searchText, MaxRecords: this.maxRecords })
                .then((result: Entry[]): void => {
                    this.searchResults = result;
                    this.loading = false;
                    this.showDropDown();
                })
                .catch((reason): void => {
                    this.loading = false;
                    this.hideDropDown();
                });
        } else {
            this.hideDropDown();
        }
    }

    acceptSelection(): void {
        this.$emit("update:modelValue", this.selectedEntry?.Key || null);
        this.$emit("selection");
        this.hideDropDown();
    }

    selectResult(entry: Entry): void {
        this.selectedEntry = entry;
        this.searchText = entry.Value;
        this.hideDropDown();
        this.$emit("update:modelValue", this.selectedEntry.Key);
        this.$emit("selection");
    }
    clearSelected(): void {
        this.selectedEntry = null;
        this.searchResults = [];
        if (!this.allowNew) {
            this.searchText = "";
            this.$emit("update:modelValue", null);
            this.$emit("selection");
        }
    }

    searchFocusIn(): void {
        this.isFocused = true;
    }
    searchFocusOut(): void {
        // allow any mouse clicks to be processed before closing the dropdown
        setTimeout((): void => {
            if (this.inDropDown) {
                this.inDropDown = false;
            } else {
                this.isFocused = false;
                this.hideDropDown();
                if (!this.selectedEntry && !this.allowNew) {
                    this.searchText = "";
                }
            }
        }, 500);
    }

    mounted(): void {
        this.dropDownButton = new Dropdown(this.$refs.ddButton);
        this.$refs.ddButton.addEventListener("hide.bs.dropdown", (event: Event): boolean => {
            this.dropDownOpen = false;
            this.inDropDown = false;
            return true;
        });
        this.$refs.ddButton.addEventListener("show.bs.dropdown", (event: Event): boolean => {
            // when we don't have search results, prevent the dropdown from opening
            if (!this.searchResults || this.searchResults.length === 0) {
                event.preventDefault();
                return false;
            } else {
                this.dropDownOpen = true;
                this.inDropDown = false;
            }
            return true;
        });
        document.addEventListener("click", this.handleMouseClicks);
    }
    unmounted(): void {
        document.removeEventListener("click", this.handleMouseClicks);
    }
    handleMouseClicks(event: MouseEvent): boolean {
        // Close the dropdown when clicking outside
        this.inDropDown = false;
        if (this.dropDownOpen) {
            const target = event.target;
            if (target && this.$refs.ddMenu.contains(target as Node)) {
                // clicking on dropdown menu
                this.inDropDown = true;
            } else {
                event.preventDefault();
                event.stopPropagation();
                this.hideDropDown();
                return false;
            }
        }
        return true;
    }

    $refs!: {
        ddButton: HTMLButtonElement,
        ddMenu: HTMLUListElement,
    }
}


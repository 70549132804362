

import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";

import CalibrationsGrid from "./CalibrationsGrid.vue";
import AuggieIcon from "@/shared/components/common/AuggieIcon.vue";
import { SignalR } from "@/support/SignalR";

@Options({
    components: {
        CalibrationsGrid,
        AuggieIcon,
    },
})
export default class Packages extends PageBase {

    mounted(): void {
        SignalR.whenReady((): void => {
            this.pageLoading = false;
        });
    }

}


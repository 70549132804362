

import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { DataRequest } from "@/shared/support/Data";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import Toast from "@/shared/support/Toast";
import Events from "@/shared/support/Events";
import { UserRoles } from "@/identity";
import TooltipSupport from "@/shared/support/Tooltip";
import Utility from "@/shared/support/Utility";
import App from "@/App.vue";
import Globals from "@/support/Globals";

export default class PackageButtonCell extends Vue {

    @Prop({ required: true }) readonly packageId: number = 0;
    @Prop({ required: true }) readonly packageName: string = "";
    @Prop({ required: true }) readonly active: boolean = false;

    canEdit(): boolean {
        return UserRoles.has("AuggiePackageEdit");
    }
    canView(): boolean {
        return !this.canEdit() && UserRoles.has("AuggiePackageView");
    }
    canRemove(): boolean {
        return UserRoles.has("AuggiePackageDelete");
    }

    showPackageModal(): void {
        App.MainApp.routerPush("/packages", { packageid: this.packageId });
    }
    removePackage(): void {
        confirmYesNo(`Are you sure you want to deactivate Package "${this.packageName}"?`)
            .then((clicked: ButtonClicked) => {
                if (clicked === ButtonClicked.Ok)
                    this.postRemovePackage();
            });
    }
    restorePackage(): void {
        confirmYesNo(`Are you sure you want to activate Package "${this.packageName}"?`)
            .then((clicked: ButtonClicked) => {
                if (clicked === ButtonClicked.Ok)
                    this.postRestorePackage();
            });
    }
    private postRemovePackage(): void {
        const dr = new DataRequest();
        dr.$delete<boolean>(`/Service/Packages/${this.packageId}`)
            .then((result: boolean): void => {
                if (result) {
                    Toast.success(`Package "${this.packageName}" has been successfully deactivated.`);
                    Events.bubbleEvent(this, "package-grid-reload", this.packageId);
                } else {
                    confirmOk(`An error occurred deactivating Package "${this.packageName}".`);
                }
            }).catch((reason: any): void => {
                confirmOk(`An error occurred deactivating Package "${this.packageName}": ${reason}`);
            });
    }
    private postRestorePackage(): void {
        const dr = new DataRequest();
        dr.$delete<boolean>(`/Service/Packages/${this.packageId}`, { Restore: true })
            .then((result: boolean): void => {
                if (result) {
                    Toast.success(`Package "${this.packageName}" has been successfully activated.`);
                    Events.bubbleEvent(this, "package-grid-reload", this.packageId);
                } else {
                    confirmOk(`An error occurred activating Package "${this.packageName}".`);
                }
            }).catch((reason: any): void => {
                confirmOk(`An error occurred activating Package "${this.packageName}": ${reason}`);
            });
    }
    get downloadUrl(): string {
        return Utility.formatUrl(`/Service/Packages/GetPackage/${this.packageName}`, null, Globals.GetWebApi(), true);
    }
    downloadClicked(): void {
        TooltipSupport.clearTooltips();
    }
}




import { CalibrationSessionDto } from "@/shared/models/CalibrationSessionDto";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { Global } from "@/support/GlobalData";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class WheelArchPanel extends Vue {

    @Prop({ default: null, required: true }) readonly vehicle: VehicleDto | null = null;
    @Prop({ default: null, required: true }) readonly session: CalibrationSessionDto | null = null;

    model: VehicleDto|null = this.vehicle;
    sessionModel: CalibrationSessionDto|null = this.session;

    get measurementsEnabled(): boolean {
        if (!this.model || !this.sessionModel) return false;
        if (!this.model.MeasuredWheelArchHeightFrontLeft || !this.model.MeasuredWheelArchHeightFrontRight ||
            !this.model.MeasuredWheelArchHeightRearLeft || !this.model.MeasuredWheelArchHeightRearRight ||
            !this.sessionModel.RideHeightFrontLeft || !this.sessionModel.RideHeightFrontRight ||
            !this.sessionModel.RideHeightRearLeft || !this.sessionModel.RideHeightRearRight)
            return false;
        return this.sessionModel?.WheelArchHeightEnabledInd || false;
    }
    get measFL(): string { return this.model?.MeasuredWheelArchHeightFrontLeft?.toString()  || "n/a"; }
    get measFR(): string { return this.model?.MeasuredWheelArchHeightFrontRight?.toString()  || "n/a"; }
    get measRL(): string { return this.model?.MeasuredWheelArchHeightRearLeft?.toString()  || "n/a"; }
    get measRR(): string { return this.model?.MeasuredWheelArchHeightRearRight?.toString()  || "n/a"; }
    get subFL(): string { return this.sessionModel?.RideHeightFrontLeft?.toString() || "n/a"; }
    get subFR(): string { return this.sessionModel?.RideHeightFrontRight?.toString() || "n/a"; }
    get subRL(): string { return this.sessionModel?.RideHeightRearLeft?.toString() || "n/a"; }
    get subRR(): string { return this.sessionModel?.RideHeightRearRight?.toString() || "n/a"; }

    public Update(): void {
        this.model = Global.Vehicle || null;
        this.sessionModel = Global.CalibrationSession || null;
    }
}


import { CurrentUser } from "@/identity";
import * as signalR from "@microsoft/signalr";
import Toast, { FeedbackFormat, ToastFeedback } from "@/shared/support/Toast";
import Utility from "@/shared/support/Utility";
import { CalibrationEventSupport } from "./CalibrationEvent";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { Global } from "./GlobalData";
import { SessionType } from "@/shared/enums/SessionType";

export class SignalRSupport {

    public static readonly CalibrationCanceledByDevice: string  = "CalibrationCanceledByDevice";
    public static readonly CalibrationDisconnectedDevice: string  = "CalibrationDisconnectedDevice";
    public static readonly NewDeviceConnection: string  = "NewDeviceConnection";
    public static readonly NewDeviceCalibrationSession: string  = "NewDeviceCalibrationSession";
    public static readonly NewDeviceCalibrationReady: string  = "NewDeviceCalibrationReady";
    public static readonly RemovedDeviceCalibrationSession: string  = "RemovedDeviceCalibrationSession";
    public static readonly SessionStarted: string  = "SessionStarted";
    public static readonly CameraPositionAccepted: string  = "CameraPositionAccepted";
    public static readonly CameraPositionRejected: string  = "CameraPositionRejected";
    public static readonly VehicleTargetImageDisplayed: string  = "VehicleTargetImageDisplayed";
    public static readonly CameraAlignmentImageAvailable: string  = "CameraAlignmentImageAvailable";
    public static readonly CameraAlignmentReached: string  = "CameraAlignmentReached";
    public static readonly DeviceOutOfSync: string  = "DeviceOutOfSync";
    public static readonly CurrentLightIntensity: string  = "CurrentLightIntensity";
    public static readonly AcknowledgeByDevice: string  = "AcknowledgeByDevice";
    public static readonly TeamViewerStarted: string  = "TeamViewerStarted";
    public static readonly CurrentDeviceStatus: string = "CurrentDeviceStatus";
    public static readonly DeviceFailure: string  = "DeviceFailure";

    domain: string | undefined = undefined;
    onStartedCallbacks: (() => void)[] = [];

    public get connection(): signalR.HubConnection {
        if (!this.connectionCache) throw new Error("Attempted use of disconnected SignalR connection");
        return this.connectionCache;
    }
    private connectionCache: signalR.HubConnection|null = null;

    public get isConnected(): boolean {
        return this.isConnectedCache;
    }
    isConnectedCache = false;
    connecting = false;

    public onCalibrationCanceledByDevice: (() => void) | null = null;
    public onCalibrationDisconnectedDevice: (() => void) | null = null;
    public onNewDeviceCalibrationReady: ((deviceName: string) => void) | null = null;
    public onNewDeviceConnection: (() => void) | null = null;
    public onNewDeviceCalibrationSession: (() => void) | null = null;
    public onRemovedDeviceCalibrationSession: (() => void) | null = null;
    public onSessionStarted: (() => void) | null = null;
    public onCameraPositionAccepted: (() => void) | null = null;
    public onCameraPositionRejected: (() => void) | null = null;
    public onCameraAlignmentImageAvailable: (() => void) | null = null;
    public onCameraAlignmentReached: (() => void) | null = null;
    public onCurrentLightIntensity: ((intensity: number) => void) | null = null;
    public onAcknowledgeByDevice: (() => void) | null = null;
    public onTeamViewerStarted: ((device: string, techGuid: string|null) => void) | null = null;

    public start(domain?: string): void {

        // Note that the domain has to be the URL of the FUNCTION, not Azure SignalR
        // The initial Negotiate call (to the Function) establishes the actual SignalR URL
        // see https://docs.microsoft.com/en-us/azure/azure-signalr/signalr-concept-serverless-development-config#sending-messages-from-a-client-to-the-service

        if (this.isConnected || this.connecting) return;
        if (domain)
            this.domain = domain;

        this.updateStatus("Connection Starting...", "label-info", "Connecting...");

        this.connecting = true;
        this.connectionCache = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Error)
            .withAutomaticReconnect()
            .withUrl(Utility.formatUrl("", { }, this.domain))
            .build();
        this.bindConnection();
        this.connectionCache.start()
            .then((): void => {
                this.connecting = false;
                this.onConnected();
            })
            .catch((error): void => {
                this.connecting = false;
                this.connectionCache = null;
                console.error(error?.message || "SignalR connection failed");
                this.updateStatus("Disconnected!", "label-danger", "Disconnected");
            });
    }

    private clientPingInterval = 0;

    setupClientPing(): void {
        if (this.clientPingInterval) return;
        const pingInterval = Number(process.env.VUE_APP_CLIENTPINGFREQUENCY) * 1000;
        if (isNaN(pingInterval)) throw new Error("SignalR: VUE_APP_CLIENTPINGFREQUENCY not defined in .env");
        console.debug(`PING ==> ${pingInterval}`);
        this.clientPingInterval = setInterval((): void => {
            if (!this.isConnected) return;
            console.debug(`SignalR: Client Ping ${window.location.pathname}`);
            this.connection.send("PageNavigation", window.location.pathname);
        }, pingInterval) as unknown as number;
    }

    bindConnection(): void {
        this.connection.onclose((error: Error | undefined): void => {
            if (error && error.message)
                console.error(error.message);
            this.isConnectedCache = false;
            this.connectionCache = null;
            this.updateStatus("Disconnected!", "label-danger", "Site Disconnected");
            Toast.error("The server connection has been lost");
        });
        // eslint-disable-next-line
        this.connection.onreconnecting((error: Error | undefined): void => {
            this.updateStatus("Reconnecting...", "label-info", "Reconnecting...");
            Toast.info("Reconnecting to server...");
        });
        this.connection.onreconnected((id: string | undefined): void => {
            this.updateStatus("Connection Established.", "label-success", "Site Connected");
            Toast.info("Reconnected to server");
        });

        this.connection.on(SignalRSupport.CalibrationCanceledByDevice, (): void => {
            CalibrationEventSupport.CancelRetryableEvents();
            if (this.onCalibrationCanceledByDevice)
                this.onCalibrationCanceledByDevice();
        });
        this.connection.on(SignalRSupport.CalibrationDisconnectedDevice, (): void => {
            CalibrationEventSupport.CancelRetryableEvents();
            if (this.onCalibrationDisconnectedDevice)
                this.onCalibrationDisconnectedDevice();
        });
        this.connection.on(SignalRSupport.NewDeviceCalibrationReady, (deviceName: string): void => {
            Toast.info(`A new calibration request for ${deviceName||"(name n/a)"} is available`);
            if (this.onNewDeviceCalibrationReady)
                this.onNewDeviceCalibrationReady(deviceName);
        });
        this.connection.on(SignalRSupport.NewDeviceConnection, (): void => {
            if (this.onNewDeviceConnection)
                this.onNewDeviceConnection();
        });
        this.connection.on(SignalRSupport.NewDeviceCalibrationSession, (): void => {
            if (this.onNewDeviceCalibrationSession)
                this.onNewDeviceCalibrationSession();
        });
        this.connection.on(SignalRSupport.RemovedDeviceCalibrationSession, (): void => {
            if (this.onRemovedDeviceCalibrationSession)
                this.onRemovedDeviceCalibrationSession();
        });
        this.connection.on(SignalRSupport.SessionStarted, (): void => {
            if (this.onSessionStarted)
                this.onSessionStarted();
        });
        this.connection.on(SignalRSupport.CameraPositionAccepted, (): void => {
            CalibrationEventSupport.CancelRetryableEvents();
            if (this.onCameraPositionAccepted)
                this.onCameraPositionAccepted();
        });
        this.connection.on(SignalRSupport.CameraPositionRejected, (): void => {
            CalibrationEventSupport.CancelRetryableEvents();
            if (this.onCameraPositionRejected)
                this.onCameraPositionRejected();
        });
        this.connection.on(SignalRSupport.CameraAlignmentImageAvailable, (): void => {
            CalibrationEventSupport.CancelRetryableEvents();
            if (this.onCameraAlignmentImageAvailable)
                this.onCameraAlignmentImageAvailable();
        });
        this.connection.on(SignalRSupport.CameraAlignmentReached, (): void => {
            CalibrationEventSupport.CancelRetryableEvents();
            if (this.onCameraAlignmentReached)
                this.onCameraAlignmentReached();
        });
        this.connection.on(SignalRSupport.DeviceOutOfSync, (): void => {
            CalibrationEventSupport.CancelRetryableEvents();
            Toast.error("The Auggie device is out of sync and can't respond to the current action. The session may need to be restarted.");
        });
        this.connection.on(SignalRSupport.CurrentLightIntensity, (intensity: number): void => {
            if (this.onCurrentLightIntensity)
                this.onCurrentLightIntensity(intensity);
        });
        this.connection.on(SignalRSupport.AcknowledgeByDevice, (): void => {
            // the only purpose of this message is to cancel retry of a retriable event
            CalibrationEventSupport.CancelRetryableEvents();
        });
        this.connection.on(SignalRSupport.TeamViewerStarted, (deviceName: string, techGuid: string|null): void => {
            if (this.onTeamViewerStarted)
                this.onTeamViewerStarted(deviceName, techGuid);
        });
        this.connection.on(SignalRSupport.DeviceFailure, (msg: string): void => {
            CalibrationEventSupport.CancelRetryableEvents();
            ToastFeedback.error(FeedbackFormat.Close, msg, "Auggie Device Failure");
        });

        this.connection.on(SignalRSupport.CurrentDeviceStatus, (data: string|null): void => {
            if (!Global.CalibrationSession) return; // no session, not for us
            if (!Global.CalibrationSession.TechnicianUserGuid) return; // no tech, not for us
            if (Global.CalibrationSession.TechnicianUserGuid !== CurrentUser.userGuid) return; // for different tech
            if (Global.CalibrationSession.SessionType !== SessionType.AssistedCalibration && Global.CalibrationSession.SessionType !== SessionType.AssistedTargetDisplay) return; // not assisted

            if (process.env.VUE_APP_DEBUG === "1")
                console.debug(data);

            if (!data) return;
            const deviceStatus: DeviceStatusDto = JSON.parse(data);
            const deviceName = deviceStatus.DeviceName;
            if (Global.CalibrationSession.DeviceName !== deviceName) return;// for a different device

            const page = deviceStatus.Page;

            switch (page) {
                case "Home":
                case "CalibrationVehicle":
                    Global.MainApp.routerReplace("/session/Vehicle", { session: Global.CalibrationSession.CalibrationSessionGuid });
                    break;
                case "CalibrationRideHeight":
                    Global.MainApp.routerReplace("/session/RideHeight", { session: Global.CalibrationSession.CalibrationSessionGuid });
                    break;
                case "CalibrationCameraSetup":
                    if (!deviceStatus.IsTechControlled)
                        Global.MainApp.routerReplace("/session/CameraSetup", { session: Global.CalibrationSession.CalibrationSessionGuid });
                    break;
                case "CalibrationCameraReady":
                    if (deviceStatus.CameraVerifying) {
                        Global.MainApp.routerReplace("/session/CameraVerifying", { session: Global.CalibrationSession.CalibrationSessionGuid });
                    } else {
                        Global.MainApp.routerReplace("/session/CameraReady", {
                            session: Global.CalibrationSession.CalibrationSessionGuid,
                            // not used here YawRollValid: deviceStatus.YawRollValid
                        });
                    }
                    break;
                case "CalibrationVin":
                case "CalibrationCameraImage":
                case "CalibrationSendTargets":
                case "CalibrationContacting":
                    // only used in self calibration
                    break;
                case "CalibrationInProgress":
                    // Calibration in progress
                    if (deviceStatus.TargetNumber == null || deviceStatus.TargetNumber < 0) {
                        Global.MainApp.routerReplace("/session/CameraVerification", {
                            session: Global.CalibrationSession.CalibrationSessionGuid,
                            // not used TechnicianName: deviceStatus.TechnicianName,
                        });
                    } else {
                        Global.MainApp.routerReplace("/session/SendTargetImages", {
                            session: Global.CalibrationSession.CalibrationSessionGuid,
                            // not used TechnicianName: deviceStatus.TechnicianName,
                            targetnumber: deviceStatus.TargetNumber,
                        });
                    }
                    break;
                case "CalibrationComplete":
                case "CalibrationFailed":
                case "CalibrationEnded":
                    // no action needed
                    break;
            }
        });
    }

    techSessionStarted = false;

    startTechSession(): boolean {
        if (!this.isConnected) return false;
        if (!CurrentUser.isAuthenticated) return false;
        if (this.techSessionStarted) return true;

        const techGuid = CurrentUser.userGuid;
        if (!techGuid) return false;

        this.connection.send("techStartSession", techGuid);
        this.techSessionStarted = true;

        this.updateStatus("Connection Established.", "label-success", "Connected");

        for (const callback of this.onStartedCallbacks)
            callback();
        this.onStartedCallbacks = [];

        return true;
    }

    onConnected(): void {
        console.log("SignalR connection started");
        this.isConnectedCache = true;

        this.startTechSession();
    }

    updateStatus(logMsg: string, css: string, text: string): void {
        console.log(logMsg);
        if (this.updateStatusCallback)
            this.updateStatusCallback(css, text);
    }
    public registerUpdateStatusCallback(callback: ((css: string, text: string) => void)|null): void {
        this.updateStatusCallback = callback;
    }
    updateStatusCallback: ((css: string, text: string) => void)|null = null;

    /**
     * Register a callback that is called as soon as SignalR and a user session is available.
     * Views, modals and components that rely on SignalR must call this, usually in mounted().
     * @param callback
     */
    whenReady(callback: () => void): void {
        if (this.startTechSession())
            callback();
        else
            this.onStartedCallbacks.push(callback);
    }
}

export const SignalR = new SignalRSupport();
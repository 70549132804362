
import { Options, Vue } from "vue-class-component";

@Options({
})
export default class Footer extends Vue {

    STARTYEAR = 2021;

    version(): string {
        if (!process.env.VUE_APP_VERSION) throw new Error("VUE_APP_VERSION not defined in .env");
        return process.env.VUE_APP_VERSION;
    }
    openUnimatrix(): void {
        window.open("//unimatrixdesigns.com", "UniMatrixDesigns");
    }
    getYears(): string {
        const year = this.thisYear();
        if (year !== this.STARTYEAR) {
            return `${this.STARTYEAR}-${year}`;
        } else {
            return year.toString();
        }
    }
    thisYear(): number {
        return new Date().getFullYear();
    }
}



import { Options } from "vue-class-component";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { SignalR } from "@/support/SignalR";
import { Global } from "@/support/GlobalData";
import SessionBase from "./SessionBase";
import DeviceStatusModal from "./DeviceStatusModal.vue";
import App from "@/App.vue";
import StatusMessage from "./StatusMessage.vue";
import { CameraImageDto } from "@/shared/models/CameraImageDto";
import { CameraStatus } from "@/shared/enums/CameraStatus";
import { DataRequest } from "@/shared/support/Data";
import { confirmOk, confirmYesWaitNo } from "@/shared/components/common/AlertDialog.vue";
import Utility from "@/shared/support/Utility";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import ImageFooter from "./ImageFooter.vue";
import Globals from "@/support/Globals";
import SessionSidePanel from "./SessionSidePanel.vue";

@Options({
    components: {
        DeviceStatusModal,
        SessionSidePanel,
        StatusMessage,
        ImageFooter,
    },
    watch: {
        $route(to, from): void { // going from this page to some page. If it's the same, check query parameters
            if (from.path.toLowerCase() === to.path.toLowerCase()) { // going to the same page, re-initialize
                this.initialize();
            }
        }
    }
})
export default class CameraVerification extends SessionBase {

    alignmentResult: CameraImageDto|null = null;
    sendingImage = false;

    get cameraImage(): string {
        return this.cameraImageCache;
    }
    set cameraImage(image: string) {
        this.cameraImageCache = image;
    }
    cameraImageCache = "";

    cameraNotFound(): boolean {
        return !this.alignmentResult || (this.alignmentResult.Success !== CameraStatus.Found && this.alignmentResult.Success !== CameraStatus.FoundNotWithinTolerance);
    }
    cameraFound(): boolean {
        return !!this.alignmentResult && this.alignmentResult.Success === CameraStatus.Found;
    }

    cameraFoundNotWithinTolerance(): boolean {
        return !!this.alignmentResult && this.alignmentResult.Success === CameraStatus.FoundNotWithinTolerance;
    }

    resetData(): void {
        this.alignmentResult = null;
        this.cameraImage = "";
        this.sendingImage = false;
    }

    cancelClicked(): void {
        App.MainApp.cancelCalibration();
    }

    getFormattedScaleXY(): string {
        if (!this.alignmentResult || this.alignmentResult.Success === CameraStatus.NotFound) return "";
        const scale = this.alignmentResult.TargetScale || 0;
        const x = this.alignmentResult.TargetXdif || 0;
        const y = this.alignmentResult.TargetDropY || 0;
        return `${scale.toFixed(6)} / ${x.toFixed(6)} / ${y.toFixed(6)}`;
    }

    loadCameraImage(): void {

        this.loading = true;

        this.alignmentResult = null;
        const request = new DataRequest();
        request.$post<any, CameraImageDto>("/Service/CameraParser/GetCameraImageInfo", { CalibrationSessionGuid: Global.CalibrationSession!.CalibrationSessionGuid })
            .then((cameraImage: CameraImageDto): void => {
                this.loading = false;
                if (!cameraImage.Url) {
                    confirmOk("An unexpected error occurred retrieving the camera image");
                    this.resetData();
                    return;
                }
                const url = Utility.formatUrl(cameraImage.Url, {}, Globals.GetWebApi(), true);
                this.loading = true; // we'll get an onload event (cameraImageLoaded) which clears the loading indicator
                this.cameraImage = url;
                this.alignmentResult = cameraImage;
            })
            .catch((reason): void => {
                confirmOk(`An unexpected error occurred retrieving the camera image - ${reason}`);
                this.resetData();
            })
            .finally((): void => {
                this.loading = false;
            });
    }
    cameraImageLoaded(): void {
        this.loading = false;
    }

    recalibrateClicked(): void {
        this.loading = true;
        CalibrationEventSupport.RetryableEvent((): void => {
            CalibrationEventSupport.sendEvent(Global.CalibrationSession!.CalibrationSessionGuid, CalibrationEvent.TechnicianCameraRejected);
        }, (): Promise<ButtonClicked> => {
            return confirmYesWaitNo("The device did not respond. Do you want to retry recalibrating the camera? Responding No will cancel the session.");
        }, (): void => {
            this.resetData();
            App.MainApp.routerReplace("/connect");
        }, 60);
    }

    sendFirstImageClicked(): void {
        App.MainApp.routerReplace("/session/SendTargetImages", {
            session: Global.CalibrationSession!.CalibrationSessionGuid,
            // not used TechnicianName: deviceStatus.TechnicianName,
            targetImageId: -1,
        });
    }

    initialize(): void {
        CalibrationEventSupport.CancelRetryableEvents();
        this.loading = true;
        SignalR.whenReady((): void => {
            this.pageLoading = false;
            this.$refs.refDeviceStatusModal.verifySessionFromContext(false, ():void => {
                this.loading = false;
                this.resetData();
                this.loadCameraImage();
                this.$nextTick((): void => {
                    this.$refs.refSessionSidePanel?.Update();
                });
            });
        });
    }
    mounted(): void {
        this.initialize();
    }
    unmounted(): void {
        CalibrationEventSupport.CancelRetryableEvents();
    }

    $refs!: {
        refSessionSidePanel: SessionSidePanel,
        refDeviceStatusModal: DeviceStatusModal,
    }
}


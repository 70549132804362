

import { DataRequest } from "@/shared/support/Data";

import { confirmOk, confirmYesWaitNo } from "@/shared/components/common/AlertDialog.vue";
import { Global } from "@/support/GlobalData";
import { CalibrationSessionDto } from "@/shared/models/CalibrationSessionDto";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { SignalR } from "@/support/SignalR";
import { CurrentUser } from "@/identity";
import ModalBase from "@/shared/components/common/ModalBase";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { VehicleCalibrationDto } from "@/shared/models/VehicleCalibrationDto";
import { SessionType } from "@/shared/enums/SessionType";

export default class DeviceStatusModal extends ModalBase {

    /**
     * Every page in an active session must call this function (in mounted()). The session guid is extracted
     * from the page's query string.
     * If session information is available, all is well, this function calls the success callback.
     * Otherwise we show a modal while we query the device status. Once we get the device status,
     * the SignalR message sent from the device will be routed to the correct page to resume the session.
     * If the device status can't be obtained, we show an error popup and return to the home page.
     */
    verifySessionFromContext(forceReload: boolean, success: () => void): void {
        console.debug(`verifySessionFromContext forceReload=${forceReload}`);

        // we must have a session in the query string
        const query = this.$router.currentRoute.value.query;
        const calibrationSessionGuid = (query.session as string|undefined) || null;

        if (!calibrationSessionGuid) {
            console.error("No calibration session ID");
            Global.MainApp.routerReplace("/connect");
            return;
        }

        if (Global.CalibrationSession && Global.CalibrationSession.CalibrationSessionGuid !== calibrationSessionGuid) {
            console.error("Calibration session for another user");
            Global.MainApp.routerReplace("/connect");
            return;
        }

        const fail = (): void => {
            ModalBase.killModals();
            Global.MainApp.routerReplace("/connect");
        };

        if (!Global.CalibrationSession) {
            this.show(calibrationSessionGuid,
                (): void => {
                    this.getDeviceStatus(fail);
                },
                fail);
        } else if (forceReload) {
            this.getSessionInfo(Global.CalibrationSession!.CalibrationSessionGuid, success, fail);
        } else {
            this.loadVehicleData(null, success, fail);
        }
    }
    /**
     * Activates a session if session information is available.
     * We show a modal while we query the device status. Once we get the device status,
     * the SignalR message sent from the device will be routed to the correct page to resume the session.
     * If the device status can't be obtained, we show an error popup.
     */
    activateSession(calibrationSessionGuid: string): void {
        console.debug(`activateSession calibrationSessionGuid=${calibrationSessionGuid}`);

        const fail = (): void => {
            Global.clearSession();
            ModalBase.killModals();
        };

        this.show(calibrationSessionGuid,
            (): void => {
                this.getDeviceStatus(fail);
            },
            fail
        );
    }

    show(calibrationSessionGuid: string, success?: () => void, fail?: () => void): void {
        console.debug(`show calibrationSessionGuid=${calibrationSessionGuid}`);
        this.showModal();
        this.getSessionInfo(calibrationSessionGuid, success, fail);
    }
    hide():void {
        this.hideModal();
    }

    getSessionInfo(sessionGuid: string, success?: () => void, fail?: () => void): void {
        console.debug(`getSessionInfo calibrationSessionGuid=${sessionGuid}`);
        const dr = new DataRequest();
        dr.$get<CalibrationSessionDto>(`/Service/CalibrationSession/${sessionGuid}`)
            .then((session: CalibrationSessionDto): void => {
                if (session.SessionType === SessionType.SelfCalibration) {
                    confirmOk("This session is a self guided calibration and cannot be controlled.");
                    if (fail) fail();
                    return;
                }
                Global.CalibrationSession = session;
                Global.Vehicle = null;
                this.loadVehicleData(Global.CalibrationSession!.VehicleId, success, fail);
            })
            .catch((reason): void => {
                confirmOk(`Unable to obtain session record - ${reason}.`);
                if (fail) fail();
            });
    }

    loadVehicleData(vehicleId: number|null, success?: () => void, fail?: () => void): void {
        console.debug(`loadVehicleData vehicleId=${vehicleId} Global.Vehicle=${Global.Vehicle != null ?  "present": "null"}`);
        if (!vehicleId)
            vehicleId = Global.CalibrationSession!.VehicleId;
        if (!Global.Vehicle && vehicleId) {
            const dr = new DataRequest();
            dr.$get<VehicleDto>(`/Service/Vehicle/${vehicleId}`)
                .then((vehicle: VehicleDto): void => {
                    const cal = this.getVehicleCalibration(vehicle, Global.CalibrationSession!.VehicleCalibrationId!);
                    if (cal == null) {
                        confirmOk(`There are no calibration types defined for ${Global.VehicleDescription()}.`);
                        if (fail) fail();
                        return;
                    }
                    Global.Vehicle = vehicle;
                    Global.CalibrationSession!.VehicleId = vehicleId;
                    Global.VehicleCalibration = cal;
                    console.debug("loadVehicleData loaded");
                    if (success) success();
                })
                .catch((reason): void => {
                    confirmOk(`No vehicle information available for vehicle ID ${vehicleId}.`);
                    if (fail) fail();
                });
        } else {
            if (success) success();
        }
    }

    getVehicleCalibration(vehicle: VehicleDto, vehicleCalibrationId: number): VehicleCalibrationDto|null {
        const cals = vehicle!.VehicleCalibrations.filter((cal: VehicleCalibrationDto, index: number): boolean => {
            return vehicleCalibrationId === cal.VehicleCalibrationId;
        });
        if (cals.length === 0) return null;
        return cals[0];
    }

    getDeviceStatus(fail?: () => void): void {
        console.debug("getDeviceStatus");
        // If all goes well we will get the device status, which continues on the desired page.
        CalibrationEventSupport.RetryableEvent((): void => {
            SignalR.connection.send("techStartCalibrationSession", CurrentUser.userGuid!, Global.CalibrationSession!.DeviceGuid);
        }, (): Promise<ButtonClicked> => {
            return confirmYesWaitNo("The device did not respond. Do you want to retry obtaining the device status?");
        }, (): void => {
            if (fail) fail();
        }, 30);
    }
}


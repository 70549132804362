import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "btn-group",
  role: "group",
  "aria-label": "Actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("td", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("a", {
        href: _ctx.downloadUrl,
        target: "_blank",
        class: "btn btn-sm btn-primary",
        "data-bs-toggle": "tooltip",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearTooltips())),
        title: "Download ZIP File"
      }, [
        _createVNode(_component_fa_icon, {
          icon: "file-download",
          class: "fa-w-20"
        })
      ], 8, ["href"])
    ])
  ]))
}
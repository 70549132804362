

import { VNode } from "@vue/runtime-core";
import { Vue, Options } from "vue-class-component";

import GridPanel from "@/shared/components/common/GridPanel.vue";
import { GridCellProps, GridColumnProps } from "@progress/kendo-vue-grid";
import { State } from "@progress/kendo-data-query";
import { DataRequest, DataResult } from "@/shared/support/Data";
import Utility from "@/shared/support/Utility";

import EditPackageModal from "./EditPackageModal.vue";
import PackageButtonCell from "./PackageButtonCell.vue";

import { PackageDto, PackageGridDto } from "@/shared/models/PackageDto";
import BoolDisplayGridCell from "@/shared/components/common/BoolDisplayGridCell.vue";
import { GenericActiveStatus } from "@/shared/enums/GenericActiveStatus";
import { UserRoles } from "@/identity";
import App from "@/App.vue";
import { OpsysTypeDescription } from "@/shared/enums/OpsysType";

@Options({
    components: {
        EditPackageModal,
    },
    watch: {
        $route(to, from): void { // going from this page to some page. If it's the same, check query parameters
            if (from.path.toLowerCase() === to.path.toLowerCase()) { // going to the same page, show a modal (if any)
                this.navigate();
            }
        }
    }
})
export default class PackageInventory extends Vue {

    loading = false;
    status: GenericActiveStatus = GenericActiveStatus.Active;

    showPackageModal(packageId?: number): void {
        this.$nextTick((): void => {
            this.$refs.packagemodal.show(packageId);
        });
    }
    hidePackageModal(forceReload?: boolean, setUrl?: boolean): void {
        this.$refs.packagemodal?.hide(forceReload, setUrl);
    }
    addNew(): void {
        App.MainApp.routerPush("/packages", { packageid: ""});
    }
    canAddNew(): boolean {
        return UserRoles.has("AuggiePackageCreate");
    }

    dataResult:DataResult<PackageDto> = GridPanel.EmptyDataResult;
    columns: GridColumnProps[] = [ // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridColumnProps/
        { field: "Actions", title: " ", sortable: false, width: "120px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActions },
        { field: "PackageId", hidden: true },
        { field: "PackageName", title: "Name", width: "300px", },
        { field: "Version__Display", title: "Version", width: "120px", },
        { field: "RequiredInstall", title: "Required", width: "100px", headerClassName: "gCenter", className: "gCenter", cell: this.cellRequired },
        { field: "InstallBy__Display", title: "Install By", width: "120px", },
        { field: "ActiveInd", title: "Active", width: "100px", headerClassName: "gCenter", className: "gCenter", cell: this.cellActive },
        { field: "Opsys__Display", title: "OpSys", width: "140px", },
        { field: "CreatedDt__Display", title: "Created", width: "180px", },
        { field: "CreatedByUserName", title: "Created By", width: "300px", },
        { field: "UpdatedDt__Display", title: "Updated", width: "180px", },
        { field: "UpdatedByUserName", title: "Updated By", width: "300px", },
    ];

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActions(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            PackageButtonCell, { // the component used to render the cell
                packageId: props.dataItem.PackageId,
                packageName: props.dataItem.PackageName,
                active: props.dataItem.ActiveInd,
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellActive(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            BoolDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.ActiveInd,  // props to pass to the component
            });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cellRequired(oldH: any, dr: VNode | null, props: GridCellProps, listeners: any): VNode {
        return GridPanel.customCell(oldH, dr, props, listeners,
            BoolDisplayGridCell, {     // the component used to render the cell
                value: props.dataItem.RequiredInstall,  // props to pass to the component
            });
    }

    loadGrid(dataState: State, search: string): void {

        this.loading = true; // turn on page's loading indicator

        // load grid data
        const request = new DataRequest();
        request.$getGridData<PackageGridDto[]>("/Service/Packages", dataState, search, {
            StatusFilter: this.status,
        })
            .then((result): void => {
                // format all data that is not readily renderable
                for (const row of result.Rows) {
                    row.CreatedDt__Display = Utility.getFormattedDateTime(new Date(row.CreatedDt));
                    if (row.UpdatedDt) row.UpdatedDt__Display = Utility.getFormattedDateTime(new Date(row.UpdatedDt));
                    row.Version__Display = `${row.VersionMajor}.${row.VersionMinor}.${row.VersionBuild}`;
                    row.Opsys__Display = row.Opsys ? OpsysTypeDescription[row.Opsys] : "(n/a)";
                    if (row.InstallBy) row.InstallBy__Display = Utility.getFormattedDate(new Date(row.InstallBy));
                }
                // set grid data
                this.loading = false;  // turn off page's loading indicator
                this.dataResult = { // update grid's model
                    data: result.Rows,
                    total: result.Total,
                };
            })
            .catch((reason: any): void => {
                this.loading = false; // turn off page's loading indicator
                this.dataResult = GridPanel.EmptyDataResult;
            });
    }
    reloadGrid(): void {
        this.$refs.packageGrid.reload();
    }

    navigate(): void {
        this.hidePackageModal();
        const query = this.$router.currentRoute.value.query;
        const packageId = query.packageid;
        if (packageId !== undefined)
            this.showPackageModal(Number(packageId));
    }

    mounted(): void {
        this.navigate();
    }

    $refs!: {
        packagemodal: EditPackageModal,
        packageGrid: GridPanel,
    }
}



import { Vue } from "vue-class-component";
import { Global } from "@/support/GlobalData";
import { SessionType } from "@/shared/enums/SessionType";

export default class Header extends Vue {

    get auggieDeviceName(): string {
        if (!Global.CalibrationSession || !Global.CalibrationSession.DeviceName) return "";
        return ` - ${Global.CalibrationSession.DeviceName}`;
    }
    get sessionType(): string {
        if (Global.CalibrationSession?.SessionType === SessionType.AssistedCalibration) return " - Assisted Calibration";
        if (Global.CalibrationSession?.SessionType === SessionType.AssistedTargetDisplay) return " - Target Request";
        return "";
    }
}

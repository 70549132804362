import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_grid_title = _resolveComponent("grid-title")
  const _component_grid_header = _resolveComponent("grid-header")
  const _component_grid_norecords = _resolveComponent("grid-norecords")
  const _component_Grid = _resolveComponent("Grid")
  const _component_grid_body = _resolveComponent("grid-body")

  return (_openBlock(), _createBlock("div", {
    ref: "refGrid",
    class: _ctx.cardCss()
  }, [
    _withDirectives(_createVNode(_component_grid_header, {
      "css-header": _ctx.cssHeader,
      onLoadgrid: _ctx.loadGrid,
      modelValue: _ctx.search,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.search = $event)),
      loading: _ctx.loading,
      "reload-button": _ctx.reloadButton,
      "auto-search": _ctx.autoSearch,
      "auto-search-timer": _ctx.autoSearchTimer
    }, {
      headerright: _withCtx(() => [
        _renderSlot(_ctx.$slots, "headerright")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_grid_title, null, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "title")
          ]),
          _: 3
        }),
        _renderSlot(_ctx.$slots, "header")
      ]),
      _: 3
    }, 8, ["css-header", "onLoadgrid", "modelValue", "loading", "reload-button", "auto-search", "auto-search-timer"]), [
      [_vShow, _ctx.headerShown]
    ]),
    _createVNode(_component_grid_body, null, {
      default: _withCtx(() => [
        _createVNode(_component_Grid, {
          style: _ctx.style,
          sortable: _ctx.sortable,
          sort: _ctx.currSort,
          scrollable: _ctx.scrollable,
          pageable: _ctx.realPageable,
          skip: _ctx.currSkip,
          take: _ctx.currTake,
          columns: _ctx.columns,
          onDatastatechange: _ctx.dataStateChange,
          "data-items": _ctx.dataResult
        }, {
          default: _withCtx(() => [
            _createVNode(_component_grid_norecords, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getNoRecordsText()), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["style", "sortable", "sort", "scrollable", "pageable", "skip", "take", "columns", "onDatastatechange", "data-items"])
      ]),
      _: 1
    })
  ], 2))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selection = _resolveComponent("selection")

  return (_openBlock(), _createBlock(_component_selection, {
    modelValue: _ctx.status,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.status = $event)),
    options: _ctx.options,
    "add-select": false,
    sort: "none",
    readonly: _ctx.readonly,
    required: _ctx.required
  }, null, 8, ["modelValue", "options", "readonly", "required"]))
}

import { Options, Vue } from "vue-class-component";
import { CurrentUser } from "@/identity";
import OnlineStatus from "./OnlineStatus.vue";
import Globals from "@/support/Globals";

@Options({
    components: {
        OnlineStatus,
    }
})
export default class Header extends Vue {

    environmentName = Globals.GetEnvironment();

    get userName(): string | null {
        return CurrentUser.userProfile?.name || null;
    }

    settingsUrl(): string {
        const loc = window.location;
        const appUrl = `${loc.protocol}//${loc.host}${process.env.BASE_URL}`;
        return Globals.IdentityUrl(`${process.env.VUE_APP_Identity_Settings}${appUrl}`);
    }
}



// not yet import "@progress/kendo-theme-default/dist/all.css";

import { Options, Vue } from "vue-class-component";

import { SignalR } from "./support/SignalR";
import Tooltip from "@/shared/support/Tooltip";
import Header from "@/components/Header.vue";
import Footer from "@/shared/components/common/Footer.vue";
import AlertDialog, { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { DataRequest } from "./shared/support/Data";
import { Access, CurrentUser } from "./identity";
import { Global } from "./support/GlobalData";
import { ButtonClicked } from "./shared/enums/ButtonClicked";
import { CalibrationEventSupport } from "./support/CalibrationEvent";
import { CalibrationEvent } from "./shared/enums/CalibrationEvent";
import Utility from "./shared/support/Utility";
import Toast from "./shared/support/Toast";
import DropDownMenuSupport from "./shared/support/DropDownMenu";
import { CalibrationSessionDto } from "./shared/models/CalibrationSessionDto";
import ModalBase from "./shared/components/common/ModalBase";
import Globals from "./support/Globals";

@Options({
    components: {
        Header,
        Footer,
        AlertDialog
    }
})
export default class App extends Vue {

    // Main App accessors

    public static get MainApp(): App {
        return Global.MainApp;
    }

    public created(): void {
        Global.MainApp = this;
    }
    mounted(): void {
        AlertDialog.initializeAlertDialog(this.$refs.alertDialog);
        Tooltip.initializeTooltips();
        DropDownMenuSupport.initialize();

        // Init signalr
        SignalR.start(Globals.GetSignalRFunction());

        DataRequest.getWebAPI = (): string => {
            return Globals.GetWebApi();
        };
        DataRequest.setRequestHeaders = (request: XMLHttpRequest, useAuthentication: boolean): void => {
            request.setRequestHeader("Management-Api-Version", "v1.0");
            const token = Access.getToken();
            if (useAuthentication && token) { // pass our JWT token
                request.setRequestHeader("Authorization", `Bearer ${token}`);
            }
        };
        DataRequest.getToken = (): string => {
            return Access.getToken() || "";
        };
        DataRequest.haveToken = (): boolean => {
            return Access.getToken() != null;
        };
        DataRequest.getMaxRetries = (): number => {
            return 3;
        };

        SignalR.onTeamViewerStarted = (deviceName: string, techGuid: string|null): void => {
            if (!techGuid || techGuid === CurrentUser.userGuid)
                Toast.info(`Remote Assistance has been enabled on device ${deviceName}.`);
        };
        SignalR.onCalibrationCanceledByDevice = (): void => {
            Toast.warning("The calibration has been canceled by the customer.");
            App.MainApp.routerPush("/");
        };
        SignalR.onCalibrationDisconnectedDevice = (): void => {
            Toast.warning("The device has disconnected and the calibration has been canceled.");
            App.MainApp.routerPush("/");
        };
    }
    unmounted(): void {
        SignalR.onTeamViewerStarted = null;
        SignalR.onCalibrationCanceledByDevice = null;
        SignalR.onCalibrationDisconnectedDevice = null;
    }

    getSessionInfo(sessionGuid: string): void {

        const dr = new DataRequest();
        dr.$get<CalibrationSessionDto>(`/Service/CalibrationSession/${sessionGuid}`)
            .then((session: CalibrationSessionDto): void => {
                Global.CalibrationSession = session;
            })
            .catch((reason): void => {
                confirmOk(`Unable to obtain session record - ${reason}.`);
            });
    }

    $refs!: {
        alertDialog: AlertDialog
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public routerReplace(url: string, query?:any): void {
        // defeat vue router caching by adding a time tick.
        let complete = `${url}?x=${this.ticks()}`;
        if (query)
            complete += this.addQuery(query);
        ModalBase.killModals();// kill modals in case we go to a page that's already shown
        this.$router.replace(complete);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public routerPush(url: string, query?:any): void {
        // defeat vue router caching by adding a time tick.
        let complete = `${url}?x=${this.ticks()}`;
        if (query)
            complete += this.addQuery(query);
        ModalBase.killModals();// kill modals in case we go to a page that's already shown
        this.$router.replace(complete);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    addQuery(query?:any): string {
        let args = "";
        for (const key in query) {
            args += "&";
            const val = query[key];
            if (val !== undefined && val !== null)
                args += `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
            else
                args += `${encodeURIComponent(key)}=`;
        }
        return args;
    }
    ticks(): string {
        const dNow = new Date();
        const dStart = new Date(1970, 0, 1);
        const seconds = dNow.getTime();
        const secondsStart = dStart.getTime();
        const dateDifference = seconds - secondsStart;
        return dateDifference.toString();
    }
    /**
     * Tech cancels session, with prompt.
     */
    cancelCalibration(): void {
        if (Global.CalibrationSession && Global.CalibrationSession.CalibrationSessionGuid) {
            confirmYesNo("Are you sure you want to cancel this session?")
                .then((clicked: ButtonClicked) => {
                    if (clicked === ButtonClicked.Ok) {
                        this.abortCalibration();
                    }
                });
        } else {
            this.abortCalibration();
        }
    }

    /**
     * Tech cancels session, without prompt.
     */
    abortCalibration(): void {
        if (Global.CalibrationSession && Global.CalibrationSession.CalibrationSessionGuid)
            CalibrationEventSupport.sendEvent(Global.CalibrationSession.CalibrationSessionGuid, CalibrationEvent.CalibrationTechnicianCanceled);
        Utility.pageChanged = false;
        Global.CalibrationSession = null;
        App.MainApp.routerPush("/");
    }

    /**
     * Session end.
     */
    endCalibration(): void {
        CalibrationEventSupport.CancelRetryableEvents();
        if (Global.CalibrationSession && Global.CalibrationSession.DeviceGuid && CurrentUser.userGuid) {
            SignalR.connection.send("techEndCalibrationSession", CurrentUser.userGuid, Global.CalibrationSession.DeviceGuid);
        }
        Utility.pageChanged = false;
        App.MainApp.routerPush("/");
    }
}


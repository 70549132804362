

import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class CalibrationButtonCell extends Vue {

    @Prop({ required: true }) readonly calibrationSessionGuid: string = "";

    openUrl(): string {
        return `/calibrationdetails?calibrationsessionguid=${this.calibrationSessionGuid}`;
    }
}


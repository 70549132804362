

import { Options } from "vue-class-component";
import PageBase from "@/shared/components/common/PageBase";

import MetricsGrid from "./MetricsGrid.vue";
import { SignalR } from "@/support/SignalR";

@Options({
    components: {
        MetricsGrid,
    },
})
export default class Metrics extends PageBase {

    mounted(): void {
        SignalR.whenReady((): void => {
            this.pageLoading = false;
        });
    }

}




import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class NotesDisplayGridCell extends Vue {

    @Prop({ default: "" }) readonly value: string = "";

}


export enum OpsysType {
    // eslint-disable-next-line camelcase
    Ubuntu_20_04_1 = 1,
    // eslint-disable-next-line camelcase
    Ubuntu_22_04_1 = 2,

    Windows10 = 10,
    Windows11 = 11,
}
export enum OpsysAllType {
    All = 0,
    // eslint-disable-next-line camelcase
    Ubuntu_20_04_1 = 1,
    // eslint-disable-next-line camelcase
    Ubuntu_22_04_1 = 2,
    Windows10 = 10,
    Windows11 = 11,
}

export const OpsysTypeDescription: string[] = [
    "All",
    "Ubuntu 20.04.1",
    "Ubuntu 22.04.1",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Windows 10",
    "Windows 11"
];

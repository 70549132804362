

import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { GenericActiveStatus, GenericActiveStatusDescription } from "@/shared/enums/GenericActiveStatus";
import Selection from "./Selection.vue";

export default class GenericStatus extends Vue {

    @Prop({ default: GenericActiveStatus.Active }) readonly modelValue:GenericActiveStatus = null!
    @Prop({ default: false }) readonly readonly: boolean = false;
    @Prop({ default: false }) readonly required: boolean = false;

    options = Selection.EnumSelections(GenericActiveStatus, GenericActiveStatusDescription);

    get status(): GenericActiveStatus {
        return this.modelValue;
    }
    set status(value: GenericActiveStatus) {
        this.$emit("update:modelValue", value);
    }
}




import { defineComponent } from "vue";

export default defineComponent({
    components: {

    },
    props: {
        headerShown: { type: Boolean, required: false, default: true },
        expandCollapse: { type: Boolean, required: false, default: false },
        expanded: { type: Boolean, required: false, default: true },
    },
    data: function() {
        return {
            panelExpanded: this.expanded,
        };
    },
    methods: {
        expandCollapseClicked(): void {
            this.panelExpanded = !this.panelExpanded;
        },
    },
    watch: {
        expanded(): void {
            this.panelExpanded = this.expanded;
        },
    }
});

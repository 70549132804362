

import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import Toast from "@/shared/support/Toast";
import { DataRequest } from "@/shared/support/Data";
import Utility from "@/shared/support/Utility";
import Events from "@/shared/support/Events";
import TooltipSupport from "@/shared/support/Tooltip";

export default class UploadButtonCell extends Vue {

    @Prop({ required: true }) readonly uploadId: number = 0;
    @Prop({ required: true }) readonly uploadFileName: string = "";
    @Prop({ required: true }) readonly apiUrl: string = "";
    @Prop({ default: false, required: false }) readonly readonly: boolean = false;

    removeClicked(): void {
        confirmYesNo(`Are you sure you want to remove upload file "${this.uploadFileName}"?`)
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    this.postRemoveUpload();
                }
            });
    }
    private postRemoveUpload(): void {
        const dr = new DataRequest();
        dr.$delete<boolean>(`/Service/Uploads/${this.uploadId}`)
            .then((result: boolean): void => {
                if (result) {
                    Toast.success(`Upload file "${this.uploadFileName}" has been removed.`);
                    Events.bubbleEvent(this, "upload-grid-reload", this.uploadId);
                } else {
                    confirmOk(`An error occurred removing upload file "${this.uploadFileName}".`);
                }
            }).catch((reason: any): void => {
                confirmOk(`An error occurred removing upload file "${this.uploadFileName}": ${reason}`);
            });
    }
    get downloadUrl(): string {
        return Utility.formatUrl(`/Service/Uploads/GetContentById/${this.uploadId}`, null, this.apiUrl, true);
    }
    clearTooltips(): void {
        TooltipSupport.clearTooltips();
    }
}


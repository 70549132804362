import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "btn-group",
  role: "group",
  "aria-label": "Actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("td", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("a", {
        class: "btn btn-sm btn-warning",
        href: _ctx.openUrl(),
        target: "_blank",
        "data-bs-toggle": "tooltip",
        title: "Display Calibration Details"
      }, [
        _createVNode(_component_fa_icon, {
          icon: "eye",
          class: "fa-w-20"
        })
      ], 8, ["href"])
    ])
  ]))
}